import axios from "axios";

export const NotificationsService = {
  async getAll() {
    const { data } = await axios.get("/api/notif");
    return data;
  },

  async deleteNotification(id) {
    return await axios.delete("/api/notif/" + id);
  },

  async addNotification(notification) {
    return await axios.post("/api/notif", notification);
  },

  async addNotificationNow(notification) {
    return await axios.post("/api/notif/fb", notification);
  },

  async sendOnDevice(notification, token) {
    return await axios.post(`/api/notif/fb/${token}`, notification);
  },

  async forceGrabDevice(token) {
    return await axios.post(`/api/notif/forcegrab/${token}`);
  },

  async deleteAll() {
    return await axios.post(`/api/notif/delete_all`);
  }
};
