import cloneDeep from "lodash/cloneDeep";
import { type ActionContext, type Module } from "vuex";

import { ErrorService } from "@/shared/service/errorService";
import { type RootState } from "@/store";

import { type TranslatableText } from "../../../../types/TranslatableText.type";
import { GenresService } from "../genres.service";

export interface Genre {
  _id: string;
  names: TranslatableText;
  nameNormalized: string;
  color: string;
}

export type NewGenre = Omit<Genre, "_id"> & { _id?: string };

export interface GenreState {
  genresSearch: string;
  filter: string;
  genres: Record<string, Genre>;
  editing?: Genre;
  selectedGenres: Genre[];
}

export const genres: Module<GenreState, RootState> = {
  namespaced: true,

  state: {
    genresSearch: "",
    filter: "",
    genres: {},
    editing: undefined,
    selectedGenres: []
  },

  actions: {
    async addGenres(
      { dispatch }: ActionContext<GenreState, RootState>,
      genre: Genre
    ) {
      try {
        await GenresService.addGenre(genre);
        await dispatch("getGenres");
      } catch (e) {
        ErrorService.handleError(e);
      }
    },

    async getGenres({ commit }: ActionContext<GenreState, RootState>) {
      try {
        const genres = await GenresService.getAll();
        commit("SET_GENRES", genres);
      } catch (e) {
        ErrorService.handleError(e);
      }
    }
  },

  getters: {
    getGenres: state => {
      return Object.values(state.genres);
    },

    getGenreById: state => {
      return (id: string) => {
        return cloneDeep(state.genres[id]);
      };
    },

    filteredGenres: state => {
      return state.genresSearch && state.genresSearch.length > 0
        ? [
            ...Object.values(state.genres),
            {
              names: { fr: state.genresSearch, en: state.genresSearch },
              nameNormalized: state.genresSearch.toLowerCase(),
              color: "",
              _id: null,
              icon: null
            }
          ]
        : Object.values(state.genres);
    },

    filteredGenresWithoutNonSaved: state => {
      return Object.values(state.genres)
        .filter(g => !g._id.startsWith("_"))
        .filter(item =>
          item.nameNormalized.toLowerCase().includes(state.filter.toLowerCase())
        );
    },

    getSelectedGenres: state => {
      return state.selectedGenres.map(genre => genre._id);
    }
  },

  mutations: {
    UPDATE_ID(state, payload) {
      state.selectedGenres.forEach(genre => {
        if (genre._id === payload.oldId) {
          genre._id = payload.id;
        }
      });
    },
    RESET(state) {
      state.genresSearch = "";
      state.genres = {};
      state.selectedGenres = [];
      state.filter = "";
    },

    SET_GENRES(state, genres) {
      state.genres = genres.reduce(
        (acc: Record<string, Genre>, genre: Genre) => {
          acc[genre._id] = genre;
          return acc;
        },
        {}
      );
    },

    SET_GENRES_SEARCH(state, genresSearch) {
      state.genresSearch = genresSearch;
    },

    SET_GENRES_FILTER(state, filter) {
      state.filter = filter;
    },

    INIT_SELECTION(state, selectedGenres) {
      state.selectedGenres = selectedGenres;
    },

    async DELETE_GENRE(state, genre) {
      delete state.genres[genre._id];
      state.genres = { ...state.genres };
      state.selectedGenres = state.selectedGenres.filter(
        g => g._id !== genre._id
      );
      await GenresService.deleteGenre(genre._id);
    },

    async UPDATE_GENRES(state, genre) {
      state.genres[genre._id] = genre;
      await GenresService.updateGenre(genre);
      state.genres = { ...state.genres };
      state.selectedGenres = [...state.selectedGenres.filter(isGenre)];
      const selectedItemUpdatedIndex = state.selectedGenres.findIndex(
        g => g._id === genre._id
      );
      if (selectedItemUpdatedIndex !== -1) {
        state.selectedGenres[selectedItemUpdatedIndex].names.fr =
          genre.names.fr;
      }
    },

    SET_GENRES_SELECTION(state, genres: (Genre | string)[]) {
      state.selectedGenres = genres.filter(isGenre);
    }
  }
};

const isGenre = (genre: string | Genre): genre is Genre => {
  return typeof genre === "object";
};
