import axios from "axios";

import { type TranslatableText } from "../../../types/TranslatableText.type";

export type FestivalPlayStore = {
  _id?: string;
  applicationName?: string;
  subtitle?: string;
  shortDescriptions?: TranslatableText;
  longDescriptions?: TranslatableText;
  applicationIcone?: string | null;
  playStoreImage?: string | null;
  emailsTester?: string;
  emailsTesteriOS?: string;
  googlePlayLink?: string;
  appleStoreLink?: string;
};

export const StoresInformationsService = {
  async getAppStore(): Promise<FestivalPlayStore> {
    const { data } = await axios.get<FestivalPlayStore>(
      "/api/festivalPlayStore"
    );
    return data;
  },

  async updateStoresInformations(appStore: FestivalPlayStore): Promise<void> {
    axios.put("/api/festivalPlayStore", appStore);
  }
};
