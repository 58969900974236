import cloneDeep from "lodash/cloneDeep";

import { ErrorService } from "@/shared/service/errorService";

import { i18n } from "../../i18n/i18n";
import {
  type Festival,
  FestivalService
} from "../../shared/service/festival.service";
import { SnackbarService } from "../../shared/snackbar/snackbar.service";
import { createStore } from "../../utils/createStore";

export class FestivalState {
  festival: Festival | undefined;
}

export const {
  festival,
  commit: commitFestival,
  dispatch: dispatchFestival,
  mapGetters: mapGettersFestival,
  mapState: mapStateFestival,
  useGetter: useGetterFestival,
  useState: useStateFestival
} = createStore({
  moduleName: "festival",
  initState: new FestivalState(),
  actions: {
    async loadFestival({ commit }) {
      try {
        const festival = await FestivalService.getFestival();
        commit("SET_FESTIVAL", festival);
      } catch (e) {
        ErrorService.handleError(e);
      }
    },

    async updateFestival({ commit, state }, festival: Festival) {
      try {
        festival._id = state.festival?._id;
        const updatedFestival = await FestivalService.updateFestival(festival);
        SnackbarService.info(i18n.t("FESTIVAL.SNACK_BAR.UPDATE") as string);
        commit("SET_FESTIVAL", updatedFestival);
      } catch (e) {
        ErrorService.handleError(e);
      }
    }
  },
  getters: {
    getFestival: state => {
      if (!state.festival) {
        return undefined;
      }
      const festival = cloneDeep(state.festival);
      festival.name = state.festival.name ?? "";
      festival.links = state.festival.links ?? {};
      festival.descriptions = state.festival.descriptions ?? {
        fr: "",
        en: ""
      };
      festival.startDate = state.festival.startDate ?? "";
      festival.endDate = state.festival.endDate ?? "";
      festival.contact = state.festival.contact ?? {
        tel: "",
        address: "",
        mail: "",
        name: ""
      };
      festival.locationName = state.festival.locationName ?? "";
      festival.locationCoords = state.festival.locationCoords ?? {
        lat: "",
        lng: ""
      };
      festival.alwaysShowEventHour = Boolean(
        state.festival.alwaysShowEventHour
      );
      festival.gcExportUrls = festival.gcExportUrls ?? [
        { type: "SCENE", url: "" },
        { type: "ARTIST", url: "" },
        { type: "EVENT", url: "" }
      ];
      festival.orderEventsByEndDate = state.festival.orderEventsByEndDate;
      festival.eventsHideEndDate = Boolean(state.festival.eventsHideEndDate);
      festival.hideMultilangSelector = Boolean(
        state.festival.hideMultilangSelector
      );
      festival.saferApiKey = state.festival.saferApiKey;
      return festival;
    }
  },
  mutations: {
    SET_FESTIVAL(state, festival) {
      state.festival = festival;
    }
  }
});
