export const state = {
  mode: "view",
  attendeeWhiteLists: [],
  currentAttendeeWhiteList: { email: "", lastname: "", firstname: "" },

  pagination: {
    sortBy: ["email"],
    sortDesc: [true],
    itemsPerPage: 10,
    page: 1,
    totalItems: 0
  },

  filter: {
    search: ""
  },

  loading: false
};
