export const state = {
  namespaced: true,
  scenes: {},
  deletingSceneId: null,
  editingSceneId: null,
  loading: false,
  showModal: false,

  sceneToMoveId: null,

  deletingSceneEvents: []
};
