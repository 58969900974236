export const PAGE_MODE = {
  VIEW: "view",
  EDIT: "edit",
  DELETE: "delete",
  TOGGLING_VISIBILITY: "togglingVisibility",
  SETTING_VISIBILITY_MULTIPLE: "settingVisibilityMultiple",
  DELETE_MULTIPLE: "deleteMultiple",
  EDIT_MULTIPLE: "editMultiple",
  DELETE_ALL: "deleteAll",
  ENABLE: "enable",
  DISABLE: "disable",
  ADD: "add"
} as const;

export type PageMode = (typeof PAGE_MODE)[keyof typeof PAGE_MODE];
