import { MenuElement } from "@/pages/menuEntries/MenuEntry.type";

import { type GalleryItem } from "./../gallery.type";

export class GalleryState {
  gallery: { [key: string]: GalleryItem } = {};
  loading: boolean = false;
  editingGalleryItemId: string = "";
  deletingGalleryItemId: string = "";
  showAddingModal: boolean = false;
  thumbnailImage: string = "";
  thumbnailImageCrop: string = "";
  image: string = "";
  galleryEntry: MenuElement = new MenuElement();
  pdfFilename: string = "";
}

export const state = new GalleryState();
