import { type Module } from "vuex";

import { ErrorService } from "@/shared/service/errorService";

import { i18n } from "../../i18n/i18n";
import { SnackbarService } from "../../shared/snackbar/snackbar.service";
import { type RootState } from "./../../store";
import { ResetAppService } from "./resetApp.service.js";

export class ResetAppState {
  fullReset: boolean = false;
  showModal: boolean = false;
}

export const resetApp: Module<ResetAppState, RootState> = {
  namespaced: true,

  state: new ResetAppState(),

  mutations: {
    SET_FULL_RESET(state, val) {
      state.fullReset = val;
    },

    RESET(state) {
      state.fullReset = false;
      state.showModal = false;
    },

    SHOW_MODAL(state) {
      state.showModal = true;
    }
  },

  actions: {
    async reset({ commit, state }) {
      try {
        await ResetAppService.resetApp(state.fullReset);
        SnackbarService.info(i18n.t("RESET_APP.SNACKBAR_MESSAGE").toString());
        commit("RESET");
      } catch (e) {
        ErrorService.handleError(e);
      }
    },

    async resetMenu() {
      try {
        await ResetAppService.resetMenu();
        SnackbarService.info(i18n.t("RESET_APP.MENU_RESET_CONFIRM").toString());
      } catch (e) {
        ErrorService.handleError(e);
      }
    }
  }
};
