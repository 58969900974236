import { cloneDeep } from "lodash";
import { type ActionTree } from "vuex";

import { ErrorService } from "@/shared/service/errorService";
import { FilesService } from "@/shared/service/files.service";
import { type RootState } from "@/store";

import { i18n } from "../../../i18n/i18n";
import { FestivalService } from "../../../shared/service/festival.service";
import { SnackbarService } from "../../../shared/snackbar/snackbar.service";
import { InternalType } from "../../../shared/types/files.type";
import { fromEntries } from "../../../utils";
import { GeoJsonParser } from "../geojson.service";
import { MapService } from "./../map.service";
import { type MarkerIcon, type POI, type ZOI } from "./../map.type";
import { type StateMap } from "./map.state";

export const actions: ActionTree<StateMap, RootState> = {
  async getMap(
    { state },
    {
      updateOnlyPreparedMap = false,
      id
    }: {
      id: string;
      updateOnlyPreparedMap?: boolean;
    }
  ) {
    try {
      const [map, festival] = await Promise.all([
        MapService.getMapById(id),
        FestivalService.getFestival()
      ]);
      state.preparedMap = map;
      state.markerIcons = festival.markerIcons.reduce(
        (acc, marker) => {
          if (!marker.oid) return acc;
          acc[marker.oid] = marker;
          return acc;
        },
        {} as Record<string, MarkerIcon>
      );

      if (!updateOnlyPreparedMap) {
        state.map = map;
        state.poiList = (map.poiList || []).reduce(
          (acc: { [key: string]: POI }, poi: POI) => {
            acc[poi.oid] = poi;
            return acc;
          },
          {}
        );
        state.zoiList = map.zoiList.reduce(
          (acc: { [key: string]: ZOI }, zoi: ZOI) => {
            acc[zoi.oid] = zoi;
            return acc;
          },
          {}
        );
        state.mapOverlay = map.mapOverlay || null;
        state.corners = map.corners;
        state.imageOpacity = map.imageOpacity;
      }
    } catch (e) {
      ErrorService.handleError(e);
    }
  },

  async getMapEntityChoices({ state }) {
    try {
      const [sceneList, guestList] = await Promise.all([
        MapService.getSceneList(),
        MapService.getGuestList()
      ]);

      state.entityIdChoicesByType = {
        NONE: undefined,
        SCENE: sceneList,
        GUEST: guestList
      };
    } catch (e) {
      ErrorService.handleError(e);
    }
  },

  async upsertMarker({ commit, state }, marker: MarkerIcon) {
    try {
      commit("LOAD");
      const image = await FilesService.uploadImage(
        FilesService.getImgFromCrop(state.picCrop, InternalType.MAP_MARKER)
      );
      if (!marker.oid) return;
      state.markerIcons = {
        ...state.markerIcons,
        [marker.oid]: {
          ...marker,
          file: image.filename
        }
      };
      state.poiList = fromEntries(
        Object.values(state.poiList).map(poi => {
          if (poi.markerIconId === marker.oid) {
            return [
              poi.oid,
              {
                ...poi,
                pic: image.filename
              }
            ] as const;
          }
          return [poi.oid, poi] as const;
        })
      );
      state.editingMarkerId = "";
      await FestivalService.updateMarkerIcons(Object.values(state.markerIcons));
    } catch (e) {
      ErrorService.handleError(e);
    } finally {
      commit("UNLOAD");
    }
  },

  async delete({ commit, state }) {
    try {
      commit("LOAD");

      const id = state.deletingId;
      if (state.type === "POI" && state.poiList[id]) {
        const pois = cloneDeep(state.poiList);
        delete pois[id];
        state.poiList = { ...pois };
      }
      if (state.type === "ZOI" && state.zoiList[id]) {
        const zois = cloneDeep(state.zoiList);
        delete zois[id];
        state.zoiList = { ...zois };
      }
      if (state.type === "MARKER") {
        const icons = cloneDeep(state.markerIcons);
        delete icons[id];
        state.markerIcons = icons;
        state.poiList = fromEntries(
          Object.values(state.poiList).map(poi => {
            if (poi.markerIconId === id) {
              return [
                poi.oid,
                {
                  ...poi,
                  pic: "DEFAULT",
                  markerIconId: null
                }
              ] as const;
            }
            return [poi.oid, poi] as const;
          })
        );
        await FestivalService.updateMarkerIcons(
          Object.values(state.markerIcons)
        );
      }
      state.deletingId = "";
    } catch (e) {
      ErrorService.handleError(e);
    } finally {
      commit("UNLOAD");
    }
  },

  async save({ commit, getters, dispatch }) {
    commit("LOAD");
    const preparedMap = getters["getPreparedMap"];
    await MapService.saveMap(preparedMap!)
      .then(() => {
        commit("SET_MAP_COPY");
        SnackbarService.info(i18n.t("MAP.SNACKBAR_MSG").toString());
      })
      .catch(e => {
        ErrorService.handleError(e);
      })
      .finally(async () => {
        await dispatch("getMap", {
          id: preparedMap ? preparedMap._id : undefined,
          updateOnlyPreparedMap: true
        });
        commit("UNLOAD");
      });
  },

  layerButtonPressed({ commit, state }) {
    if (!state.addingLayer && state.mapOverlay) {
      commit("SHOW_CHANGE_LAYER_MODAL");
    } else {
      commit("SET_ADDING_LAYER");
    }
  },

  async importGeoJsonFile(
    { commit, state },
    payload: { file: Blob; overwrite: boolean }
  ) {
    commit("LOAD");
    await new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsText(payload.file);
      reader.onload = () => {
        resolve(reader.result as string);
      };
      reader.onerror = error => reject(error);
    }).then(async geojson => {
      try {
        if (state.map?._id === undefined) throw "Unknown map";
        const data = await GeoJsonParser.parse(geojson as string);
        if (payload.overwrite) {
          state.poiList = {};
          state.zoiList = {};
        }
        data.pois.forEach(poi =>
          commit(
            "ADD_POI",
            replaceMarkerImage(poi, Object.values(state.markerIcons))
          )
        );
        data.zois.forEach(zoi => commit("ADD_ZOI", zoi));
        commit("SET_IMPORT_GEOJSON", {
          pois: data.pois.length,
          zois: data.zois.length,
          errors: data.errors
        });
      } catch (e) {
        ErrorService.handleError(e);
      } finally {
        commit("UNLOAD");
      }
    });
  }
};

function replaceMarkerImage(poi: POI, markers: MarkerIcon[]): POI {
  const marker = markers.find(
    marker => marker.label.toLocaleLowerCase() === poi.pic.toLocaleLowerCase()
  );
  if (marker) {
    poi.markerIconId = marker.oid;
    poi.pic = marker.file;
  } else {
    poi.markerIconId = null;
    poi.pic = "DEFAULT";
  }
  return poi;
}
