import { type ProgramsState } from "@/pages/programs/store/programs.state";
import { type Program } from "@/shared/types/program.type";

import { makeTranslatableText } from "../../../../types/TranslatableText.type";

const toProgramModel = function (
  rawProgram: Pick<Program, "names" | "color" | "isDeletable">
): Program {
  return {
    ...rawProgram
  } as Program;
};
export const getters = {
  getPrograms: (state: ProgramsState): Program[] =>
    Object.values(state.programs).map(toProgramModel),

  getProgram: (state: ProgramsState): Program => {
    return toProgramModel(
      state.editingProgramId
        ? state.programs[state.editingProgramId]
        : {
            names: makeTranslatableText(""),
            color: "#FFFFFFFF",
            isDeletable: true
          }
    );
  },

  isLastMenuEntry: (state: ProgramsState) => {
    return state.editingProgramId
      ? Object.values(state.programs).filter(n => !n.isDeletable).length === 1
      : false;
  }
};
