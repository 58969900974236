import { type Route } from "vue-router";

import { progEventDispatch } from "../progEvent/progEvent.store";
import { dispatchApplicationCfg } from "./store/applicationCfg.store";

export const ApplicationCfgRouter = [
  {
    path: "/applicationCfg",
    component: () => import("./applicationCfg.vue"),
    beforeEnter: async (to: Route, from: Route, next: any) => {
      await Promise.all([
        dispatchApplicationCfg("loadApplicationCfg"),
        progEventDispatch("list")
      ]);
      next();
    }
  }
];
