import { type Module } from "vuex";

import { AdsActions } from "@/pages/ads/store/ads.actions";
import { AdsMutation } from "@/pages/ads/store/ads.mutations";
import { type AdsState, state } from "@/pages/ads/store/ads.state";
import { type RootState } from "@/store";

export const ads: Module<AdsState, RootState> = {
  namespaced: true,
  state: state,
  mutations: AdsMutation,
  actions: AdsActions
};
