export const mutations = {
  LOAD(state) {
    state.loading = true;
  },

  UNLOAD(state) {
    state.loading = false;
  },

  SET_FILES(state, files) {
    state.orphanedFiles = files.reduce((acc, file) => {
      acc[file._id] = file;
      return acc;
    }, {});
  },

  SET_DELETING_ALL(state) {
    state.deleting = "ALL";
  },

  SET_DELETING(state, image) {
    state.deleting = image;
  },

  CANCEL_DELETING(state) {
    state.deleting = false;
  }
};
