import { type Route } from "vue-router";

import store from "../../store";

export const AdsRouter = [
  {
    path: "/ads",
    component: () => import("./ads.vue"),
    beforeEnter: async (to: Route, from: Route, next: any) => {
      await store.dispatch("ads/loadApplicationCfg");
      next();
    }
  }
];
