import { type Module } from "vuex";

import { actions } from "@/pages/guestTypes/store/guestType.actions";
import { getters } from "@/pages/guestTypes/store/guestType.getters";
import { mutations } from "@/pages/guestTypes/store/guestType.mutations";
import { type RootState } from "@/store";

import { type GuestTypeState, state } from "./guestType.state";

export const guestType: Module<GuestTypeState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
