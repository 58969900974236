import { type ActionContext } from "vuex";

import { createStore } from "../../utils/createStore";
import { ErrorService } from "../service/errorService";
import { SessionService } from "../service/session.service";
import { type Version, VersionService } from "../service/version.service";
import { type RootState } from "./../../store";

export class SessionState {
  loading: boolean = false;
  key: string = "";
  principal = new PrincipalDto();
  connectionRequired = true;
  version: string = "";
  upToDate: boolean = false;
}

export class PrincipalDto {
  email: string = "";
  name: string = "";
  roles: string[] = [];
}

export const {
  session,
  commit: sessionCommit,
  dispatch: sessionDispatch,
  mapGetters: sessionMapGetters,
  mapState: sessionMapState,
  useGetter: useSessionGetter,
  useState: useSessionState
} = createStore({
  namespaced: false,
  moduleName: "session",
  initState: new SessionState(),
  mutations: {
    SET_SESSION(
      state: SessionState,
      { principal, key }: { principal: PrincipalDto; key: string }
    ) {
      state.principal = principal;
      state.key = key;
    },

    LOAD(state: SessionState) {
      state.loading = true;
    },

    UNLOAD(state: SessionState) {
      state.loading = false;
    },

    RESET(state: SessionState) {
      state.principal = new PrincipalDto();
      state.key = "";
    },

    SET_CONNECTION_REQUIRED(state: SessionState) {
      state.connectionRequired = true;
    },

    SET_CONNECTION_NOT_REQUIRED(state: SessionState) {
      state.connectionRequired = false;
    },

    SET_VERSION(state: SessionState, { version, upToDate }: Version) {
      state.upToDate = upToDate;
      state.version = version;
    }
  },
  actions: {
    async LOAD_SESSION({ commit }: ActionContext<SessionState, RootState>) {
      try {
        commit("LOAD");
        const session = await SessionService.getSession();
        commit("SET_SESSION", session);
      } catch (e) {
        ErrorService.handleError(e, false);
      } finally {
        commit("UNLOAD");
      }
    },

    async LOGOUT({ commit }: ActionContext<SessionState, RootState>) {
      try {
        commit("LOAD");
        SessionService.logout();
      } catch (e) {
        ErrorService.handleError(e);
      } finally {
        commit("RESET");
        commit("UNLOAD");
      }
    },

    async CHECK_VERSION({ commit }: ActionContext<SessionState, RootState>) {
      try {
        commit("LOAD");
        const { version, upToDate } = await VersionService.getVersion();
        commit("SET_VERSION", { upToDate, version });
      } catch (e) {
        ErrorService.handleError(e, false);
      } finally {
        commit("UNLOAD");
      }
    }
  },
  getters: {
    isAdmin: (state: SessionState) => {
      return state.principal.roles && state.principal.roles.includes("admin");
    },
    isConnected: (state: SessionState): boolean => !!state.key,
    isConnecting: (state: SessionState): boolean => state.loading,
    isConnectionRequired: (state: SessionState): boolean =>
      state.connectionRequired
  }
});
