import { type Module } from "vuex";

import { type RootState } from "@/store";

import { StaticConfigActions } from "./staticConfig.actions";
import { StaticConfigGetters } from "./staticConfig.getters";
import { StaticConfigMutations } from "./staticConfig.mutations";
import { state, type StaticConfigState } from "./staticConfig.state";

export const staticConfig: Module<StaticConfigState, RootState> = {
  namespaced: true,
  state: state,
  mutations: StaticConfigMutations,
  actions: StaticConfigActions,
  getters: StaticConfigGetters
};
