import axios from "axios";

import { SnackbarService } from "../../shared/snackbar/snackbar.service";
import { dispatchAppConfig } from "../../shared/store/app-config.store";

export const PublicationsService = {
  async getAll() {
    const { data } = await axios.get("/api/exports");
    if (data.length > 0) {
      data[0].latest = true;
    }
    return data;
  },

  async getDiff(versionNumber) {
    const { data } = await axios.get(
      "/api/versionData/withVersion/" + versionNumber
    );
    return data;
  },

  async publishPublication() {
    try {
      await axios.post("/api/versionData");
      SnackbarService.info("Les données ont bien été publiées");
    } catch (error) {
      SnackbarService.error("Une erreur est survenue");
    }
  },

  async doPublication(message) {
    try {
      await axios.post("/api/exports", message);
      await dispatchAppConfig("LOAD_APP_CONFIG");
      SnackbarService.info("L'export est en cours de génération.");
    } catch (error) {
      if (error.response && error.response.status === 304) {
        SnackbarService.warn(
          "Un export est déjà en cours, merci de patienter quelques instants..."
        );
      } else {
        SnackbarService.error("Oups, l'export n'a pas pu être planifié");
      }
    }
  }
};
