import { type ActionTree } from "vuex";

import { i18n } from "@/i18n/i18n";
import { ApplicationDataService } from "@/shared/service/applicationDataService";
import { ErrorService } from "@/shared/service/errorService";
import { FilesService } from "@/shared/service/files.service";
import { type Font } from "@/shared/service/fontsService";
import { type PreviewGrabData } from "@/shared/types/applicationData.type";
import { type RootState } from "@/store";
import { entries } from "@/utils";

import { SnackbarService } from "../../../shared/snackbar/snackbar.service";
import { StaticConfigService } from "../staticCfg.service";
import {
  type FontChangeSet,
  type Lang,
  LANGS,
  type StaticConfig
} from "../staticCfg.types";
import { type StaticConfigState } from "./staticConfig.state";

export const StaticConfigActions: ActionTree<StaticConfigState, RootState> = {
  async loadStaticConfig({ commit }, { oid }: { oid: string }) {
    try {
      const config = await StaticConfigService.get(oid);
      commit("SET_CONFIG", config);
    } catch (e) {
      ErrorService.handleError(e);
    }
  },

  async createStaticConfig({ commit, rootGetters }) {
    const appCfg = rootGetters["applicationCfg/getApplicationCfg"];
    const config: StaticConfig = {
      colorAction: appCfg.colorAction,
      colorBlockAction: appCfg.colorAction,
      colorBackground: appCfg.colorBackground,
      colorTitle: appCfg.colorTitle,
      colorBlockBackground: appCfg.colorBlockBackground,
      colorBlockText: appCfg.colorBlockText,
      fontText: appCfg.fontTextRegular,
      fontTitle: appCfg.fontTitle,
      smartBannerEnabled: true,
      label: "Nouveau widget",
      pages: [
        {
          icon: "fa-table",
          blockName: {
            fr: "Vue grille",
            en: "Grid view"
          },
          default: true,
          visible: true,
          view: "TIMELINE"
        },
        {
          icon: "fa-list",
          blockName: {
            fr: "Vue événements",
            en: "Event view"
          },
          default: false,
          visible: true,
          view: "EVENTS"
        }
      ]
    };
    commit("SET_CONFIG", config);
  },

  async loadPreviewDataSets({ commit }) {
    try {
      const dataSets: Record<Lang, PreviewGrabData | undefined> = {
        en: undefined,
        fr: undefined
      };
      await Promise.all(
        LANGS.map(
          async (lang: Lang): Promise<any> =>
            (dataSets[lang] = await ApplicationDataService.getCurrent(
              lang,
              true
            ))
        )
      );
      commit("SET_DATASETS", dataSets);
    } catch (e) {
      ErrorService.handleError(e);
    }
  },

  async uploadFonts(
    ctx,
    { config, changeset }: { config: StaticConfig; changeset: FontChangeSet }
  ) {
    try {
      await Promise.all(
        entries(changeset)
          .filter(
            <U, T>(arg: [U, T | undefined]): arg is [U, T] => arg[1] != null
          )
          .map(([key, value]) =>
            FilesService.uploadFont(value).then(
              ({ filename, postScriptName }) => {
                const configFont: Font | undefined = config[key];
                if (!configFont) return;
                configFont.id = filename;
                configFont.postScriptName = postScriptName;
              }
            )
          )
      );
    } catch (e) {
      ErrorService.handleError(e);
    }
  },

  async saveStaticConfig({ commit }, staticCfg: StaticConfig) {
    try {
      const saved = await StaticConfigService.save(staticCfg);
      commit("SET_CONFIG", saved);
      SnackbarService.info(i18n.t("GENERAL.SAVED").toString());
    } catch (e) {
      ErrorService.handleError(e);
    }
  }
};
