<template>
  <modal
    :title="$t('PUBLICATION.CREATE_MODAL.TITLE')"
    :btn-ok-label="$t('ACTION.CONFIRM')"
    max-width="900px"
    @okAction="agreeAction"
    @cancelAction="disagreeAction"
  >
    <v-textarea
      v-model="exportDetails.message"
      rows="15"
      color="bleu2"
      :label="$t('PUBLICATION.CREATE_MODAL.COMMENT')"
      no-resize
      :placeholder="$t('PUBLICATION.CREATE_MODAL.COMMENT_PLACEHOLDER')"
    />
  </modal>
</template>

<script>
import { PublicationsService } from "./publications.service";

export default {
  components: {},
  data: () => ({
    exportDetails: {
      message: ""
    }
  }),
  methods: {
    agreeAction: async function () {
      await PublicationsService.doPublication(this.exportDetails);
      this.$emit("refresh-data");
      this.$store.commit("publications/hidePublicationModal");
      setTimeout(this.updateBanner, 2000);
    },

    updateBanner() {
      this.$store.dispatch("warningBanner/loadWarningBanner");
    },

    disagreeAction: function () {
      this.$store.commit("publications/hidePublicationModal");
    }
  }
};
</script>
