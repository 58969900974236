import { type Module } from "vuex";

import { type RootState } from "@/store";

import { actions } from "./sponsors.actions";
import { getters } from "./sponsors.getters";
import { mutations } from "./sponsors.mutations";
import { type SponsorsState, state } from "./sponsors.state";

export const sponsors: Module<SponsorsState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
