import { cloneDeep } from "lodash";
import { type Module } from "vuex";

import { type RootState } from "@/store";

import { i18n } from "../../../i18n/i18n";
import { PAGE_MODE } from "../../../shared/constants";
import { ErrorService } from "../../../shared/service/errorService";
import { SnackbarService } from "../../../shared/snackbar/snackbar.service";
import { fromEntries, isNotNullish } from "../../../utils";
import { StaticConfigService } from "../staticCfg.service";
import { type StaticConfig } from "../staticCfg.types";

class StatusConfigsState {
  mode = PAGE_MODE.VIEW;
  dict: { [id: string]: StaticConfig } = {};
  current: StaticConfig | null = null;
  loading = false;
}
const state = new StatusConfigsState();

export const staticCfgs: Module<StatusConfigsState, RootState> = {
  namespaced: true,
  state,
  mutations: {
    LOAD(state) {
      state.loading = true;
    },
    SET_DELETING(state, payload: StaticConfig) {
      state.mode = PAGE_MODE.DELETE;
      state.current = cloneDeep(payload);
    },
    SET_VIEW(state) {
      state.mode = PAGE_MODE.VIEW;
      state.current = null;
      state.loading = false;
    },
    SET_ITEMS(state, payload: StaticConfig[]) {
      state.dict = fromEntries(
        payload
          .map(item => (item._id ? ([item._id, item] as const) : null))
          .filter(isNotNullish)
      );
      state.loading = false;
    }
  },
  actions: {
    async list({ commit }) {
      try {
        commit("LOAD");
        commit("SET_ITEMS", await StaticConfigService.getAll());
      } catch (e) {
        ErrorService.handleError(e);
      }
    },
    async delete({ commit, dispatch, state }) {
      try {
        commit("LOAD");
        if (!state.current || !state.current._id) return;
        await StaticConfigService.delete(state.current._id!);
        SnackbarService.info(
          i18n.t("MAPS.SNACK_BAR.DELETE_SUCCESS").toString()
        );
      } catch (e) {
        ErrorService.handleError(e);
      } finally {
        await dispatch("list");
        commit("SET_VIEW");
      }
    },
    async duplicate({ commit }, item: StaticConfig) {
      try {
        commit("LOAD");
        const { _id, ...woIdItem } = item;
        await StaticConfigService.save({
          ...woIdItem,
          label: (item.label ?? "") + " (copie)"
        });
        commit("SET_ITEMS", await StaticConfigService.getAll());
      } catch (e) {
        ErrorService.handleError(e);
      }
    }
  },
  getters: {
    items(state) {
      return Object.values(state.dict);
    }
  }
};
