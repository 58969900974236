import { type Module } from "vuex";

import { type RootState } from "@/store";

export class IsMobileState {
  isMobile: boolean = false;
  isSmallLaptop: boolean = false;
}

export const isMobile: Module<IsMobileState, RootState> = {
  namespaced: false,
  state: new IsMobileState(),
  mutations: {
    SET_IS_MOBILE(state: IsMobileState) {
      state.isMobile = true;
      state.isSmallLaptop = true;
    },

    SET_IS_SMALL_LAPTOP(state: IsMobileState) {
      state.isMobile = false;
      state.isSmallLaptop = true;
    },

    SET_IS_DEFAULT(state: IsMobileState) {
      state.isSmallLaptop = false;
      state.isMobile = false;
    }
  },

  getters: {
    getIsMobile: (state: IsMobileState) => {
      return state.isMobile;
    },

    getIsSmallLaptop: (state: IsMobileState) => {
      return state.isSmallLaptop;
    }
  }
};
