import { ErrorService } from "@/shared/service/errorService";

import { i18n } from "../../../i18n/i18n";
import { SnackbarService } from "../../../shared/snackbar/snackbar.service";
import { AttendeesService } from "../attendees.service";

export const actions = {
  async list({ commit, state }, payload) {
    commit("LOAD");
    if (payload && payload.pagination) {
      commit("SET_PAGINATION", { ...state.pagination, ...payload.pagination });
    }
    if (payload && payload.filter) {
      commit("SET_FILTER", { ...state.filter, ...payload.filter });
    }
    const { sortBy, sortDesc, page, itemsPerPage } = state.pagination;
    const { attendees, total } = await AttendeesService.getAll(
      state.filter.search,
      page,
      itemsPerPage,
      sortBy[0],
      sortDesc[0],
      state.filter.onlyReported
    );
    commit("SET_ATTENDEES", { attendees, total });
  },

  async save({ commit, dispatch, state }) {
    commit("LOAD");
    try {
      await AttendeesService.update(state.currentAttendee);
      await dispatch("list");
    } catch (e) {
      ErrorService.handleError(e);
    } finally {
      commit("SET_VIEW");
    }
  },

  async delete({ commit, state }) {
    commit("LOAD");
    try {
      await AttendeesService.delete(state.currentAttendee._id);
      commit("SET_ATTENDEES", {
        attendees: state.attendees.filter(
          item => item._id !== state.currentAttendee._id
        ),
        total: state.pagination.totalItems - 1
      });
    } catch (e) {
      ErrorService.handleError(e);
    } finally {
      commit("SET_VIEW");
    }
  },

  async toggle({ commit, state }) {
    commit("LOAD");
    try {
      await AttendeesService.toggle(
        state.currentAttendee._id,
        state.currentAttendee.disabled
      );
      commit("SET_ATTENDEES", {
        attendees: state.attendees.map(item => {
          if (item._id !== state.currentAttendee._id) return item;
          return {
            ...state.currentAttendee,
            disabled: !state.currentAttendee.disabled
          };
        }),
        total: state.pagination.totalItems
      });
    } catch (e) {
      ErrorService.handleError(e);
    } finally {
      commit("SET_VIEW");
    }
  },
  async resetPwd({}, attendee) {
    try {
      await AttendeesService.resetPwd(attendee._id);
      SnackbarService.info(i18n.tc("ATTENDEE.CONFIRMATION.RESET_PWD"));
    } catch (e) {
      ErrorService.handleError(e);
    }
  }
};
