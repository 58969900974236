import { type LatLng } from "leaflet";
import cloneDeep from "lodash/cloneDeep";
import { type MutationTree } from "vuex";

import { IdsService } from "@/shared/service/utils.service";

import {
  type Lang,
  makeTranslatableText
} from "../../../../types/TranslatableText.type";
import { type AugmentedRequired } from "../../../utils";
import {
  type Coords,
  type GeoJsonImportSummary,
  makeCoords,
  makePOI,
  makeZOI,
  type MapEntityChoicesByType,
  type MapEntityType,
  type POI,
  type ZOI
} from "../map.type";
import { MapModalStates, type StateMap } from "./map.state";

export const mutations: MutationTree<StateMap> = {
  SET_MAP_COPY(state: StateMap) {
    state.mapCopy.poiList = cloneDeep(state.poiList);
    state.mapCopy.zoiList = cloneDeep(state.zoiList);
    state.mapCopy.markerIcons = cloneDeep(state.markerIcons);
    state.mapCopy.corners = cloneDeep(state.corners);
    state.mapCopy.mapOverlay = state.mapOverlay;
    state.mapCopy.imageOpacity = state.imageOpacity;
  },

  RESET_MAP_MODIFICATIONS(state: StateMap) {
    state.resetting = true;
    state.poiList = cloneDeep(state.mapCopy.poiList);
    state.zoiList = cloneDeep(state.mapCopy.zoiList);
    state.markerIcons = cloneDeep(state.mapCopy.markerIcons);
    state.mapOverlay = state.mapCopy.mapOverlay;
    state.corners = cloneDeep(state.mapCopy.corners);
    state.imageOpacity = state.mapCopy.imageOpacity;
  },

  END_RESET(state: StateMap) {
    state.resetting = false;
  },

  CHANGE_MAP_VIEW(state: StateMap) {
    state.satelliteView = !state.satelliteView;
  },

  LOAD(state: StateMap) {
    state.loading = true;
  },

  UNLOAD(state: StateMap) {
    state.loading = false;
  },

  SET_ADDING_POI(state: StateMap) {
    state.addingPoi = !state.addingPoi;
    state.addingZoi = false;
    state.addingLayer = false;
    state.showLayerModal = MapModalStates.HIDE;
  },

  SET_ADDING_ZOI(state: StateMap) {
    state.addingPoi = false;
    state.addingZoi = !state.addingZoi;
    state.addingLayer = false;
    state.showLayerModal = MapModalStates.HIDE;
  },

  SET_ADDING_LAYER(state: StateMap) {
    state.addingPoi = false;
    state.addingZoi = false;
    state.addingLayer = !state.addingLayer;
    state.showLayerModal = MapModalStates.HIDE;
    state.imageOpacity = 100;
  },

  SET_ADDING_NOTHING(state: StateMap) {
    state.addingPoi = false;
    state.addingZoi = false;
    state.addingLayer = false;
    state.showLayerModal = MapModalStates.HIDE;
  },

  SHOW_LAYER_MODAL(state: StateMap) {
    state.showLayerModal = MapModalStates.DELETE;
  },

  SHOW_CHANGE_LAYER_MODAL(state: StateMap) {
    state.showLayerModal = MapModalStates.CHANGE;
  },

  SET_REMOVE_LAYER(state: StateMap) {
    state.corners = [];
    state.mapOverlay = null;
  },

  OPACITY_PLUS(state: StateMap) {
    if (state.imageOpacity <= 80) {
      state.imageOpacity += 20;
    }
  },

  OPACITY_MINUS(state: StateMap) {
    if (state.imageOpacity >= 40) {
      state.imageOpacity -= 20;
    }
  },

  HIDE_LAYER_MODAL(state: StateMap) {
    state.showLayerModal = MapModalStates.HIDE;
  },

  ADD_POI(state: StateMap, payload: AugmentedRequired<Partial<POI>, "coords">) {
    const id = payload.oid ? payload.oid : IdsService.generateId();
    const pois = cloneDeep(state.poiList);
    pois[id] = makePOI({
      ...payload,
      oid: id
    });
    state.poiList = { ...pois };
  },

  SET_POI_COORDS(state: StateMap, payload: { id: string; coords: Coords }) {
    if (state.poiList[payload.id]) {
      state.poiList[payload.id].coords = payload.coords;
    } else {
      state.poiList[payload.id] = makePOI({
        coords: payload.coords,
        labels: makeTranslatableText(payload.id)
      });
    }
  },

  SET_POI_LAT(state: StateMap, payload: { id: string; val: string }) {
    const tab = cloneDeep(state.poiList);
    tab[payload.id].coords.lat = Number(payload.val);
    state.poiList = { ...tab };
  },

  SET_POI_LNG(state: StateMap, payload: { id: string; val: string }) {
    const tab = cloneDeep(state.poiList);
    tab[payload.id].coords.lng = Number(payload.val);
    state.poiList = { ...tab };
  },

  SET_POI_ENTITY(
    state: StateMap,
    payload: { poiId: string; entityType: MapEntityType; entityId: string }
  ) {
    state.poiList[payload.poiId].entityId = payload.entityId;
    state.poiList[payload.poiId].entityType = payload.entityType;
  },

  SET_POI_LABEL(
    state: StateMap,
    payload: { id: string; label: string; lang: Lang }
  ) {
    state.poiList[payload.id].labels[payload.lang] = payload.label;
  },

  SET_POI_PIC(
    state: StateMap,
    payload: {
      id: string;
      value: {
        file: string;
        oid: string | null;
      };
    }
  ) {
    const tab = cloneDeep(state.poiList);
    tab[payload.id].pic = payload.value.file;
    tab[payload.id].markerIconId = payload.value.oid;
    state.poiList = { ...tab };
  },

  SET_DELETE_POI(state: StateMap, id: string) {
    state.deletingId = id;
    state.type = "POI";
  },

  SET_DELETE_MARKER(state: StateMap, id: string) {
    state.deletingId = id;
    state.type = "MARKER";
  },

  SET_EDITING_MARKER_ID(state: StateMap, id: string) {
    state.editingMarkerId = id;
  },

  SET_ADDING_MARKER(state: StateMap) {
    state.editingMarkerId = "";
  },

  SET_PIC_CROP(state: StateMap, crop: string) {
    state.picCrop = crop;
  },

  //ZOI
  ADD_ZOI(
    state: StateMap,
    payload: AugmentedRequired<Partial<ZOI>, "zoneCorners">
  ) {
    const id = payload.oid ? payload.oid : IdsService.generateId();
    const zois = cloneDeep(state.zoiList);
    zois[id] = makeZOI({
      ...payload,
      oid: id
    });
    state.zoiList = { ...zois };
  },

  SET_DELETE_ZOI(state: StateMap, id: string) {
    state.deletingId = id;
    state.type = "ZOI";
  },

  SET_ZOI_LABEL(
    state: StateMap,
    payload: { id: string; label: string; lang: Lang }
  ) {
    state.zoiList[payload.id].labels[payload.lang] = payload.label;
  },

  SET_ZOI_ENTITY(
    state: StateMap,
    payload: { zoiId: string; entityType: MapEntityType; entityId: string }
  ) {
    state.zoiList[payload.zoiId].entityId = payload.entityId;
    state.zoiList[payload.zoiId].entityType = payload.entityType;
  },

  SET_ZOI_COLOR(state: StateMap, payload: { id: string; color: string }) {
    const zois = cloneDeep(state.zoiList);
    zois[payload.id].color = payload.color;
    state.zoiList = { ...zois };
  },

  ZOI_EDITED(state: StateMap, payload: { id: string; latLngs: LatLng[] }) {
    state.zoiList[payload.id].zoneCorners = payload.latLngs.map(
      (latLng: LatLng) => makeCoords(latLng.lat, latLng.lng)
    );
  },

  //Other
  SET_MAP_FOCUS(state: StateMap, id: string) {
    state.mapFocus = id;
  },

  CANCEL_DELETING(state: StateMap) {
    state.type = "";
    state.deletingId = "";
  },

  //layer

  EDIT_LAYER(state: StateMap, layer: any) {
    if (layer.getCorners()) {
      state.corners = cloneDeep(
        layer
          .getCorners()
          .map((corner: LatLng) => makeCoords(corner.lat, corner.lng))
      );
    }
  },

  RESET_CORNERS(state: StateMap) {
    state.corners = [];
  },

  SET_MAP_OVERLAY(state: StateMap, mapOverlay: string) {
    state.mapOverlay = mapOverlay;
  },

  SET_ENTITY_ID_CHOICES(state: StateMap, choices: MapEntityChoicesByType) {
    state.entityIdChoicesByType = choices;
  },

  // import
  SET_IMPORT_GEOJSON(state: StateMap, result: GeoJsonImportSummary) {
    state.importGeoJsonResult = result;
  },

  SHOW_IMPORT_GEOJSON_MODAL(state: StateMap) {
    state.showImportGeoJsonModal = MapModalStates.CHANGE;
  },

  HIDE_IMPORT_GEOJSON_MODAL(state: StateMap) {
    state.showImportGeoJsonModal = MapModalStates.HIDE;
  }
};
