import axios from "axios";

import { computeFakeTitle } from "@/pages/progEvent/progEvent.service";

import { weightService } from "../../shared/service/utils.service";

function eventsToJsFormat(data) {
  data.forEach(element => {
    element["fakeTitle"] = computeFakeTitle(element);
  });
  return data;
}

export const ScenesService = {
  async getAll() {
    const { data } = await axios.get("/api/scenes");
    return weightService.formatWeight(data);
  },

  async deleteScene(id) {
    return await axios.delete(`/api/scenes/${id}`);
  },

  async addScene(scene) {
    return await axios.post("/api/scenes", scene);
  },

  async updateScene(scene) {
    return await axios.put(`/api/scenes/${scene._id}`, scene);
  },

  async getSceneById(sceneId) {
    const { data } = await axios.get(`/api/scenes/${sceneId}`);
    return data;
  },

  async getSceneEvents(sceneId) {
    const { data } = await axios.get(`/api/scenes/events/${sceneId}`);
    return eventsToJsFormat(data);
  },

  /**
   * @param {string} sceneId
   * @returns {Array<{id: string, name: string }>}
   */
  async getSceneMarkers(sceneId) {
    const { data } = await axios.get(`/api/scenes/markers/${sceneId}`);
    return data;
  }
};
