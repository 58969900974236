import axios from "axios";
import cloneDeep from "lodash/cloneDeep";

import { type TranslatableText } from "../../../types/TranslatableText.type";
import { type FeatureName } from "../../pages/configDev/features";
import { type Font } from "./fontsService";
import { colorService } from "./utils.service";

export type AdImageLink = {
  name: string;
  percentage: number;
  description?: TranslatableText | null;
  image?: string | null;
  link?: string | null;
  videoFilename?: string | null;
};

export type ApplicationFeatures = {
  [k in FeatureName]: boolean;
};

export type ApplicationSpotify = {
  clientId?: string | null;
  clientSecret?: string | null;
  redirectURI?: string | null;
  valid: boolean;
};

export type ApplicationConfig = {
  _id: string;
  colorBackground: string;
  colorTitle: string;
  colorAction: string;
  colorBlockBackground: string;
  colorBlockText: string;
  menuBackgroundImage: string;
  fontTitle?: Font | null;
  fontTextRegular?: Font | null;
  fontTextSemiBold?: Font | null;
  fontTextBold?: Font | null;
  fbNotifTopic: string;
  timezone: string;
  drawerAds: AdImageLink[];
  splashAds: AdImageLink[];
  splashImage: string;
  splashDuration: number;
  deeplinkHost: string;
  deeplinkPathPrefix: string;
  features: ApplicationFeatures;
  spotify: ApplicationSpotify;
  theme: string;
  editionVersion?: number;
  matomoIdSite?: number;
  isDirty?: boolean;
};
type ColorKeysObj = {
  [k in keyof ApplicationConfig]: k extends `color${string}` ? k : never;
};
export type ApplicationConfigColorKeys = Exclude<
  ColorKeysObj[keyof ColorKeysObj],
  undefined
>;

type FontKeysObj = {
  [k in keyof ApplicationConfig]: k extends `font${string}` ? k : never;
};
export type ApplicationConfigFontKeys = Exclude<
  FontKeysObj[keyof FontKeysObj],
  undefined
>;

function toJsFormat(cfg: ApplicationConfig): ApplicationConfig {
  function convert(key: ApplicationConfigColorKeys) {
    cfg[key] = cfg[key] ? colorService.argb_to_rgba(cfg[key]) : "";
  }

  convert("colorBackground");
  convert("colorTitle");
  convert("colorBlockText");
  convert("colorAction");
  convert("colorBlockBackground");
  return cfg;
}

export function applicationCfgToDbFormat(
  config: ApplicationConfig
): ApplicationConfig {
  const cfg = cloneDeep(config);

  function convert(key: ApplicationConfigColorKeys) {
    cfg[key] = cfg[key] ? colorService.rgba_to_argb(cfg[key]) : "";
  }

  convert("colorBackground");
  convert("colorTitle");
  convert("colorBlockText");
  convert("colorAction");
  convert("colorBlockBackground");
  return cfg;
}

export const ApplicationConfigService = {
  async get(): Promise<ApplicationConfig> {
    const { data } = await axios.get("/api/applicationConfig");
    return toJsFormat(data);
  },

  async updateApplicationCfg(
    applicationConfig: ApplicationConfig
  ): Promise<void> {
    await axios.put(
      "/api/applicationConfig",
      applicationCfgToDbFormat(applicationConfig)
    );
  },

  async shiftDates(): Promise<void> {
    await axios.post("/api/admin/shift-dates?shift=-2");
  },

  async updateApplicationCfgFeatures(
    applicationConfig: ApplicationConfig
  ): Promise<ApplicationConfig> {
    const { data } = await axios.put(
      "/api/applicationConfig/updateFeatures",
      applicationConfig
    );
    return toJsFormat(data);
  },

  async markDirty(): Promise<ApplicationConfig> {
    const { data } = await axios.post("/api/applicationConfig/markDirty");
    return toJsFormat(data);
  }
};
