export const publications = {
  namespaced: true,
  state: {
    showPublicationModal: false,
    showPublishModal: false
  },
  mutations: {
    showPublicationModal(state) {
      state.showPublicationModal = true;
    },

    hidePublicationModal(state) {
      state.showPublicationModal = false;
    },

    showPublishModal(state) {
      state.showPublishModal = true;
    },

    hidePublishModal(state) {
      state.showPublishModal = false;
    }
  }
};
