import type { Module } from "vuex/types/index.js";

import type { RootState } from "@/store";

export class ScrollPositionState {
  scrollPosition: { [key: string]: number } = {};
}

export const scrollPosition: Module<ScrollPositionState, RootState> = {
  namespaced: true,
  state: new ScrollPositionState(),
  mutations: {
    SET_SCROLL_POSITION(
      state: ScrollPositionState,
      payload: { key: string; posY: number }
    ) {
      state.scrollPosition[payload.key] = payload.posY;
    }
  },

  getters: {
    getScrollPosition: (state: ScrollPositionState): any => {
      return (key: string): number => {
        return state.scrollPosition[key] ? state.scrollPosition[key] : 0;
      };
    }
  }
};
