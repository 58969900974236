import axios from "axios";

export type Version = { upToDate: boolean; version: string };

export const VersionService = {
  async getVersion(): Promise<Version> {
    const { data } = await axios.get<Version>(`/api/version`);
    return data;
  }
};
