import { actions } from "./attendee.actions";
import { mutations } from "./attendee.mutations";
import { state } from "./attendee.state";

export const attendee = {
  namespaced: true,
  state,
  mutations,
  actions
};
