import axios from "axios";
import cloneDeep from "lodash/cloneDeep";

import { type Sponsor } from "@/shared/types/sponsor.type";

function addGroupToSponsors(sponsors: Sponsor[]): Sponsor[] {
  return sponsors.map(s => addGroupToSponsor(s));
}

function addGroupToSponsor(sponsor: Sponsor): Sponsor {
  const groupedSponsor = cloneDeep(sponsor);
  groupedSponsor.group = sponsor.category ? sponsor.category._id : "noCategory";
  return groupedSponsor;
}

function removeGroupFromSponsor(sponsor: Sponsor): Sponsor {
  const formatedSponsor = cloneDeep(sponsor);
  delete formatedSponsor.group;
  return formatedSponsor;
}

export const SponsorsService = {
  async getAll(): Promise<Sponsor[]> {
    const { data } = await axios.get("/api/sponsors");
    return addGroupToSponsors(data);
  },

  async updateSponsor(sponsor: Sponsor): Promise<Sponsor> {
    return await axios.put(
      `/api/sponsors/${sponsor._id}`,
      removeGroupFromSponsor(sponsor)
    );
  },

  async delete(id: string): Promise<void> {
    return await axios.delete(`/api/sponsors/${id}`);
  },

  async addSponsor(sponsor: Sponsor): Promise<Sponsor> {
    return await axios.post("/api/sponsors", removeGroupFromSponsor(sponsor));
  },

  async getSponsorById(id: string): Promise<Sponsor> {
    const { data } = await axios.get(`/api/sponsors/${id}`);
    return addGroupToSponsor(data);
  },

  async updateOrder(payload: { [catName: string]: string[] }): Promise<void> {
    await axios.put("api/sponsors/order", payload);
  }
};
