import { type ActionContext } from "vuex";

import { i18n } from "@/i18n/i18n";
import { type GuestType } from "@/pages/guestTypes/GuestType.model";
import { GuestTypeService } from "@/pages/guestTypes/guestType.service";
import { type GuestTypeState } from "@/pages/guestTypes/store/guestType.state";
import { ErrorService } from "@/shared/service/errorService";
import { SnackbarService } from "@/shared/snackbar/snackbar.service";
import { type RootState } from "@/store";

export const actions = {
  async list({ commit }: ActionContext<GuestTypeState, RootState>) {
    try {
      commit("LOAD");
      commit("SET_GUEST_TYPES", await GuestTypeService.getAll());
    } catch (e) {
      ErrorService.handleError(e);
    }
  },

  async delete({
    commit,
    state,
    dispatch
  }: ActionContext<GuestTypeState, RootState>) {
    try {
      commit("LOAD");
      await GuestTypeService.deleteGuestType(state.currentGuestType!._id);
      SnackbarService.info(
        i18n.t("GUEST_TYPE.SNACK_BAR.DELETE_SUCCESS").toString()
      );
      await dispatch("list");
    } catch (e) {
      ErrorService.handleError(e);
    } finally {
      commit("SET_VIEW");
    }
  },

  async loadGuestNames(
    { commit }: ActionContext<GuestTypeState, RootState>,
    guestType: GuestType
  ) {
    try {
      commit("LOAD");
      commit(
        "SET_GUEST_NAMES",
        await GuestTypeService.getGuestNamesByType(guestType._id)
      );
      commit("SET_DELETING", guestType);
    } catch (e) {
      ErrorService.handleError(e);
      commit("SET_VIEW");
    }
  },

  async addGuestType(
    { commit, dispatch }: ActionContext<GuestTypeState, RootState>,
    payload: GuestType
  ) {
    commit("LOAD");
    try {
      await GuestTypeService.addGuestType(payload);

      SnackbarService.info(
        i18n
          .t("GUEST_TYPE.SNACK_BAR.ADD_SUCCESS", {
            name: payload.names["fr"]
          })
          .toString()
      );
    } catch (e) {
      ErrorService.handleError(e);
    } finally {
      await dispatch("list");
      commit("SET_VIEW");
    }
  },

  async editGuestType(
    { commit, dispatch }: ActionContext<GuestTypeState, RootState>,
    payload: GuestType
  ) {
    commit("LOAD");
    try {
      await GuestTypeService.updateGuestType(payload);

      SnackbarService.info(
        i18n
          .t("GUEST_TYPE.SNACK_BAR.EDIT_SUCCESS", { name: payload.names["fr"] })
          .toString()
      );
    } catch (e) {
      ErrorService.handleError(e);
    } finally {
      await dispatch("list");
      commit("SET_VIEW");
    }
  }
};
