import { PAGE_MODE } from "../../../shared/constants";

export const mutations = {
  LOAD(state) {
    state.loading = true;
  },
  SET_ATTENDEE_WHITE_LIST(state, { attendeeWhiteLists, total }) {
    state.attendeeWhiteLists = attendeeWhiteLists;
    state.pagination.totalItems = total;
    state.loading = false;
  },
  SET_PAGINATION(state, pagination) {
    state.pagination = pagination;
  },
  SET_FILTER(state, filter) {
    state.filter = filter;
  },
  SET_DELETING(state, payload) {
    state.mode = PAGE_MODE.DELETE;
    state.currentAttendeeWhiteList = { ...payload };
  },
  SET_DELETE_ALL(state) {
    state.mode = PAGE_MODE.DELETE_ALL;
  },
  SET_VIEW(state) {
    state.mode = PAGE_MODE.VIEW;
    state.loading = false;
    state.currentAttendeeWhiteList = { email: "", lastname: "", firstname: "" };
  },
  UPDATE_CURRENT_ATTENDEE_WHITE_LIST(state, payload) {
    state.currentAttendeeWhiteList = {
      ...state.currentAttendeeWhiteList,
      ...payload
    };
  }
};
