import axios from "axios";

import { type GEDFile, InternalType } from "../types/files.type";

export type ImageTransformations = {
  imageId: string;
  square: {
    x: number;
    y: number;
    zoom: number;
  };
};

function getImgFromCrop(
  crop: null | undefined,
  internalType?: InternalType
): null;
function getImgFromCrop(
  crop: string,
  internalType?: InternalType
): {
  internalType: InternalType;
  image: string;
};
function getImgFromCrop(
  crop: string | undefined | null,
  internalType?: InternalType
): null | {
  internalType: InternalType;
  image: string;
};
function getImgFromCrop(
  crop: string | undefined | null,
  internalType = InternalType.PICTURE
): null | {
  internalType: InternalType;
  image: string;
} {
  if (crop) {
    return {
      internalType: internalType,
      image: crop
    };
  }
  return null;
}

export const FilesService = {
  async uploadImage(image: {
    /** base64 encoded image */
    image: string;
    internalType: InternalType;
    filename?: string;
    transformations?: ImageTransformations;
  }) {
    const { data } = await axios.post<{ filename: string }>(
      "/api/uploadImage",
      image
    );
    return data;
  },

  async uploadVideo(video: File, ratio: number) {
    const form = new FormData();
    form.append("file", video);
    form.append("ratio", String(ratio));
    const { data } = await axios.post<{ filename: string }>(
      "/api/uploadVideo",
      form
    );
    return data;
  },

  async uploadImageFile(file: File, internalType = InternalType.PICTURE) {
    const img = await this.getBase64(file);
    const image = {
      internalType: internalType,
      image: img
    };
    return await this.uploadImage(image);
  },

  deleteFile(fileName: string) {
    axios.delete(`/api/files/${fileName}`);
  },

  getBase64(file: Blob): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result as string);
      };
      reader.onerror = error => reject(error);
    });
  },

  async uploadFont(file: File) {
    const font = await this.getBase64(file);
    const image = {
      internalType: "FILE",
      image: font,
      filename: file.name.split(".")[0]
    };
    const { data } = await axios.post<{
      filename: string;
      postScriptName: string;
    }>("/api/uploadFont", image);
    return data;
  },

  async uploadFile(file: File) {
    const img = await this.getBase64(file);
    const image = {
      internalType: "FILE",
      image: img,
      filename: file.name.split(".")[0]
    };
    const { data } = await axios.post<{ filename: string }>(
      "/api/uploadPdf",
      image
    );
    return data;
  },

  getImgFromCrop,

  async setImageTransform(
    imageId: string,
    imageTransform: ImageTransformations
  ) {
    await axios.put(`/api/imageTransformations/${imageId}`, imageTransform);
  },

  async getImageTransform(imageId: string): Promise<ImageTransformations> {
    const { data } = await axios.get(`/api/imageTransformations/${imageId}`);
    return data;
  },

  async getFileInfo(id: string): Promise<GEDFile> {
    const { data } = await axios.get(`/api/filesInfo/${id}`);
    return data;
  }
};
