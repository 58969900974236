import cloneDeep from "lodash/cloneDeep";

import { ErrorService } from "@/shared/service/errorService";

import { i18n } from "../../../i18n/i18n";
import router from "../../../router.ts";
import { SnackbarService } from "../../../shared/snackbar/snackbar.service";
import { UsersService } from "../users.service";

export const actions = {
  async list({ commit }) {
    try {
      commit("LOAD");
      const users = await UsersService.getAll();
      commit("SET_USERS", users);
    } catch (e) {
      ErrorService.handleError(e);
    } finally {
      commit("UNLOAD");
    }
  },

  async deleteUser({ commit, state, dispatch }) {
    try {
      commit("LOAD");
      await UsersService.deleteUser(state.deletingUserId);
      SnackbarService.info(
        i18n.t("USERS.SNACKBAR.DELETE", {
          string: state.users[state.deletingUserId].name
        })
      );
      await dispatch("list");
    } catch (e) {
      ErrorService.handleError(e);
    } finally {
      commit("RESET");
    }
  },

  async resetPassword({ commit, state }, id) {
    try {
      commit("LOAD");
      await UsersService.resetPassword(id);
      SnackbarService.info(
        i18n.t("USERS.SNACKBAR.RESET", { string: state.users[id].name })
      );
    } catch (e) {
      ErrorService.handleError(e);
    } finally {
      commit("UNLOAD");
    }
  },

  async blockUser({ commit, dispatch, state }, id) {
    try {
      commit("LOAD");
      const user = cloneDeep(state.users[id]);
      user.blocked = !user.blocked;
      await UsersService.updateUser(user);
      const message = user.blocked
        ? i18n.t("USERS.SNACKBAR.BLOCK", { string: user.name })
        : i18n.t("USERS.SNACKBAR.UNBLOCK", { string: user.name });
      SnackbarService.info(message);
      await dispatch("list");
    } catch (e) {
      ErrorService.handleError(e);
    } finally {
      commit("UNLOAD");
    }
  },

  async addUser({ commit, state }, user) {
    try {
      let message = "";
      if (state.editingUser) {
        await UsersService.updateUser(user);
        message = i18n.t("USERS.SNACKBAR.EDIT", { string: user.name });
      } else {
        await UsersService.addUser(user);
        message = i18n.t("USERS.SNACKBAR.ADD", { string: user.name });
      }

      SnackbarService.info(message);
      commit("RESET");
      router.push("/users");
    } catch (e) {
      ErrorService.handleError(e);
    } finally {
      commit("UNLOAD");
    }
  },

  async loadUser({ commit }, id) {
    try {
      commit("LOAD");
      const user = await UsersService.getUserById(id);
      commit("SET_EDITING", user);
    } catch (e) {
      ErrorService.handleError(e);
    } finally {
      commit("UNLOAD");
    }
  }
};
