import axios from "axios";

import { IdsService, weightService } from "../../shared/service/utils.service";
import { type MenuElement } from "../menuEntries/MenuEntry.type";
import { type PageContentWithElements } from "../menuEntries/pageSetup/pageContent.type";
import { type Button, type Info, type Section } from "./Infos";
import { type StateInfo, type StateSection } from "./store/infos.store";

const convertToAPI = (info: StateInfo): Info => ({
  ...info,
  sections: Object.values(info.sections).map(section => ({
    ...section,
    buttons: Object.values(section.buttons)
  }))
});

const convertToStateInfo = (info: Info): StateInfo => {
  const sections: Record<string, StateSection> = info.sections.reduce(
    (acc: Record<string, StateSection>, section: Section) => {
      acc[IdsService.generateId()] = convertToStateSection(section);
      return acc;
    },
    {}
  );
  return { ...info, sections };
};

const convertToStateSection = (section: Section): StateSection => {
  const recordButton: Record<string, Button> = section.buttons.reduce(
    (acc: Record<string, Button>, button: Button) => {
      acc[IdsService.generateId()] = button;
      return acc;
    },
    {}
  );
  return {
    ...section,
    buttons: recordButton
  };
};

export type InfosPratiqueUsage = {
  pageContents: PageContentWithElements[];
  navBarElements: MenuElement[];
};

export const InfosService = {
  async getAll(): Promise<StateInfo[]> {
    const { data } = await axios.get<Info[]>("/api/infosPratiques");
    return weightService.formatWeight(data).map(convertToStateInfo);
  },

  async updateInfo(info: StateInfo): Promise<StateInfo> {
    const { data } = await axios.put<Info>(
      `/api/infosPratiques/${info._id}`,
      convertToAPI(info)
    );
    return convertToStateInfo(data);
  },

  async delete(id: string): Promise<void> {
    return await axios.delete(`/api/infosPratiques/${id}`);
  },

  async addInfo(info: StateInfo): Promise<StateInfo> {
    const { data } = await axios.post<Info>(
      "/api/infosPratiques",
      convertToAPI(info)
    );
    return convertToStateInfo(data);
  },

  async checkUsage(ids: string[]): Promise<InfosPratiqueUsage> {
    const { data } = await axios.get<InfosPratiqueUsage>(
      "/api/infosPratiques/check-usage",
      { params: { ids: ids.join(",") } }
    );
    return data;
  },

  async getInfoById(id: string): Promise<StateInfo> {
    const { data } = await axios.get<Info>(`/api/infosPratiques/${id}`);
    return convertToStateInfo(data);
  }
};
