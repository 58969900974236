import { type SponsorsState } from "@/pages/sponsors/store/sponsors.state";
import { type Sponsor } from "@/shared/types/sponsor.type";

export const mutations = {
  LOAD(state: SponsorsState) {
    state.loading = true;
  },

  UNLOAD(state: SponsorsState) {
    state.loading = false;
  },

  SET_SPONSORS(state: SponsorsState, sponsors: Sponsor[]) {
    state.sponsors = sponsors.reduce(
      (acc, sponsor) => {
        acc[sponsor._id] = sponsor;
        return acc;
      },
      {} as { [key: string]: Sponsor }
    );
  },

  SET_DELETING(state: SponsorsState, id: string) {
    state.deletingSponsorId = id;
  },

  SET_NOT_DELETING(state: SponsorsState) {
    state.deletingSponsorId = null;
  },

  SET_IMAGE(state: SponsorsState, image: string) {
    state.image = image;
  },

  RESET(state: SponsorsState) {
    state.editingSponsor = null;
    state.image = null;
  },

  SET_EDITING(state: SponsorsState, sponsor: Sponsor) {
    state.editingSponsor = sponsor;
    if (sponsor.category) {
      (this as any).commit(
        "sponsorsCategories/SET_CATEGORIES_SELECTION",
        sponsor.category.nameNormalized
      );
    }
    state.image = sponsor.image;
  }
};
