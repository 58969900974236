import { type Route } from "vue-router";

import store from "../../store";

export const UsersRouter = [
  {
    path: "/users",
    component: () => import("./Users.vue")
  },
  {
    path: "/users/create",
    component: () => import("./AddUser.vue")
  },
  {
    path: "/users/:id",
    component: () => import("./AddUser.vue"),
    beforeEnter: async (to: Route, from: Route, next: any) => {
      await store.dispatch("users/loadUser", to.params.id);
      next();
    }
  }
];
