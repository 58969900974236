import dayjs, { type Dayjs } from "dayjs";
import { type Module } from "vuex";

import { type ScreenTableDateDto } from "@/pages/analytics/models/analytics.type";
import { type AdvertisementDto } from "@/pages/analytics/models/analytics-advertisement.type";
import { type AnalyticsNotification } from "@/pages/analytics/models/analytics-notification.type";
import {
  type UserCountryDto,
  type UserSectionDashboardDto,
  type UserSessionPeriodDto
} from "@/pages/analytics/models/analytics-user.type";
import { actions } from "@/pages/analytics/store/dashboardAnalytics.actions";
import { mutations } from "@/pages/analytics/store/dashboardAnalytics.mutations";
import { type RootState } from "@/store";

const TODAY = { name: "MATOMO.PERIOD.TODAY", id: "TODAY" };
const LAST_WEEK = { days: 7, name: "MATOMO.PERIOD.LAST_WEEK", id: "LAST_WEEK" };
const LAST_MONTH = {
  days: 30,
  name: "MATOMO.PERIOD.LAST_MONTH",
  id: "LAST_MONTH"
};
const LAST_QUARTER = {
  months: 3,
  name: "MATOMO.PERIOD.LAST_QUARTER",
  id: "LAST_QUARTER"
};
const LAST_SEMESTER = {
  months: 6,
  name: "MATOMO.PERIOD.LAST_SEMESTER",
  id: "LAST_SEMESTER"
};
const LAST_YEAR = {
  months: 12,
  name: "MATOMO.PERIOD.LAST_YEAR",
  id: "LAST_YEAR"
};
const START_OF_YEAR = {
  name: "MATOMO.PERIOD.START_OF_YEAR",
  id: "START_OF_YEAR"
};

export const PERIODS: Period[] = [
  TODAY,
  LAST_WEEK,
  LAST_MONTH,
  LAST_QUARTER,
  LAST_SEMESTER,
  LAST_YEAR,
  START_OF_YEAR
];

export type Period = { name: string; id: string };
export type DayPeriod = { days: number } & Period;
export type MonthPeriod = { months: number } & Period;

function isDayPeriod(period: any): period is DayPeriod {
  return !!period.days;
}

function isMonthPeriod(period: any): period is MonthPeriod {
  return !!period.months;
}

function getPeriodFromDateRange(period: Period): {
  start: Dayjs;
  end: Dayjs;
} {
  const today = dayjs();
  if (period.id === "TODAY") {
    return { start: today, end: today };
  }
  if (isDayPeriod(period)) {
    return {
      start: dayjs().subtract(1, "day").subtract(period.days, "day"),
      end: today.subtract(1, "day")
    };
  }
  if (isMonthPeriod(period)) {
    return {
      start: dayjs().subtract(1, "day").subtract(period.months, "month"),
      end: today.subtract(1, "day")
    };
  }
  if (period.id === "START_OF_YEAR") {
    return { start: dayjs().startOf("year"), end: today.subtract(1, "day") };
  }
  throw Error("period not implemented");
}

export function getPeriodById(id: string | null): Period | undefined {
  if (id == null) return undefined;
  return PERIODS.find(period => period.id === id);
}

export const STAT_REQUEST_LOADING = { status: "loading" };
export const STAT_REQUEST_ERROR = { status: "error" };

export type StatRequestDTO<T> =
  | typeof STAT_REQUEST_LOADING
  | T
  | typeof STAT_REQUEST_ERROR;

export function isLoaded<T>(stat: StatRequestDTO<T>): stat is T {
  return stat !== STAT_REQUEST_LOADING && stat !== STAT_REQUEST_ERROR;
}

export class DashboardMatomoState {
  userMetrics: StatRequestDTO<Map<string, number>> = STAT_REQUEST_LOADING;
  userSectionDashboardMetrics: StatRequestDTO<UserSectionDashboardDto> =
    STAT_REQUEST_LOADING;
  userCountryMetrics: StatRequestDTO<UserCountryDto[]> = STAT_REQUEST_LOADING;
  userSessionMetrics: StatRequestDTO<Map<string, UserSessionPeriodDto[]>> =
    STAT_REQUEST_LOADING;
  schedulesMetrics: StatRequestDTO<ScreenTableDateDto[]> = STAT_REQUEST_LOADING;
  eventMetrics: StatRequestDTO<ScreenTableDateDto[]> = STAT_REQUEST_LOADING;
  favoriteEventMetrics: StatRequestDTO<ScreenTableDateDto[]> =
    STAT_REQUEST_LOADING;
  commentedEventMetrics: StatRequestDTO<ScreenTableDateDto[]> =
    STAT_REQUEST_LOADING;
  ratedEventMetrics: StatRequestDTO<ScreenTableDateDto[]> =
    STAT_REQUEST_LOADING;
  participatedEventMetrics: StatRequestDTO<ScreenTableDateDto[]> =
    STAT_REQUEST_LOADING;
  guestMetrics: StatRequestDTO<Map<string, ScreenTableDateDto[]>> =
    STAT_REQUEST_LOADING;
  favoriteGuestMetrics: StatRequestDTO<Map<string, ScreenTableDateDto[]>> =
    STAT_REQUEST_LOADING;
  guestListMetrics: StatRequestDTO<ScreenTableDateDto[]> = STAT_REQUEST_LOADING;
  galleryMetrics: StatRequestDTO<ScreenTableDateDto[]> = STAT_REQUEST_LOADING;
  infoPratiqueMetrics: StatRequestDTO<ScreenTableDateDto[]> =
    STAT_REQUEST_LOADING;
  webviewMetrics: StatRequestDTO<ScreenTableDateDto[]> = STAT_REQUEST_LOADING;
  advertisementMetrics: StatRequestDTO<AdvertisementDto> = STAT_REQUEST_LOADING;
  notificationMetrics: StatRequestDTO<AnalyticsNotification[]> =
    STAT_REQUEST_LOADING;
  otherScreenMetrics: StatRequestDTO<ScreenTableDateDto[]> =
    STAT_REQUEST_LOADING;

  filterDatesModel: Period =
    getPeriodById(localStorage.getItem("ANALYTICS_PERIOD")) || LAST_WEEK;
}

export type StatName = keyof Omit<DashboardMatomoState, "filterDatesModel">;

export const matomoDashboardStore: Module<DashboardMatomoState, RootState> = {
  namespaced: true,
  state: new DashboardMatomoState(),
  actions,
  getters: {
    getPeriodDates: (state): { start: Dayjs; end: Dayjs } => {
      return getPeriodFromDateRange(state.filterDatesModel);
    }
  },
  mutations
};
