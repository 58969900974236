import type { Module } from "vuex/types/index.js";

import type { RootState } from "@/store";

import { actions } from "./gallery.actions";
import { getters } from "./gallery.getters";
import { mutations } from "./gallery.mutations";
import { type GalleryState, state } from "./gallery.state";

export const gallery: Module<GalleryState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
