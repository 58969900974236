import { cloneDeep } from "lodash";
import { type ActionContext } from "vuex";

import { i18n } from "@/i18n/i18n";
import { ErrorService } from "@/shared/service/errorService";
import { FilesService } from "@/shared/service/files.service";
import { SnackbarService } from "@/shared/snackbar/snackbar.service";
import { type RootState } from "@/store";

import { type GalleryItem } from "../gallery.type";
import { type GEDFile } from "./../../../shared/types/files.type";
import { GalleryService } from "./../gallery.service";
import { type GalleryState } from "./gallery.state";

export const actions = {
  async getGalleryItems({ commit }: ActionContext<GalleryState, RootState>) {
    try {
      commit("LOAD");
      const galleryItems: GalleryItem[] = await GalleryService.getAll();
      commit("SET_GALLERY", galleryItems);
    } catch (e) {
      ErrorService.handleError(e);
    } finally {
      commit("UNLOAD");
    }
  },

  async uploadFile(
    { commit, dispatch }: ActionContext<GalleryState, RootState>,
    file: File
  ) {
    try {
      commit("LOAD");
      const filename = (await FilesService.uploadFile(file)).filename;
      commit("SET_IMAGE", filename);
      await dispatch("getFileInfo");
    } catch (e) {
      ErrorService.handleError(e);
    } finally {
      commit("UNLOAD");
    }
  },

  async uploadThumbnailImage({
    commit,
    state
  }: ActionContext<GalleryState, RootState>) {
    try {
      commit("LOAD");
      const image = FilesService.getImgFromCrop(state.thumbnailImageCrop);
      const filename = (await FilesService.uploadImage(image)).filename;
      commit("SET_THUMBNAIL", filename);
    } catch (e) {
      ErrorService.handleError(e);
    } finally {
      commit("UNLOAD");
    }
  },

  async changeVisibility(
    { commit, state, dispatch }: ActionContext<GalleryState, RootState>,
    id: string
  ) {
    try {
      commit("LOAD");
      const gallery: GalleryItem = cloneDeep(state.gallery[id]);
      gallery.visible = !gallery.visible;
      await GalleryService.updateGalleryItem(gallery);
      gallery.visible
        ? SnackbarService.info(
            i18n
              .t("GALLERY.SNACKBAR.HIDE_OFF", { string: gallery.titles["fr"] })
              .toString()
          )
        : SnackbarService.info(
            i18n
              .t("GALLERY.SNACKBAR.HIDE", { string: gallery.titles["fr"] })
              .toString()
          );
      await dispatch("getGalleryItems");
    } catch (e) {
      ErrorService.handleError(e);
    } finally {
      commit("UNLOAD");
    }
  },

  async delete({
    commit,
    state,
    dispatch
  }: ActionContext<GalleryState, RootState>) {
    try {
      commit("LOAD");
      await GalleryService.delete(state.deletingGalleryItemId);
      commit("SET_NOT_DELETING");
      SnackbarService.info(i18n.t("GALLERY.SNACKBAR.DELETE").toString());
      await dispatch("getGalleryItems");
    } catch (e) {
      ErrorService.handleError(e);
    } finally {
      commit("UNLOAD");
    }
  },

  async addGalleryItem(
    { commit, dispatch, state }: ActionContext<GalleryState, RootState>,
    gallery: GalleryItem
  ) {
    try {
      commit("LOAD");
      if (state.thumbnailImageCrop) {
        await dispatch("uploadThumbnailImage");
      }
      gallery.thumbnailImage = state.thumbnailImage;
      gallery.image = state.image;
      let message: string = "";
      if (state.editingGalleryItemId) {
        await GalleryService.updateGalleryItem(gallery);
        message = i18n
          .t("GALLERY.SNACKBAR.EDIT", {
            string: gallery.titles["fr"]
          })
          .toString();
      } else {
        await GalleryService.postGallery(gallery);
        message = i18n
          .t("GALLERY.SNACKBAR.ADD", {
            string: gallery.titles["fr"]
          })
          .toString();
      }
      SnackbarService.info(message);
      commit("RESET_ADDING");
      await dispatch("getGalleryItems");
    } catch (e) {
      ErrorService.handleError(e);
    } finally {
      commit("UNLOAD");
    }
  },

  async reorder(
    { commit, dispatch }: ActionContext<GalleryState, RootState>,
    newOrder: GalleryItem[]
  ) {
    try {
      commit("LOAD");
      commit(
        "SET_NEW_ORDER",
        newOrder.map(i => i._id)
      );
      await GalleryService.updateGalleryOrder(newOrder.map(i => i._id));
      await dispatch("getGalleryItems");
      SnackbarService.info(i18n.t("GALLERY.SNACKBAR.REORDER").toString());
    } catch (e) {
      ErrorService.handleError(e);
    } finally {
      commit("UNLOAD");
    }
  },

  async getFileInfo({ commit, state }: ActionContext<GalleryState, RootState>) {
    {
      try {
        commit("LOAD");
        const fileInfo: GEDFile = await FilesService.getFileInfo(state.image);
        commit("SET_PDF_FILENAME", fileInfo.fileName);
      } catch (e) {
        ErrorService.handleError(e);
      } finally {
        commit("UNLOAD");
      }
    }
  },

  async setEditing(
    { commit, state }: ActionContext<GalleryState, RootState>,
    id: string
  ) {
    try {
      commit("LOAD");
      const fileInfo = await FilesService.getFileInfo(state.gallery[id].image);
      commit("SET_EDITING_ITEM", { fileInfo, id });
    } catch (e) {
      ErrorService.handleError(e);
    } finally {
      commit("UNLOAD");
    }
  }
};
