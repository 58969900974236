import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import { type TranslatableText } from "types/TranslatableText.type";
import { type DataOptions } from "vuetify";

import { i18n } from "@/i18n/i18n";
import router from "@/router";
import { PAGE_MODE, type PageMode } from "@/shared/constants";
import { translatableText } from "@/shared/filters/translatableText.filter";
import { ErrorService } from "@/shared/service/errorService";
import {
  FilesService,
  type ImageTransformations
} from "@/shared/service/files.service";
import { SnackbarService } from "@/shared/snackbar/snackbar.service";
import { InternalType } from "@/shared/types/files.type";
import { type MusicGroup } from "@/shared/types/musicGroup/musicGroup.type";
import { type MusicGroupEventFormInput } from "@/shared/types/musicGroup/musicGroupEvent.type";
import { ProgramEventNotifSendTime } from "@/shared/types/progEvent.type";
import { mapByEntries, type Optional } from "@/utils";
import { createStore } from "@/utils/createStore";

import { ProgEventService } from "../../progEvent/progEvent.service";
import { GuestsService } from "../guests.service";
import { type Genre, genres } from "./genres.module";

export const defaultInitGuestEvent: Partial<MusicGroupEventFormInput> = {
  scene: undefined,
  day: undefined,
  eventTicketLink: undefined,
  startTime: "12:00",
  endTime: "13:00",
  date: undefined,
  isVisible: false,
  program: undefined,
  enableAttending: false,
  enableRating: false,
  programEventNotifSendTime: ProgramEventNotifSendTime.NEVER,
  notifPlanned: false,
  ticketOpenInWebview: true
};

export class GuestsState {
  mode: PageMode = PAGE_MODE.VIEW;
  genres = {
    selectedGenres: [] as Genre[],
    genres: {} as { [key: string]: Genre }
  }; // TODO overriden by submodule genres
  guests: { [key: string]: MusicGroup } = {};
  nbGuests = 0;
  countByType = {};
  deleteGuestId: string | null = null;
  togglingGuest: { guestId: string; names: TranslatableText } | null = null;
  showHideMode: "SHOW" | "HIDE" = "HIDE";
  selectedGuestRows = [] as MusicGroup[];
  loading = false;
  search = "";
  selectedGuestTypeId: string | undefined;
  options: Optional<
    DataOptions,
    "groupBy" | "groupDesc" | "multiSort" | "mustSort"
  > = {
    page: 1,
    itemsPerPage: 10,
    sortDesc: [true],
    sortBy: ["names.fr"]
  };

  //Guest form
  editingGuest: MusicGroup | undefined = undefined;
  links = {} as {
    [key: string]: {
      value: string;
      url: string;
      text: string;
    };
  };
  image?: string | null = null;
  guestsLinks: string[] = [];

  //for Events
  guestsIdsNames: Array<{ names: TranslatableText }> = [];

  // location
  autocompleteLocations: Array<string[]> = [];
  //
  transformations: ImageTransformations | null = null;
  importBatchId = "";
  importFeedback = {
    successes: [{ key: "", content: {} }],
    errors: [{ key: "", content: {} }],
    toProcess: [{ key: "", content: {} }]
  };

  // Guest events
  guestEventEditingOldValue: MusicGroupEventFormInput | null = null;
  currentGuestEvent: Partial<MusicGroupEventFormInput> | null = null;
  guestEvents: MusicGroupEventFormInput[] = [];
  deletingGuestEvents: MusicGroupEventFormInput[] = [];
  deletingGuestMarkers: { id: string; name: string }[] = [];
  initGuestEvent = { ...defaultInitGuestEvent };
}

const state = new GuestsState();

export const {
  guests,
  commit: guestsCommit,
  dispatch: guestsDispatch,
  mapGetters: guestsMapGetters,
  mapState: guestsMapState,
  useState: useGuestsState,
  useGetter: useGuestsGetter
} = createStore({
  moduleName: "guests",
  initState: state,
  modules: { genres },
  mutations: {
    LOAD(state) {
      state.loading = true;
    },

    UNLOAD(state) {
      state.loading = false;
    },

    SET_GUESTS(
      state,
      {
        musicGroups,
        total,
        countByType
      }: {
        musicGroups: MusicGroup[];
        total: number;
        countByType: { [key: string]: number };
      }
    ) {
      state.guests = musicGroups.reduce(
        (acc: { [key: string]: MusicGroup }, guest) => {
          acc[guest._id] = guest;
          return acc;
        },
        {}
      );
      state.nbGuests = total;
      state.countByType = countByType;
    },

    SET_IMAGE_TRANSFORMATIONS(state, transformations: ImageTransformations) {
      state.transformations = transformations;
    },

    SET_VIEW(state) {
      state.mode = PAGE_MODE.VIEW;
      state.deleteGuestId = null;
      state.selectedGuestRows = [];
      state.editingGuest = undefined;
      state.image = null;
      state.loading = false;
      state.search = "";
      state.links = {};
      state.genres.selectedGenres = [];
      state.guestEvents = [];
    },

    SET_ADDING(state) {
      state.mode = PAGE_MODE.ADD;
      state.loading = false;
      state.guestEvents = [];
      state.currentGuestEvent = null;
      state.initGuestEvent = { ...defaultInitGuestEvent };
    },

    SET_EDITING(state, guest: MusicGroup) {
      state.mode = PAGE_MODE.EDIT;
      state.editingGuest = { ...guest };
      state.image = guest.image;
      state.loading = false;
      state.initGuestEvent = { ...defaultInitGuestEvent };
      if (guest.genres != null) {
        state.genres.selectedGenres = guest.genres
          .map(id => state.genres.genres[id])
          .filter(n => n != null);
      } else {
        state.genres.selectedGenres = [];
      }
    },

    SET_NOT_DELETING(state) {
      state.mode = PAGE_MODE.VIEW;
      state.deleteGuestId = null;
    },

    SET_NOT_TOGGLING_VISIBILITY(state) {
      state.mode = PAGE_MODE.VIEW;
      state.togglingGuest = null;
    },

    SET_DELETING_MULTIPLE_GUESTS(state) {
      state.mode = PAGE_MODE.DELETE_MULTIPLE;
    },

    SET_EDIT_MULTIPLE_GUESTS(state) {
      state.mode = PAGE_MODE.EDIT_MULTIPLE;
    },

    RESET_MULTI_SELECTION(state) {
      state.selectedGuestRows = [];
    },

    SET_SEARCH(state, search: string) {
      state.search = search;
      state.options.page = 1;
    },

    SET_SELECTED_GUEST_TYPE_ID(state, selectedGuestTypeId: string) {
      state.selectedGuestTypeId = selectedGuestTypeId;
      state.options.page = 1;
    },

    SET_SELECTED_GUEST_ROWS(state, guests: MusicGroup[]) {
      state.selectedGuestRows = [...guests];
    },

    SET_IMAGE(state, image: string) {
      state.image = image;
      state.loading = false;
    },

    SET_DELETE_LINK(state, linkValue: string) {
      delete state.links[linkValue];
      state.links = { ...state.links };
    },

    SET_LINK_URL(state, payload: { link: { value: string }; val: string }) {
      state.links[payload.link.value].url = payload.val;
    },

    LOAD_LINKS(state) {
      const tmpLinks: Record<string, string> =
        state.mode !== PAGE_MODE.EDIT
          ? state.guestsLinks.reduce<Record<string, string>>((acc, link) => {
              acc[link] = "";
              return acc;
            }, {})
          : state.editingGuest?.links != null
            ? Object.keys(state.editingGuest.links)
                .filter(key => !!state.editingGuest?.links[key])
                .reduce<Record<string, string>>((acc, key) => {
                  acc[key] = state.editingGuest!.links[key];
                  return acc;
                }, {})
            : {};
      state.links = mapByEntries(
        tmpLinks,
        ([key, value]) =>
          [
            key,
            {
              value: key,
              url: value,
              text: i18n.t(
                `GUESTS.ADDING.LINKS_LIST.${key.toUpperCase()}`
              ) as string
            }
          ] as const
      );
    },

    ADD_LINK(state, link: GuestsState["links"][string]) {
      state.links = { ...state.links, [link.value]: link };
    },

    SET_OPTIONS(state, options: GuestsState["options"]) {
      state.options = options;
    },

    RESET_PAGINATION(state) {
      state.options.page = 1;
      state.options.itemsPerPage = 10;
    },

    SET_IMPORT_FEEDBACK(state, importResult: GuestsState["importFeedback"]) {
      state.importFeedback = importResult;
    },

    SET_IMPORT_BATCH_ID(state, importBatchId: string) {
      state.importBatchId = importBatchId;
    },

    // Guest Events

    ADD_GUEST_EVENT(state) {
      state.guestEventEditingOldValue = null;
      state.currentGuestEvent = cloneDeep(state.initGuestEvent);
    },

    EDIT_GUEST_EVENT(state, guestEvent: MusicGroupEventFormInput) {
      state.guestEventEditingOldValue = cloneDeep(guestEvent);
      state.currentGuestEvent = cloneDeep(guestEvent);
    },

    UPDATE_GUEST_EVENT(state, guestEvent) {
      state.currentGuestEvent = cloneDeep(guestEvent);
    },

    CANCEL_GUEST_EVENT(state) {
      state.guestEventEditingOldValue = null;
      state.currentGuestEvent = null;
    },

    SAVE_GUEST_EVENT(state) {
      if (state.guestEventEditingOldValue != null) {
        state.guestEvents = state.guestEvents.filter(
          e => !isEqual(e, state.guestEventEditingOldValue)
        );
      }
      if (state.currentGuestEvent) {
        state.guestEvents = [
          ...state.guestEvents,
          cloneDeep(state.currentGuestEvent) as MusicGroupEventFormInput
        ];
      }
      state.initGuestEvent = {
        ...cloneDeep(state.currentGuestEvent),
        date: undefined,
        eventTicketLink: null,
        startTime: "12:00",
        endTime: "13:00"
      };
      state.currentGuestEvent = null;
      state.guestEventEditingOldValue = null;
    },

    DELETE_GUEST_EVENT(state, guestEvent: MusicGroupEventFormInput) {
      state.currentGuestEvent = null;
      state.guestEvents = state.guestEvents.filter(
        e => !isEqual(e, guestEvent)
      );
    },

    SET_GUEST_EVENTS(state, events) {
      state.deletingGuestEvents = [...events];
    },

    SET_GUEST_MARKERS(state, markers) {
      state.deletingGuestMarkers = [...markers];
    }
  },
  actions: {
    async list({ commit }) {
      commit("LOAD");
      const guests = await GuestsService.getAll();
      commit("SET_GUESTS", guests);
      commit("UNLOAD");
    },

    async getGuestsLinks({ commit }) {
      try {
        commit("SET_ADDING");
        commit("LOAD");
        const guests = await GuestsService.getGuestsLinks();
        state.guestsLinks = guests;
      } catch (e) {
        ErrorService.handleError(e);
      } finally {
        commit("UNLOAD");
      }
    },

    async getGuestsLight({ commit }) {
      try {
        commit("LOAD");
        const guests = await GuestsService.getGuestsLight();
        state.guestsIdsNames = guests;
      } catch (e) {
        ErrorService.handleError(e);
      } finally {
        commit("UNLOAD");
      }
    },

    async getPaginatedGuests({ commit, state }) {
      try {
        commit("LOAD");
        const offset = (state.options.page - 1) * state.options.itemsPerPage;
        const data = await GuestsService.getPaginatedGuests(
          state.search,
          state.options,
          offset,
          state.selectedGuestTypeId
        );
        commit("SET_GUESTS", data);
        commit("RESET_MULTI_SELECTION");
      } catch (e) {
        ErrorService.handleError(e);
      } finally {
        commit("UNLOAD");
      }
    },

    async delete({ commit, state, dispatch }) {
      if (!state.deleteGuestId) return;
      commit("LOAD");
      try {
        await GuestsService.deleteGuest(state.deleteGuestId);
        await dispatch("getPaginatedGuests");
        SnackbarService.info(
          i18n
            .t("GUESTS.SNACK_BAR.DELETE", {
              string: translatableText(state.guests[state.deleteGuestId]?.names)
            })
            .toString()
        );
      } catch (e) {
        ErrorService.handleError(e);
      } finally {
        commit("SET_VIEW");
      }
    },

    async multipleDelete({ commit, getters, dispatch }) {
      commit("LOAD");
      try {
        const guestsIds = getters.getSelectedGuestIds;
        await GuestsService.deleteMultipleGuests(guestsIds);
        await dispatch("getPaginatedGuests");
        SnackbarService.info(
          i18n
            .t("GUESTS.SNACK_BAR.MULTIPLE_DELETE", {
              number: guestsIds.length
            })
            .toString()
        );
      } catch (e) {
        ErrorService.handleError(e);
      } finally {
        commit("SET_VIEW");
      }
    },

    async toggleVisibility(
      { commit, dispatch, state },
      { eventsIds }: { eventsIds: string[] }
    ) {
      commit("LOAD");
      try {
        if (!state.togglingGuest) return;
        await GuestsService.hideShowBatch(
          [state.togglingGuest.guestId],
          state.showHideMode === "SHOW"
        );
        await ProgEventService.hideShowBatch(
          eventsIds,
          state.showHideMode === "SHOW"
        );
        await dispatch("getPaginatedGuests");
        state.showHideMode
          ? SnackbarService.info(
              i18n
                .t("GUESTS.SNACK_BAR.SHOW", {
                  string: translatableText(state.togglingGuest.names)
                })
                .toString()
            )
          : SnackbarService.info(
              i18n
                .t("GUESTS.SNACK_BAR.HIDE", {
                  string: translatableText(state.togglingGuest.names)
                })
                .toString()
            );
      } catch (e) {
        ErrorService.handleError(e);
      } finally {
        commit("SET_VIEW");
      }
    },

    async setVisibilityBatch(
      { getters, commit, dispatch, state },
      { eventsIds }: { eventsIds: string[] }
    ) {
      commit("LOAD");
      try {
        const nb = getters.getSelectedGuestIds.length;
        await GuestsService.hideShowBatch(
          getters.getSelectedGuestIds,
          state.showHideMode === "SHOW"
        );
        await ProgEventService.hideShowBatch(
          eventsIds,
          state.showHideMode === "SHOW"
        );
        await dispatch("getPaginatedGuests");
        state.showHideMode
          ? SnackbarService.info(
              i18n
                .t("GUESTS.SNACK_BAR.BATCH_SHOW", {
                  number: nb
                })
                .toString()
            )
          : SnackbarService.info(
              i18n
                .t("GUESTS.SNACK_BAR.BATCH_HIDE", {
                  number: nb
                })
                .toString()
            );
      } catch (e) {
        ErrorService.handleError(e);
      } finally {
        commit("SET_VIEW");
      }
    },

    async uploadImage({ commit }, file) {
      commit("LOAD");
      try {
        const filename = file
          ? (await FilesService.uploadImageFile(file, InternalType.PICTURE))
              .filename
          : null;
        commit("SET_IMAGE", filename);
      } catch (e) {
        ErrorService.handleError(e);
      }
    },

    async addGuest({ state, commit, dispatch }, guest: MusicGroup) {
      commit("LOAD");
      try {
        const eventsCount = state.guestEvents ? state.guestEvents.length : 0;
        const guestToSend = cloneDeep(guest);
        guestToSend.image = state.image;
        guestToSend.links = {};
        Object.values(state.links).forEach(link => {
          guestToSend.links[link.value] = link.url;
        });
        guestToSend.genres = state.genres.selectedGenres.map(
          genre => genre._id
        );

        let message;
        if (state.mode === PAGE_MODE.EDIT) {
          await GuestsService.updateGuest(
            guestToSend,
            cloneDeep(state.guestEvents)
          );
          message = i18n
            .t("GUESTS.SNACK_BAR.EDIT", {
              string: translatableText(guest.names)
            })
            .toString();
        } else {
          await GuestsService.addGuest(
            guestToSend,
            cloneDeep(state.guestEvents)
          );
          message = i18n
            .t("GUESTS.SNACK_BAR.ADD", {
              string: translatableText(guest.names)
            })
            .toString();
        }

        if (state.transformations) {
          await FilesService.setImageTransform(
            state.transformations.imageId,
            state.transformations
          );
        }

        await dispatch("getPaginatedGuests");
        router.push("/guests");
        SnackbarService.info(message);
        setTimeout(
          () =>
            eventsCount > 0
              ? SnackbarService.info(
                  i18n
                    .t("GUESTS.SNACK_BAR.EVENTS", { count: eventsCount })
                    .toString()
                )
              : "",
          3200
        );
      } catch (e) {
        ErrorService.handleError(e);
      } finally {
        commit("UNLOAD");
      }
    },

    async loadGuestsLocations({ commit }) {
      try {
        commit("LOAD");
        const locations = await GuestsService.getGuestsLocations();

        state.autocompleteLocations = locations;
      } catch (e) {
        ErrorService.handleError(e);
      } finally {
        commit("UNLOAD");
      }
    },

    async loadGuest({ commit, dispatch }, guestId) {
      commit("LOAD");
      try {
        await dispatch("genres/getGenres");
        const guest = await GuestsService.getGuestById(guestId);
        if (guest.image) {
          const transformations = await FilesService.getImageTransform(
            guest.image
          );
          commit("SET_IMAGE_TRANSFORMATIONS", transformations);
        }
        commit("SET_EDITING", guest);
        commit("LOAD_LINKS");
      } catch (e) {
        ErrorService.handleError(e);
      } finally {
        commit("UNLOAD");
      }
    },

    async saveFile({ commit, dispatch }, file) {
      commit("LOAD");
      await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.onerror = error => reject(error);
      }).then(async x64File => {
        try {
          const results = await GuestsService.uploadFile(x64File);
          commit("SET_IMPORT_BATCH_ID", results.data);
          await dispatch("updateImportList", results.data);
        } catch (e) {
          ErrorService.handleError(e);
          commit("UNLOAD");
        }
      });
    },

    async updateImportList({ commit, dispatch }, batchId) {
      const results = await GuestsService.getImportResult(batchId);
      commit("SET_IMPORT_FEEDBACK", results.data);
      if (results.data.toProcess && results.data.toProcess.length > 0) {
        setTimeout(async () => {
          await dispatch("updateImportList", batchId);
        }, 7000);
      } else {
        commit("UNLOAD");
      }
    },

    async resetImport({ commit }) {
      commit("SET_IMPORT_FEEDBACK", {
        successes: [],
        errors: [],
        toProcess: []
      });
      commit("SET_IMPORT_BATCH_ID", "");
    },

    async setDeletionMode({ commit }, guestId: string) {
      commit("LOAD");
      try {
        const eventList = await GuestsService.getGuestEvents([guestId]);
        commit("SET_GUEST_EVENTS", eventList);
        const markerList = await GuestsService.getGuestMarkers([guestId]);
        commit("SET_GUEST_MARKERS", markerList);
        state.mode = PAGE_MODE.DELETE;
        state.deleteGuestId = guestId;
      } catch (e) {
        ErrorService.handleError(e);
      } finally {
        commit("UNLOAD");
      }
    },

    async setToggleVisibilityMode(
      { commit, state },
      {
        guestId,
        show,
        names
      }: { guestId: string; show: boolean; names: TranslatableText }
    ) {
      commit("LOAD");
      try {
        const eventList = await GuestsService.getGuestEvents([guestId]);
        commit("SET_GUEST_EVENTS", eventList);
        state.mode = PAGE_MODE.TOGGLING_VISIBILITY;
        state.togglingGuest = { guestId, names };
        state.showHideMode = show ? "SHOW" : "HIDE";
      } catch (e) {
        ErrorService.handleError(e);
      } finally {
        commit("UNLOAD");
      }
    },

    async setMultipleDeletionMode({ state, commit }) {
      commit("LOAD");
      try {
        const guestIds = state.selectedGuestRows.map(g => g._id);
        const eventList = await GuestsService.getGuestEvents(guestIds);
        commit("SET_GUEST_EVENTS", eventList);
        const markerList = await GuestsService.getGuestMarkers(guestIds);
        commit("SET_GUEST_MARKERS", markerList);
        commit("SET_DELETING_MULTIPLE_GUESTS");
      } catch (e) {
        ErrorService.handleError(e);
      } finally {
        commit("UNLOAD");
      }
    },

    async setMultipleVisibilityMode({ state, commit }, show: boolean) {
      commit("LOAD");
      try {
        const guestIds = state.selectedGuestRows.map(g => g._id);
        const eventList = await GuestsService.getGuestEvents(guestIds);
        commit("SET_GUEST_EVENTS", eventList);
        state.mode = PAGE_MODE.SETTING_VISIBILITY_MULTIPLE;
        state.showHideMode = show ? "SHOW" : "HIDE";
      } catch (e) {
        ErrorService.handleError(e);
      } finally {
        commit("UNLOAD");
      }
    },

    async editGuestsBatch({ commit, dispatch }, typeId) {
      commit("LOAD");
      try {
        const guestIds = state.selectedGuestRows.map(g => g._id);
        await GuestsService.editGuestsBatch(guestIds, typeId);
        await dispatch("getPaginatedGuests");
        SnackbarService.info(
          i18n
            .t("GUESTS.SNACK_BAR.BATCH_EDIT", { string: guestIds.length })
            .toString()
        );
      } catch (e) {
        ErrorService.handleError(e);
      } finally {
        commit("RESET_MULTI_SELECTION");
        commit("UNLOAD");
      }
    }
  },
  getters: {
    filteredSearch: state => {
      const tab = Object.keys(state.guests).map(id => state.guests[id]);

      return state.search && state.search.length > 0
        ? tab.filter(n =>
            translatableText(n.names)
              .toLowerCase()
              .includes(state.search.toLowerCase())
          )
        : tab;
    },

    getGuestsLight: state => {
      const guests = [...state.guestsIdsNames];
      guests.sort((a, b) =>
        translatableText(a.names).toLowerCase() >
        translatableText(b.names).toLowerCase()
          ? 1
          : -1
      );
      return guests;
    },

    getGuestsByType: state => {
      return (type: string) =>
        Object.values(state.guests).filter(g => g.type === type);
    },

    getGuest: state => {
      return state.mode !== PAGE_MODE.EDIT
        ? {
            copyright: null,
            description: null,
            descriptions: {
              fr: "",
              en: ""
            },
            genres: [],
            location: [],
            image: null,
            label: null,
            links: {},
            names: { fr: "" },
            notVisibleUntil: null,
            origin: null,
            schedule: null,
            showArtist: false,
            spotifyPlayableSongs: [],
            type: "",
            villageEvent: false,
            weight: null
          }
        : state.editingGuest;
    },

    getLinks: state => {
      return state.links;
    },

    getMultiDeletionCount: state => {
      return state.selectedGuestRows.length;
    },

    showDeleteDialog: state => {
      return state.mode === PAGE_MODE.DELETE && state.deleteGuestId;
    },

    showMultiDeleteDialog: state => {
      return (
        state.mode === PAGE_MODE.DELETE_MULTIPLE &&
        state.selectedGuestRows &&
        state.selectedGuestRows.length > 0
      );
    },

    showMultiEditDialog: state => {
      return (
        state.mode === PAGE_MODE.EDIT_MULTIPLE &&
        state.selectedGuestRows &&
        state.selectedGuestRows.length > 0
      );
    },

    showToggleVisibilityDialog: state => {
      return (
        state.mode === PAGE_MODE.TOGGLING_VISIBILITY &&
        state.togglingGuest != null
      );
    },

    showMultiSetVisibilityDialog: state => {
      return (
        state.mode === PAGE_MODE.SETTING_VISIBILITY_MULTIPLE &&
        state.selectedGuestRows &&
        state.selectedGuestRows.length > 0
      );
    },

    getSelectedGuestIds: state => state.selectedGuestRows.map(g => g._id),

    // GuestEvents

    getCurrentGuestEvent: state => state.currentGuestEvent,

    showGuestEventDialog: state => state.currentGuestEvent != null,

    getGuestEvents: state => state.deletingGuestEvents,
    getGuestMarkers: state => state.deletingGuestMarkers
  }
});
