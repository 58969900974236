import axios from "axios";

export const AttendeesService = {
  async getAll(query, page, itemsPerPage, sortBy, desc, onlyReported) {
    const { data } = await axios.get("/api/attendee", {
      params: {
        query: query,
        offset: (page - 1) * itemsPerPage,
        limit: itemsPerPage,
        order: sortBy,
        desc,
        onlyReported: !!onlyReported
      }
    });
    return data;
  },

  async delete(id) {
    return await axios.delete("/api/attendee/" + id);
  },

  async toggle(id, enable) {
    if (enable) {
      return await axios.put("/api/attendee/" + id + "/enable");
    }
    return await axios.put("/api/attendee/" + id + "/disable");
  },

  async update(attendee) {
    return await axios.post("/api/attendee", attendee);
  },

  async resetPwd(attendeeId) {
    return await axios.post(`/api/attendee/resetpassword/${attendeeId}`);
  },

  async getAllByFirebaseUserIds(firebaseUserIds) {
    const { data } = await axios.post(
      "/api/attendee/listByFirebaseUserId",
      firebaseUserIds
    );
    return data;
  }
};
