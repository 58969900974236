import { type ProgEvent } from "@/shared/types/progEvent.type";
import { type Program } from "@/shared/types/program.type";

export class ProgramsState {
  programs: { [key: string]: Program } = {};
  deletingProgramId: string | null = null;
  editingProgramId: string | null = null;
  loading = false;
  showModal = false;
  events: ProgEvent[] = [];
}

export const state = new ProgramsState();
