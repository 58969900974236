import { type Font } from "@/shared/service/fontsService";
import {
  type MapPOI,
  type PreviewApplicationConfig,
  type PreviewGrabData
} from "@/shared/types/applicationData.type";
import { type Program } from "@/shared/types/program.type";

import { type TranslatableText } from "../../../types/TranslatableText.type";
import { type GuestType } from "../guestTypes/GuestType.model";

type StaticPageView = "GUESTS" | "TIMELINE" | "EVENTS" | "GUESTS_COMPACT";
export const LANGS = ["fr", "en"] as const;
export type Lang = (typeof LANGS)[number];

export type StaticPreviewGrabData = Pick<
  PreviewGrabData,
  "events" | "genres" | "groups" | "guestTypes" | "programs" | "scenes"
> & {
  poiList: MapPOI[];
  staticConfig: Pick<PreviewApplicationConfig, "timezone"> & {
    alwaysShowEventHour: boolean;
    eventsHideEndDate: boolean;
    orderEventsByEndDate: boolean;
    hideMultilangSelector: boolean;
  };
  pages: StaticPreviewPage[];
};

export type StaticPreviewPage = {
  blockName: string;
};

export type StaticPreviewDataSets = Record<
  Lang,
  StaticPreviewGrabData | undefined
>;

export type StaticStyles = {
  colorTitle: string;
  colorBackground: string;
  colorBlockBackground: string;
  colorBlockText: string;
  colorAction: string;
  colorBlockAction: string;
};

export type FontChangeSet = {
  fontText: File | undefined;
  fontTitle: File | undefined;
};

// type stored in back
export type StaticConfig = StaticStyles & {
  _id?: string;
  label?: string;
  fontTitle: Font | undefined;
  fontText: Font | undefined;
  smartBannerEnabled: boolean;
  pages?: StaticPage[];
};

export const DEFAULT_HOME_PAGE_CARDS_PER_ROW = 4;

export type StaticPage = {
  icon: string;
  default: boolean;
  visible: boolean;
  view: StaticPageView;
  blockName: TranslatableText;
  guestTypes?: GuestType["_id"][];
  programs?: Program["_id"][];
  cardsPerRow?: number;
};

export type TranslationUnit = { [key: string]: string | TranslationUnit };

export type SpecificFestivalSettings = {
  i18n?: Record<Lang, TranslationUnit>;
  locales?: readonly string[];
};

// attributes passed to the <chapito-static> web component
export type StaticWebComponentAttributes = {
  pages?: StaticPage[];
  "specific-settings"?: SpecificFestivalSettings;
  styles?: StaticStyles;
  data?: StaticPreviewDataSets;
};
