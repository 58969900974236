<template>
  <v-navigation-drawer
    v-model="drawer"
    color="bleu2"
    dark
    app
    class="chapito-menu font-size-menu"
  >
    <PublicationModal v-if="isPublishing" />
    <div class="logo">
      <v-img
        class="img-cursor-click"
        src="../assets/logo-large.png"
        @click="goToDashboard"
      />
    </div>
    <v-divider class="menuDividers" />
    <div class="div-user">
      <div class="div-image">
        <v-img
          v-if="!!icon"
          class="image"
          width="80"
          :src="`/api/thumb/${icon}_square`"
        />
      </div>
      <v-row class="div-user-name">
        {{ username }}
        <v-menu
          transition="slide-y-transition"
          offset-y
          bottom
          nudge-left="50px"
        >
          <template #activator="{ on }">
            <v-icon v-on="on">more_vert</v-icon>
          </template>
          <v-list>
            <v-list-item link @click="logout()">
              <v-list-item-title>
                {{ $t("APP_LAYOUT.LOGOUT") }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-row>
    </div>
    <v-divider class="menuDividers" />
    <template v-if="isDirty">
      <v-divider class="menuDividers" />
      <div class="publish-section-wrapper">
        <v-btn x-small color="success" rounded @click="publish()">
          <v-icon left> cloud_upload </v-icon>
          {{ $t("APP_LAYOUT.PUBLISH") }}
        </v-btn>
      </div>
    </template>
    <v-list-item-group
      v-model="selected"
      color="white"
      class="chapito-menu-list"
    >
      <template v-for="field in getFilteredFields(menuFields)">
        <v-list-group
          v-if="
            !!field.subFields && getFilteredFields(field.subFields).length > 1
          "
          :key="field.text"
          class="list-fields"
          color="white"
        >
          <template #activator>
            <v-list-item-action>
              <v-icon>{{ field.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content> {{ field.text }} </v-list-item-content>
          </template>
          <v-list-item
            v-for="subfield in getFilteredFields(field.subFields)"
            :key="subfield.text"
            link
            :to="subfield.link"
          >
            <v-list-item-action />
            <v-list-item-content> {{ subfield.text }} </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-list-item
          v-else
          :key="field.text"
          class="list-fields"
          link
          :to="
            !!field.subFields
              ? getFilteredFields(field.subFields)[0].link
              : field.link
          "
        >
          <v-list-item-action>
            <v-icon>{{ field.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            {{
              !!field.subfields
                ? getFilteredFields(field.subFields)[0].text
                : field.text
            }}
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list-item-group>
    <UpToDate v-if="isAdmin" />
  </v-navigation-drawer>
</template>

<script lang="ts">
import { mapState } from "vuex";

import { i18n } from "@/i18n/i18n";
import UpToDate from "@/shared/components/UpToDate";

import PublicationModal from "../pages/publications/PublicationModal.vue";
import { mapGettersAppConfig } from "../shared/store/app-config.store";
import {
  sessionDispatch,
  sessionMapGetters
} from "../shared/store/session.store";

export default {
  components: {
    PublicationModal,
    UpToDate
  },
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },

  data() {
    return {
      selected: ""
    };
  },

  computed: {
    menuFields: {
      get() {
        return [
          {
            text: i18n.t("APP_LAYOUT.MENU.HOME"),
            icon: "dashboard",
            link: "/",
            showIf: true
          },
          {
            text: i18n.t("APP_LAYOUT.MENU.PROGRAMMATION"),
            icon: "calendar_today",
            link: "/programmation",
            showIf: true
          },
          {
            text: i18n.t("APP_LAYOUT.MENU.DATA"),
            icon: "group",
            showIf: true,
            subFields: [
              {
                text: i18n.t("APP_LAYOUT.MENU.PROGRAM"),
                link: "/programs",
                showIf: true
              },
              {
                text: i18n.t("APP_LAYOUT.MENU.SCENES"),
                link: "/scenes",
                showIf: true
              },
              {
                text: i18n.t("APP_LAYOUT.MENU.GUEST_TYPE"),
                link: "/guest-types",
                showIf: true
              },
              {
                text: i18n.t("APP_LAYOUT.MENU.GUESTS"),
                link: "/guests",
                showIf: true
              },
              {
                text: i18n.t("APP_LAYOUT.MENU.GENRES"),
                link: "/genres",
                showIf: true
              },
              {
                text: i18n.t("APP_LAYOUT.MENU.ATTENDEES"),
                link: "/attendees",
                showIf:
                  this.featureEnabled("showAttendees") ||
                  this.featureEnabled("loginWallEnabled")
              },
              {
                text: i18n.t("APP_LAYOUT.MENU.IMPORT"),
                link: "/import",
                showIf:
                  this.featureEnabled("allowGcImport") ||
                  this.featureEnabled("allowHeedsImport")
              }
            ]
          },
          {
            text: i18n.t("APP_LAYOUT.MENU.INFORMATIONS"),
            icon: "info",
            showIf: true,
            subFields: [
              {
                text: i18n.t("APP_LAYOUT.MENU.SPONSORS"),
                link: "/sponsors",
                showIf: true
              },
              {
                text: i18n.t("APP_LAYOUT.MENU.INFO"),
                link: "/infosPratiques",
                showIf: true
              },
              {
                text: i18n.t("APP_LAYOUT.MENU.GALLERY"),
                link: "/gallery",
                showIf: this.featureEnabled("showDrawerEntryForGallery")
              },
              {
                text: i18n.t("APP_LAYOUT.MENU.MAP"),
                link: "/maps",
                showIf: this.featureEnabled("allowCustomMap")
              }
            ]
          },
          {
            text: i18n.t("APP_LAYOUT.MENU.SETTINGS"),
            icon: "apps",
            showIf: true,
            subFields: [
              {
                text: i18n.t("APP_LAYOUT.MENU.GENERAL"),
                link: "/festival",
                showIf: true
              },
              {
                text: i18n.t("APP_LAYOUT.MENU.STORES"),
                link: "/store",
                showIf: true
              },
              {
                text: i18n.t("APP_LAYOUT.MENU.APPLICATION_CFG"),
                link: "/applicationCfg",
                showIf: true
              },
              {
                text: i18n.t("APP_LAYOUT.MENU.ADS"),
                link: "/ads",
                showIf:
                  this.featureEnabled("displaySplashAdvertisement") ||
                  this.featureEnabled("showDrawerBottomAdvertisement")
              },
              {
                text: i18n.t("APP_LAYOUT.MENU.STATIC_CFG"),
                link: "/staticCfgs",
                showIf: this.featureEnabled("enableStaticWebsite")
              },
              {
                text: i18n.t("APP_LAYOUT.MENU.WHITELIST"),
                link: "/attendee-white-list",
                showIf:
                  this.featureEnabled("showAttendees") ||
                  this.featureEnabled("loginWallEnabled")
              },
              {
                text: i18n.t("APP_LAYOUT.MENU.MENU"),
                link: "/menuEntries",
                showIf: true
              },
              {
                text: i18n.t("APP_LAYOUT.MENU.PRIVATE_SECTIONS"),
                link: "/privateSections",
                showIf: this.featureEnabled("privateSectionsEnabled")
              }
            ]
          },
          {
            text: i18n.t("APP_LAYOUT.MENU.NOTIFICATIONS"),
            icon: "notifications",
            showIf: true,
            subFields: [
              {
                text: i18n.t("APP_LAYOUT.MENU.TOPICS"),
                link: "/notifications/topics",
                showIf: this.featureEnabled("notificationTopicsEnabled")
              },
              {
                text: i18n.t("APP_LAYOUT.MENU.LIST_NOTIF"),
                link: "/notifications",
                showIf: true
              }
            ]
          },
          {
            text: i18n.t("APP_LAYOUT.MENU.DASHBOARD_MATOMO"),
            icon: "trending_up",
            link: "/dashboardAnalytics",
            showIf:
              this.featureEnabled("enableMatomo") &&
              this.featureEnabled("enableAnalyticsPage")
          },
          {
            text: i18n.t("APP_LAYOUT.MENU.ADMIN"),
            icon: "settings",
            showIf: this.isAdmin,
            subFields: [
              {
                text: i18n.t("APP_LAYOUT.MENU.USERS"),
                link: "/users",
                showIf: true
              },
              {
                text: i18n.t("APP_LAYOUT.MENU.EXPORT"),
                link: "/publications",
                showIf: true
              },
              {
                text: i18n.t("APP_LAYOUT.MENU.CONFIG_DEV"),
                link: "/applicationConfig",
                showIf: true
              },
              {
                text: i18n.t("APP_LAYOUT.MENU.FILES"),
                link: "/orphaned",
                showIf: true
              },
              {
                text: i18n.t("APP_LAYOUT.MENU.RESET"),
                link: "/reset",
                showIf: true
              },
              {
                text: "Api Docs",
                link: "/swagger",
                showIf: true
              }
            ]
          }
        ];
      }
    },

    ...mapState({
      username: state => state.session.principal.name,
      icon: state => state.storesInformations.icon,
      appBarText: state => state.appBar.text
    }),
    ...sessionMapGetters(["isAdmin"]),
    ...mapGettersAppConfig(["isDirty"]),
    ...mapState("publications", {
      isPublishing: "showPublicationModal"
    }),
    drawer: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    }
  },

  methods: {
    featureEnabled(feature) {
      return this.$store.getters.featureIsEnable(feature);
    },

    getFilteredFields(fields) {
      return fields.filter(field => field.showIf);
    },

    async logout() {
      await sessionDispatch("LOGOUT");
    },

    async publish() {
      this.$store.commit("publications/showPublicationModal");
    },

    goToDashboard() {
      this.$router.push("/");
    }
  }
};
</script>

<style lang="scss" scoped>
.font-size-menu {
  font-size: 14px;
}

.div-user-name {
  color: white;
  margin: 20px 0 20px 12px;
  justify-content: center;
}

.logo {
  position: relative;
  padding: 15px;
}

.menuDividers {
  margin: 0 20px 0 20px;
}

.menuActivator {
  text-align: center;
  cursor: pointer;
}

.popUpItems {
  text-align: center;
}

.chapito-menu-list {
  flex: 1;
  overflow-y: auto;
}

.list-fields {
  margin: 15px 0 15px 0;
}

.img-cursor-click {
  cursor: pointer;
}

.div-image {
  width: 80px;
  margin: 20px auto 0;
}

.image {
  border-radius: 20%;
  box-shadow:
    0 10px 30px -12px rgba(0, 0, 0, 0.42),
    0 4px 25px 0px rgba(0, 0, 0, 0.12),
    0 8px 10px -5px rgba(0, 0, 0, 0.2);
}

.publish-section-wrapper {
  padding: 1rem;
  display: grid;
}
</style>
<style lang="scss">
.chapito-menu > div {
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
}
</style>
