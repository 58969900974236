import {
  type ExportUrl,
  type ImportStatus
} from "@/pages/import/store/import.type";

export class StateImport {
  loading = false;
  disabled = false;
  lastImportStatus: ImportStatus = {} as ImportStatus;
  syncUrls: ExportUrl[] = [];
}
export const state = new StateImport();
