import axios from "axios";

export const ResetAppService = {
  async resetApp(val) {
    await axios.post("/api/admin/reset", { fullReset: val });
  },
  async resetMenu() {
    await axios.post("/api/reinitMenuEntry");
  }
};
