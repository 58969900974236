import { ErrorService } from "@/shared/service/errorService";

import { i18n } from "../../../i18n/i18n";
import { SnackbarService } from "../../../shared/snackbar/snackbar.service";
import { OrphanedService } from "../orphaned.service";

export const actions = {
  async list({ commit }) {
    try {
      commit("LOAD");
      const files = await OrphanedService.getAll();
      commit("SET_FILES", files);
    } catch (e) {
      ErrorService.handleError(e);
    } finally {
      commit("UNLOAD");
    }
  },

  async delete({ commit, dispatch, state }) {
    try {
      commit("LOAD");

      if (state.deleting === "ALL") {
        await OrphanedService.deleteAll();
        SnackbarService.info(i18n.t("ORPHANED.ALL_DELETED"));
      } else {
        await OrphanedService.delete(state.deleting);
        SnackbarService.info(i18n.t("ORPHANED.DELETED"));
      }
      await dispatch("list");
    } catch (e) {
      ErrorService.handleError(e);
    } finally {
      commit("CANCEL_DELETING");
      commit("UNLOAD");
    }
  }
};
