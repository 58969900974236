import { type RouteConfig } from "vue-router";

import store from "../../store";

export const MapRouter: RouteConfig[] = [
  {
    path: "/maps",
    component: () => import("./MapsList.vue"),
    async beforeEnter(from, to, next) {
      await store.dispatch("maps/list");
      next();
    },
    children: []
  },
  {
    path: "/maps/:id",
    component: () => import("./Map.vue"),
    props: route => ({
      mapOid: route.params.id
    }),
    async beforeEnter(to, from, next) {
      store.commit("map/LOAD");
      await Promise.all([
        store.dispatch("map/getMap", { id: to.params.id }),
        store.dispatch("map/getMapEntityChoices")
      ]);
      next();
    }
  }
];
