import { PAGE_MODE } from "../../../shared/constants";

export const mutations = {
  LOAD(state) {
    state.loading = true;
  },
  SET_ATTENDEES(state, { attendees, total }) {
    state.attendees = attendees;
    state.pagination.totalItems = total;
    state.loading = false;
  },
  SET_PAGINATION(state, pagination) {
    state.pagination = pagination;
  },
  SET_FILTER(state, filter) {
    state.filter = filter;
  },
  SET_EDITING(state, payload) {
    state.mode = PAGE_MODE.EDIT;
    state.currentAttendee = { ...payload };
  },
  SET_DELETING(state, payload) {
    state.mode = PAGE_MODE.DELETE;
    state.currentAttendee = { ...payload };
  },
  SET_VIEW(state) {
    state.mode = PAGE_MODE.VIEW;
    state.loading = false;
    state.currentAttendee = null;
  },
  UPDATE_ATTENDEE(state, payload) {
    state.currentAttendee = { ...state.currentAttendee, ...payload };
  },
  SET_DISABLE(state, payload) {
    state.mode = PAGE_MODE.DISABLE;
    state.currentAttendee = { ...payload };
  },
  SET_ENABLE(state, payload) {
    state.mode = PAGE_MODE.ENABLE;
    state.currentAttendee = { ...payload };
  }
};
