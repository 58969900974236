import { cloneDeep } from "lodash";
import { type Module } from "vuex";

import { type RootState } from "@/store";

import { i18n } from "../../../i18n/i18n";
import { PAGE_MODE } from "../../../shared/constants";
import { ErrorService } from "../../../shared/service/errorService";
import { SnackbarService } from "../../../shared/snackbar/snackbar.service";
import { fromEntries, isNotNullish } from "../../../utils";
import { MapService } from "../map.service";
import { DEFAULT_MAP, type MapObject } from "../map.type";

class MapsState {
  mode = PAGE_MODE.VIEW;
  maps: { [id: string]: MapObject } = {};
  current: MapObject | null = null;
  loading = false;
}
const state = new MapsState();

export const maps: Module<MapsState, RootState> = {
  namespaced: true,
  state,
  mutations: {
    SET_ADDING(state) {
      state.mode = PAGE_MODE.ADD;
      state.current = cloneDeep(DEFAULT_MAP);
    },
    SET_EDITING(state, payload: MapObject) {
      state.mode = PAGE_MODE.EDIT;
      state.current = cloneDeep(payload);
    },
    LOAD(state) {
      state.loading = true;
    },
    SET_DELETING(state, payload: MapObject) {
      state.mode = PAGE_MODE.DELETE;
      state.current = cloneDeep(payload);
    },
    SET_VIEW(state) {
      state.mode = PAGE_MODE.VIEW;
      state.current = null;
      state.loading = false;
    },
    SET_MAPS(state, payload: MapObject[]) {
      state.maps = fromEntries(
        payload
          .map(item => (item._id ? ([item._id, item] as const) : null))
          .filter(isNotNullish)
      );
      state.loading = false;
    }
  },
  actions: {
    async list({ commit }) {
      try {
        commit("LOAD");
        commit("SET_MAPS", await MapService.getMapList());
      } catch (e) {
        ErrorService.handleError(e);
      }
    },
    async editMap({ commit, dispatch }, payload: MapObject) {
      try {
        commit("LOAD");
        await MapService.saveMap(payload);
        SnackbarService.info(
          i18n
            .t("MAPS.SNACK_BAR.EDIT_SUCCESS", {
              name: payload.names["fr"]
            })
            .toString()
        );
      } catch (e) {
        ErrorService.handleError(e);
      } finally {
        await dispatch("list");
        commit("SET_VIEW");
      }
    },
    async addMap({ commit, dispatch }, payload: MapObject) {
      try {
        commit("LOAD");
        await MapService.saveMap(payload);
        SnackbarService.info(
          i18n
            .t("MAPS.SNACK_BAR.ADD_SUCCESS", {
              name: payload.names["fr"]
            })
            .toString()
        );
      } catch (e) {
        ErrorService.handleError(e);
      } finally {
        await dispatch("list");
        commit("SET_VIEW");
      }
    },
    async delete({ commit, dispatch, state }) {
      try {
        commit("LOAD");
        if (!state.current || !state.current._id) return;
        await MapService.deleteMap(state.current._id!);
        SnackbarService.info(
          i18n.t("MAPS.SNACK_BAR.DELETE_SUCCESS").toString()
        );
      } catch (e) {
        ErrorService.handleError(e);
      } finally {
        await dispatch("list");
        commit("SET_VIEW");
      }
    },
    async toggleVisibility({ commit, dispatch }, payload: MapObject) {
      commit("LOAD");
      try {
        payload.show = !payload.show;
        await MapService.saveMap(payload);
        SnackbarService.info(
          i18n
            .t(payload.show ? "MAPS.SNACK_BAR.SHOW" : "MAPS.SNACK_BAR.HIDE", {
              string: payload.names["fr"]
            })
            .toString()
        );
      } catch (e) {
        ErrorService.handleError(e);
      } finally {
        await dispatch("list");
        commit("SET_VIEW");
      }
    }
  },
  getters: {
    getMaps(state) {
      return Object.values(state.maps);
    }
  }
};
