import { type MenuElement } from "@/pages/menuEntries/MenuEntry.type";

import { type GEDFile } from "./../../../shared/types/files.type";
import { type GalleryItem } from "./../gallery.type";
import { type GalleryState } from "./gallery.state";

export const mutations = {
  SET_GALLERY(state: GalleryState, galleryItems: GalleryItem[]) {
    state.gallery = galleryItems.reduce(
      (acc: { [key: string]: GalleryItem }, item: GalleryItem) => {
        acc[item._id] = item;
        return acc;
      },
      {}
    );
  },

  LOAD(state: GalleryState) {
    state.loading = true;
  },

  UNLOAD(state: GalleryState) {
    state.loading = false;
  },

  SET_ADDING_ITEM(state: GalleryState) {
    state.editingGalleryItemId = "";
    state.pdfFilename = "";
    state.showAddingModal = true;
  },

  SET_EDITING_ITEM(
    state: GalleryState,
    payload: { fileInfo: GEDFile; id: string }
  ) {
    state.image = state.gallery[payload.id].image;
    state.thumbnailImage = state.gallery[payload.id].thumbnailImage;
    state.editingGalleryItemId = payload.id;
    state.pdfFilename = payload.fileInfo.fileName;
    state.showAddingModal = true;
  },

  SET_THUMBNAIL_CROP(state: GalleryState, crop: any) {
    state.thumbnailImageCrop = crop;
  },

  SET_THUMBNAIL(state: GalleryState, img: string) {
    state.thumbnailImage = img;
  },

  SET_DELETING(state: GalleryState, id: string) {
    state.deletingGalleryItemId = id;
  },

  SET_NOT_DELETING(state: GalleryState) {
    state.deletingGalleryItemId = "";
  },

  SET_IMAGE(state: GalleryState, image: string) {
    state.image = image;
  },

  RESET_ADDING(state: GalleryState) {
    state.image = "";
    state.thumbnailImage = "";
    state.thumbnailImageCrop = "";
    state.editingGalleryItemId = "";
    state.showAddingModal = false;
    state.pdfFilename = "";
  },

  SET_NEW_ORDER(state: GalleryState, ids: string[]) {
    let weightCounter: number = 0;
    ids.forEach((id: string) => {
      state.gallery[id].weight = weightCounter;
      weightCounter++;
    });
  },

  SET_PDF_FILENAME(state: GalleryState, filename: string) {
    state.pdfFilename = filename;
  },

  SET_GALLERY_MENU_ENTRY(state: GalleryState, galleryEntry: MenuElement) {
    state.galleryEntry = galleryEntry;
  }
};
