<template>
  <div class="header-div">
    <v-card
      color="#4CB9DC"
      class="my-card-header"
      data-background-color="green"
    >
      <h4 class="title"><slot /></h4>
    </v-card>
  </div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({});
</script>

<style lang="scss" scoped>
.my-card-header {
  color: white;
  padding: 15px 20px 15px 20px;
  z-index: 3;
  width: auto;
  top: -20px;
  left: 15px;
  position: absolute;
  align-content: center;
  justify-content: center;
}

.title {
  font-family: "Montserrat", "Helvetica Neue", Arial, sans-serif !important;
  font-size: 1.1rem !important;
}

.divButtons {
  position: absolute;
  right: 30px;
  margin-top: -30px;
}

.header-div {
  height: 50px;
}
</style>
