export const snackbar = {
  namespaced: true,
  state: {
    visible: false,
    message: "",
    color: ""
  },
  mutations: {
    SET(state: any, { message, color }: any) {
      state.message = message;
      state.color = color;
      state.visible = true;
    },
    HIDE(state: any) {
      state.visible = false;
    }
  },
  actions: {
    SHOW({ commit }: any, { message, color }: any) {
      commit("SET", { message, color });
      setTimeout(() => commit("HIDE"), 3000);
    }
  }
};
