export const state = {
  loading: false,
  sponsorsCategories: {},
  deletingSponsorCategoryId: null,
  editingSponsorCategoryId: null,

  //comboBox
  selectedCategory: null,
  categoriesSearch: "",
  addedCategories: {},
  deletedCategoriesIds: []
};
