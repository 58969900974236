import { type ProgEvent } from "@/shared/types/progEvent.type";

export type MusicGroupEventFormInput = Pick<
  ProgEvent,
  | "program"
  | "enableRating"
  | "enableAttending"
  | "programEventNotifSendTime"
  | "scene"
  | "eventTicketLink"
  | "startTime"
  | "endTime"
  | "date"
  | "day"
  | "hideEndDate"
  | "ticketOpenInWebview"
  | "price"
  | "soldOut"
  | "isVisible"
  | "notifPlanned"
>;

export function musicGroupEventFormInputsToProgEvents(
  inputs?: MusicGroupEventFormInput[]
): ProgEvent[] {
  return inputs ? inputs.map(i => musicGroupEventFormInputToProgEvent(i)) : [];
}
export function musicGroupEventFormInputToProgEvent(
  input: MusicGroupEventFormInput
): ProgEvent {
  return {
    ...input,
    _id: "",
    copyright: "",
    day: "",
    descriptions: undefined,
    eventAttendeesList: [],
    fakeTitle: "",
    genres: [],
    image: "",
    isVisible: false,
    meanRate: 0,
    musicGroups: [],
    notifPlanned: false,
    showEndDate: undefined,
    showStartDate: undefined,
    titles: undefined,
    hideEndDate: false,
    soldOut: false
  };
}
