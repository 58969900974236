import Vue from "vue";

import {
  type Lang,
  type TranslatableText
} from "../../../types/TranslatableText.type";

Vue.filter("translatableText", translatableText);

export function translatableText(
  source?: TranslatableText | null,
  lang: Lang = "fr"
) {
  return source?.[lang] ?? "";
}
