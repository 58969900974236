import { isEqual } from "lodash";
import { type GetterTree } from "vuex";

import { IdsService } from "../../../shared/service/utils.service";
import { type RootState } from "../../../store";
import {
  DEFAULT_MAP,
  type MapCopy,
  type MapEntityChoicesByType,
  type MapObject,
  type MarkerIcon,
  type ZOI
} from "./../map.type";
import { type StateMap } from "./map.state";

export const getters: GetterTree<StateMap, RootState> = {
  getZois: state => {
    return Object.values(state.zoiList);
  },

  getPois: state => {
    return Object.values(state.poiList);
  },

  getPoiById: state => {
    return (id: string) => {
      return state.poiList[id];
    };
  },

  getZoiById: state => {
    return (id: string): ZOI | undefined => {
      return state.zoiList[id];
    };
  },

  getMarkers: (state): MarkerIcon[] => {
    return [
      {
        label: "Default",
        file: "DEFAULT",
        oid: null
      },
      ...Object.values(state.markerIcons)
    ];
  },

  getEditingMarker:
    (state): (() => MarkerIcon) =>
    () => {
      return state.editingMarkerId
        ? state.markerIcons[state.editingMarkerId]
        : {
            label: "",
            file: "",
            oid: IdsService.generateId()
          };
    },

  getPreparedMap: (state): MapObject => {
    const preparedMap = state.preparedMap || {
      ...DEFAULT_MAP
    };
    preparedMap.poiList = Object.values(state.poiList);
    preparedMap.zoiList = Object.values(state.zoiList);
    preparedMap.mapOverlay = state.mapOverlay;
    preparedMap.corners = state.corners;
    preparedMap.imageOpacity = state.imageOpacity;

    return preparedMap;
  },

  getMapEdited: state => {
    const map: MapCopy = {
      mapOverlay: state.mapOverlay,
      corners: state.corners,
      zoiList: state.zoiList,
      poiList: state.poiList,
      markerIcons: state.markerIcons,
      imageOpacity: state.imageOpacity
    };
    const isSame = isEqual(map, state.mapCopy);
    return !isSame;
  },

  getScenes: (_state, _getters, _rootState, rootGetters) => {
    return [...rootGetters["scenes/getScenes"]];
  },

  getEntityIdChoicesByType: (state): MapEntityChoicesByType => {
    return state.entityIdChoicesByType;
  }
};
