<template>
  <v-app id="inspire" v-resize="onResize">
    <template v-if="isConnecting">
      <v-progress-circular
        class="spinner"
        :size="140"
        :width="7"
        color="#3ABBDB"
        indeterminate
      />
    </template>
    <template v-else-if="!isConnecting">
      <template v-if="!isConnectionRequired">
        <v-main class="content-backgroud">
          <router-view />
        </v-main>
      </template>
      <template v-else-if="isConnected">
        <ChapiMenu v-if="true" v-model="drawer" />
        <v-main class="content-backgroud">
          <div class="menu-button-wrapper">
            <div class="menu-button" @click="setDrawer">
              <v-icon class="menu-button-icon" dark>{{
                drawer ? "keyboard_arrow_left" : "keyboard_arrow_right"
              }}</v-icon>
            </div>
          </div>
          <Snackbar />
          <WarningBanner />
          <router-view />
        </v-main>
      </template>

      <template v-else-if="!isConnected">
        <Snackbar />
        <Login />
      </template>
    </template>
  </v-app>
</template>

<script lang="ts">
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import duration from "dayjs/plugin/duration";
import localizedFormat from "dayjs/plugin/localizedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import timezone from "dayjs/plugin/timezone"; // dependent on utc plugin
import utc from "dayjs/plugin/utc";
import Vue from "vue";

import ChapiMenu from "./components/Menu.vue";
import Login from "./pages/login/Login.vue";
import WarningBanner from "./shared/components/WarningBanner.vue";
import Snackbar from "./shared/snackbar/SnackBar.vue";
import { mapGettersAppConfig } from "./shared/store/app-config.store";
import {
  sessionDispatch,
  sessionMapGetters,
  useSessionGetter
} from "./shared/store/session.store";

dayjs.extend(utc);
dayjs.extend(customParseFormat);
dayjs.extend(timezone);
dayjs.extend(relativeTime);
dayjs.extend(duration);
dayjs.extend(localizedFormat);
dayjs.locale("fr");

export default Vue.extend({
  name: "App",
  components: {
    ChapiMenu,
    WarningBanner,
    Login,
    Snackbar
  },
  data: () => ({
    drawer: true
  }),

  async beforeMount() {
    await sessionDispatch("LOAD_SESSION");
    if (useSessionGetter("isAdmin").value) {
      await sessionDispatch("CHECK_VERSION");
    }
  },

  async mounted() {
    dayjs.tz.setDefault(this.getTimezone);
    Vue.prototype.$dayjs = dayjs;
    this.$store.watch(
      (state: any) => state.appConfig.timezone,
      (newValue: string) => {
        dayjs.tz.setDefault(newValue);
        Vue.prototype.$dayjs = dayjs;
      }
    );
  },

  computed: {
    ...mapGettersAppConfig(["getTimezone"]),
    ...sessionMapGetters([
      "isConnecting",
      "isConnected",
      "isConnectionRequired"
    ])
  },

  methods: {
    setDrawer() {
      this.drawer = !this.drawer;
    },

    onResize() {
      if (window.innerWidth < 769) {
        this.$store.commit("SET_IS_MOBILE");
      } else if (window.innerWidth < 1264) {
        this.$store.commit("SET_IS_SMALL_LAPTOP");
      } else {
        this.$store.commit("SET_IS_DEFAULT");
      }
    }
  }
});
</script>
<style lang="scss" scoped>
.v-application {
  font-family: "Montserrat", "Helvetica Neue", Arial, sans-serif;
}

.content-backgroud {
  background-color: #eeeeee;
}

.menu-button {
  position: absolute;
  overflow: hidden;
  border-radius: 0 0 100% 0;
  height: 25px;
  background-color: #0f708a;
  width: 25px;
  cursor: pointer;
  top: 0;
}

.menu-button-wrapper {
  position: sticky;
  height: 0px;
  width: 0px;
  left: 0px;
  top: 0px;
  z-index: 6;
}

.menu-button-icon {
  margin: -6px 0 0 -3px;
  cursor: pointer;
}

.spinner {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  text-align: center;
}
</style>
