import { type PreviewGrabData } from "@/shared/types/applicationData.type";

import { type Lang, type StaticConfig } from "../staticCfg.types";

export class StaticConfigState {
  config: StaticConfig | undefined;
  dataSets: Record<Lang, PreviewGrabData | undefined> = {
    en: undefined,
    fr: undefined
  };
}

export const state = new StaticConfigState();
