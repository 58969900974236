import { type GuestType } from "@/pages/guestTypes/GuestType.model";
import { PAGE_MODE, type PageMode } from "@/shared/constants";

export class GuestTypeState {
  mode: PageMode = PAGE_MODE.VIEW;
  guestTypes: { [key: string]: GuestType } = {};
  currentGuestType: GuestType | null = null;
  loading = false;
  guestNames: string[] = [];
}

export const state = new GuestTypeState();
