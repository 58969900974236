import { Marker, Polygon } from "leaflet";

import { type AugmentedRequired } from "@/utils";

import {
  makeTranslatableText,
  type TranslatableText
} from "../../../types/TranslatableText.type";
import { type StateMap } from "./store/map.state";

export type Coords = {
  lat: number;
  lng: number;
};

export function makeCoords(lat: number, lng: number): Coords {
  return {
    lat: parseFloat(lat.toString()),
    lng: parseFloat(lng.toString())
  };
}

export type MapCopy = Pick<
  StateMap,
  | "poiList"
  | "zoiList"
  | "markerIcons"
  | "mapOverlay"
  | "corners"
  | "imageOpacity"
>;

export const MAP_ENTITY_TYPES = ["NONE", "SCENE", "GUEST"] as const;
export type MapEntityType = (typeof MAP_ENTITY_TYPES)[number];

export type POI = {
  coords: Coords;
  labels: TranslatableText;
  pic: string;
  markerIconId: string | null;
  entityType: MapEntityType;
  entityId: string;
  oid: string;
};

export function makePOI(arg: AugmentedRequired<Partial<POI>, "coords">): POI {
  return {
    pic: "DEFAULT",
    markerIconId: null,
    entityType: "NONE",
    entityId: "",
    oid: "",
    ...arg,
    labels: {
      fr: "sans nom",
      en: "",
      ...(arg.labels || {})
    }
  };
}

export type MarkerIcon =
  | {
      label: string;
      file: string;
      oid: string;
    }
  | {
      label: "Default";
      file: "DEFAULT";
      oid: null;
    };

export type ZOI = {
  zoneCorners: Coords[];
  color: string;
  labels: TranslatableText;
  pic: string;
  entityType: MapEntityType;
  entityId: string;
  oid: string;
};

export function makeZOI(arg: Partial<ZOI>): ZOI {
  return {
    zoneCorners: [],
    color: "#4CB9DC",
    labels: makeTranslatableText("sans nom"),
    pic: "",
    entityType: "NONE",
    entityId: "",
    oid: "",
    ...arg
  };
}

export type MapObject = {
  names: TranslatableText;
  show: boolean;
  mapOverlay?: string | null;
  hideLabels: boolean;
  corners: Coords[];
  imageOpacity: number;
  poiList: POI[];
  zoiList: ZOI[];
  maxZoom?: number;
  _id?: string;
};

export const DEFAULT_MAP: MapObject = {
  names: { fr: "", en: "" },
  show: false,
  hideLabels: false,
  imageOpacity: 100,
  poiList: [],
  zoiList: [],
  corners: []
};

export class MarkerWithId extends Marker {
  id: string = "";
}

export class PolygonWithId extends Polygon {
  id: string = "";
}

export class ResizeMarker extends Marker {
  //0 = NW, 1 = NE, 2 = SE, 3 = SW
  bound: number = -1;
}

export type MapEntityChoicesByType = Record<
  MapEntityType,
  { _id: string; names: TranslatableText }[] | undefined
>;

export type GeoJsonImportSummary = {
  pois: number;
  zois: number;
  errors: string[];
};
