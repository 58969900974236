<template>
  <modal
    max-width="500px"
    :title="$t('GENERAL.DELETION')"
    btn-ok-color="error"
    :btn-ok-label="$t('GENERAL.DELETE')"
    :btn-ok-disabled="btnOkDisabled"
    :md="12"
    @okAction="$emit('deleteAction')"
    @cancelAction="$emit('cancelAction')"
  >
    <slot name="content" />
    <div class="text-message">{{ message }}</div>
  </modal>
</template>
<script>
export default {
  props: {
    message: {
      type: String,
      required: false
    },
    btnOkDisabled: {
      type: Boolean,
      required: false,
      default: false
    }
  }
};
</script>
<style scoped lang="scss">
.text-message {
  margin: 5px;
  text-align: center;
}
</style>
