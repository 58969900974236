export const AttendeesWhiteListRouter = [
  {
    path: "/attendee-white-list-create",
    component: () => import("./AttendeeWhiteListCreate.vue")
  },
  {
    path: "/attendee-white-list",
    component: () => import("./AttendeeWhiteListList.vue")
  }
];
