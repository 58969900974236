import axios from "axios";

import { type GalleryItem } from "./gallery.type";

function galleryItemToDbFormat(item: GalleryItem) {
  const { _id, ...rest } = item;
  return rest;
}

export const GalleryService = {
  async getAll(): Promise<GalleryItem[]> {
    const { data }: any = await axios.get("/api/galleryItems");
    return data;
  },

  async postGallery(gallery: GalleryItem) {
    await axios.post("/api/galleryItems", galleryItemToDbFormat(gallery));
  },

  async delete(id: string) {
    await axios.delete(`/api/galleryItems/${id}`);
  },

  async updateGalleryItem(item: GalleryItem) {
    await axios.put(`/api/galleryItems/${item._id}`, item);
  },

  async updateGalleryOrder(ids: string[]) {
    await axios.post("/api/galleryItems/order", ids);
  }
};
