import { type ActionContext } from "vuex";

import { AnalyticsService } from "@/pages/analytics/analyticsService";
import {
  type DashboardMatomoState,
  type Period,
  type StatName
} from "@/pages/analytics/store/dashboardAnalytics.store";
import { ErrorService } from "@/shared/service/errorService";
import { type RootState } from "@/store";

export interface PeriodRangeDate extends RangeDate {
  period: string;
}

export interface RangeDate {
  startDate: string;
  endDate: string;
}

async function queryWithLoading(
  { commit }: ActionContext<DashboardMatomoState, RootState>,
  stat: StatName,
  apiCall: () => void
) {
  commit("SET_STAT_LOADING", stat);
  try {
    await apiCall();
  } catch (e) {
    ErrorService.handleError(e);
    commit("SET_STAT_ERROR", stat);
  }
}

export const actions = {
  async loadAllStats(context: ActionContext<DashboardMatomoState, RootState>) {
    await Promise.all([
      //user
      context.dispatch("loadUserMetrics"),
      context.dispatch("loadUserSectionDashboardMetrics"),
      context.dispatch("loadUserCountryMetrics"),
      context.dispatch("loadUserSessionMetrics"),
      // navigation
      context.dispatch("loadScheduleMetrics"),
      context.dispatch("loadEventMetrics"),
      context.dispatch("loadGuestsMetrics"),
      context.dispatch("loadGuestListMetrics"),
      context.dispatch("loadGalleryMetrics"),
      context.dispatch("loadInfoPratiqueMetrics"),
      context.dispatch("loadWebviewMetrics"),
      context.dispatch("loadOtherScreenMetrics"),
      // interactions
      context.dispatch("loadFavoriteEventMetrics"),
      context.rootGetters.featureIsEnable("allowRateOnEvent")
        ? context.dispatch("loadCommentedEventMetrics")
        : Promise.resolve(),
      context.rootGetters.featureIsEnable("allowRateOnEvent")
        ? context.dispatch("loadRatedEventMetrics")
        : Promise.resolve(),
      context.rootGetters.featureIsEnable("allowAttendingEvent")
        ? context.dispatch("loadParticipateEventMetrics")
        : Promise.resolve(),
      context.dispatch("loadFavoriteGuestMetrics"),
      context.rootGetters.featureIsEnable("showDrawerBottomAdvertisement") ||
      context.rootGetters.featureIsEnable("displaySplashAdvertisement")
        ? context.dispatch("loadAdvertisementMetrics")
        : Promise.resolve(),
      context.dispatch("loadNotificationMetrics")
    ]);
  },

  async loadUserMetrics(
    context: ActionContext<DashboardMatomoState, RootState>
  ) {
    const { start, end } = context.getters.getPeriodDates;
    await queryWithLoading(context, "userMetrics", async () => {
      const data = await AnalyticsService.getUsersNumber(
        start.format("YYYY-MM-DD"),
        end.format("YYYY-MM-DD")
      );
      context.commit("SET_USER_METRICS", data);
    });
  },

  async loadUserSectionDashboardMetrics(
    context: ActionContext<DashboardMatomoState, RootState>
  ) {
    const { start, end } = context.getters.getPeriodDates;
    await queryWithLoading(context, "userSectionDashboardMetrics", async () => {
      const data = await AnalyticsService.getUsersSectionDashboardMetrics(
        start.format("YYYY-MM-DD"),
        end.format("YYYY-MM-DD")
      );
      context.commit("SET_USER_SECTION_DASHBOARD_METRICS", data);
    });
  },

  async loadUserCountryMetrics(
    context: ActionContext<DashboardMatomoState, RootState>
  ) {
    const { start, end } = context.getters.getPeriodDates;
    await queryWithLoading(context, "userCountryMetrics", async () => {
      const data = await AnalyticsService.getUsersLocation(
        start.format("YYYY-MM-DD"),
        end.format("YYYY-MM-DD")
      );
      context.commit("SET_USER_COUNTRY_METRICS", data);
    });
  },

  async loadUserSessionMetrics(
    context: ActionContext<DashboardMatomoState, RootState>
  ) {
    const { start, end } = context.getters.getPeriodDates;
    await queryWithLoading(context, "userSessionMetrics", async () => {
      const data = await AnalyticsService.getUsersSessions(
        start.format("YYYY-MM-DD"),
        end.format("YYYY-MM-DD")
      );
      context.commit("SET_USER_SESSION_METRICS", data);
    });
  },

  async loadScheduleMetrics(
    context: ActionContext<DashboardMatomoState, RootState>
  ) {
    const { start, end } = context.getters.getPeriodDates;
    await queryWithLoading(context, "schedulesMetrics", async () => {
      const schedule = await AnalyticsService.getScheduleMetrics(
        start.format("YYYY-MM-DD"),
        end.format("YYYY-MM-DD")
      );
      context.commit("SET_SCHEDULES_METRICS", schedule);
    });
  },

  async loadEventMetrics(
    context: ActionContext<DashboardMatomoState, RootState>
  ) {
    const { start, end } = context.getters.getPeriodDates;
    await queryWithLoading(context, "eventMetrics", async () => {
      const data = await AnalyticsService.getEventsNumber(
        start.format("YYYY-MM-DD"),
        end.format("YYYY-MM-DD")
      );
      context.commit("SET_EVENT_METRICS", data);
    });
  },

  async loadFavoriteEventMetrics(
    context: ActionContext<DashboardMatomoState, RootState>
  ) {
    const { start, end } = context.getters.getPeriodDates;
    await queryWithLoading(context, "favoriteEventMetrics", async () => {
      const eventfav = await AnalyticsService.getFavoriteEventMetrics(
        start.format("YYYY-MM-DD"),
        end.format("YYYY-MM-DD")
      );

      context.commit("SET_FAVORITE_EVENT_METRICS", eventfav);
    });
  },

  async loadCommentedEventMetrics(
    context: ActionContext<DashboardMatomoState, RootState>
  ) {
    const { start, end } = context.getters.getPeriodDates;
    await queryWithLoading(context, "commentedEventMetrics", async () => {
      const commentEvent = await AnalyticsService.getCommentedEventMetrics(
        start.format("YYYY-MM-DD"),
        end.format("YYYY-MM-DD")
      );

      context.commit("SET_COMMENTED_EVENT_METRICS", commentEvent);
    });
  },

  async loadRatedEventMetrics(
    context: ActionContext<DashboardMatomoState, RootState>
  ) {
    const { start, end } = context.getters.getPeriodDates;
    await queryWithLoading(context, "ratedEventMetrics", async () => {
      const rateEvent = await AnalyticsService.getRatedEventMetrics(
        start.format("YYYY-MM-DD"),
        end.format("YYYY-MM-DD")
      );

      context.commit("SET_RATED_EVENT_METRICS", rateEvent);
    });
  },
  async loadParticipateEventMetrics(
    context: ActionContext<DashboardMatomoState, RootState>
  ) {
    const { start, end } = context.getters.getPeriodDates;
    await queryWithLoading(context, "participatedEventMetrics", async () => {
      const participateEvent =
        await AnalyticsService.getParticipatedEventMetrics(
          start.format("YYYY-MM-DD"),
          end.format("YYYY-MM-DD")
        );

      context.commit("SET_PARTICIPATED_EVENT_METRICS", participateEvent);
    });
  },

  async loadGuestsMetrics(
    context: ActionContext<DashboardMatomoState, RootState>
  ) {
    const { start, end } = context.getters.getPeriodDates;
    await queryWithLoading(context, "guestMetrics", async () => {
      const data = await AnalyticsService.getGuestsNumber(
        start.format("YYYY-MM-DD"),
        end.format("YYYY-MM-DD")
      );
      context.commit("SET_GUESTS_METRICS", data);
    });
  },

  async loadFavoriteGuestMetrics(
    context: ActionContext<DashboardMatomoState, RootState>
  ) {
    const { start, end } = context.getters.getPeriodDates;
    await queryWithLoading(context, "favoriteGuestMetrics", async () => {
      const favGuest = await AnalyticsService.getFavoriteGuestMetrics(
        start.format("YYYY-MM-DD"),
        end.format("YYYY-MM-DD")
      );

      context.commit("SET_FAVORITE_GUEST_METRICS", favGuest);
    });
  },

  async loadGuestListMetrics(
    context: ActionContext<DashboardMatomoState, RootState>
  ) {
    const { start, end } = context.getters.getPeriodDates;
    await queryWithLoading(context, "guestListMetrics", async () => {
      const data = await AnalyticsService.getGuestListMetrics(
        start.format("YYYY-MM-DD"),
        end.format("YYYY-MM-DD")
      );
      context.commit("SET_GUEST_LIST_METRICS", data);
    });
  },

  async loadGalleryMetrics(
    context: ActionContext<DashboardMatomoState, RootState>
  ) {
    const { start, end } = context.getters.getPeriodDates;
    await queryWithLoading(context, "galleryMetrics", async () => {
      const data = await AnalyticsService.getGalleryNumber(
        start.format("YYYY-MM-DD"),
        end.format("YYYY-MM-DD")
      );
      context.commit("SET_GALLERY_METRICS", data);
    });
  },

  async loadInfoPratiqueMetrics(
    context: ActionContext<DashboardMatomoState, RootState>
  ) {
    const { start, end } = context.getters.getPeriodDates;
    await queryWithLoading(context, "infoPratiqueMetrics", async () => {
      const data = await AnalyticsService.getInfoPratiqueMetrics(
        start.format("YYYY-MM-DD"),
        end.format("YYYY-MM-DD")
      );
      context.commit("SET_INFO_PRATIQUE_METRICS", data);
    });
  },

  async loadWebviewMetrics(
    context: ActionContext<DashboardMatomoState, RootState>
  ) {
    const { start, end } = context.getters.getPeriodDates;
    await queryWithLoading(context, "webviewMetrics", async () => {
      const webview = await AnalyticsService.getWebviewMetrics(
        start.format("YYYY-MM-DD"),
        end.format("YYYY-MM-DD")
      );

      context.commit("SET_WEBVIEW_METRICS", webview);
    });
  },

  async loadAdvertisementMetrics(
    context: ActionContext<DashboardMatomoState, RootState>
  ) {
    const { start, end } = context.getters.getPeriodDates;
    await queryWithLoading(context, "advertisementMetrics", async () => {
      const data = await AnalyticsService.getAdvertisementMetrics(
        start.format("YYYY-MM-DD"),
        end.format("YYYY-MM-DD")
      );
      context.commit("SET_ADVERTISEMENT_METRICS", data);
    });
  },

  async loadNotificationMetrics(
    context: ActionContext<DashboardMatomoState, RootState>
  ) {
    const { start, end } = context.getters.getPeriodDates;
    await queryWithLoading(context, "notificationMetrics", async () => {
      const data = await AnalyticsService.getNotificationMetrics(
        start.format("YYYY-MM-DD"),
        end.format("YYYY-MM-DD")
      );
      context.commit("SET_NOTIFICATION_METRICS", data);
    });
  },

  async loadOtherScreenMetrics(
    context: ActionContext<DashboardMatomoState, RootState>
  ) {
    const { start, end } = context.getters.getPeriodDates;
    await queryWithLoading(context, "otherScreenMetrics", async () => {
      const data = await AnalyticsService.getOtherScreenMetrics(
        start.format("YYYY-MM-DD"),
        end.format("YYYY-MM-DD")
      );
      context.commit("SET_OTHER_SCREEN_METRICS", data);
    });
  },

  async persistFilterDatesModel(
    context: ActionContext<DashboardMatomoState, RootState>,
    payload: Period
  ) {
    context.commit("SET_FILTER_DATES_MODEL", payload);
    await context.dispatch("loadAllStats");
  }
};
