import { translatableText } from "@/shared/filters/translatableText.filter";
import { ErrorService } from "@/shared/service/errorService";

import { i18n } from "../../../i18n/i18n";
import { SnackbarService } from "../../../shared/snackbar/snackbar.service";
import { ScenesService } from "../scenes.service";

export const actions = {
  async list({ commit }) {
    commit("LOAD");
    try {
      const scenes = await ScenesService.getAll();
      commit("SET_SCENES", scenes);
    } catch (e) {
      ErrorService.handleError(e);
    } finally {
      commit("UNLOAD");
    }
  },

  async deleteScene({ dispatch, state, commit }) {
    commit("LOAD");
    try {
      await ScenesService.deleteScene(state.deletingSceneId);
      SnackbarService.info(
        i18n.t("SCENES.SNACK_BAR.DELETE", {
          string: translatableText(state.scenes[state.deletingSceneId].names)
        })
      );
      await dispatch("list");
    } catch (e) {
      ErrorService.handleError(e);
      commit("UNLOAD");
    } finally {
      commit("SET_NOT_DELETING");
    }
  },

  async addScene({ commit, dispatch, state, getters }, scene) {
    commit("LOAD");
    try {
      let message = "";
      if (state.editingSceneId) {
        await ScenesService.updateScene(scene);
        message = i18n.t("SCENES.SNACK_BAR.EDIT", {
          string: translatableText(scene.names)
        });
      } else {
        scene.weight = getters.getMinWeight;
        await ScenesService.addScene(scene);
        message = i18n.t("SCENES.SNACK_BAR.ADD", {
          string: translatableText(scene.names)
        });
      }
      SnackbarService.info(message);
      commit("RESET");
      await dispatch("list");
    } catch (e) {
      ErrorService.handleError(e);
      commit("UNLOAD");
    }
  },

  async move({ state, commit }, payload) {
    commit("LOAD");
    try {
      commit("MOVE", {
        sceneId: payload.sceneToMove._id,
        up_down: payload.up_down
      });
      await ScenesService.updateScene(state.scenes[payload.sceneToMove._id]);
      await ScenesService.updateScene(state.scenes[state.sceneToMoveId]);
      commit("RESET_MOVE");
    } catch (e) {
      ErrorService.handleError(e);
    } finally {
      commit("UNLOAD");
    }
  },

  async setDeletionMode({ commit }, sceneId) {
    commit("LOAD");
    try {
      const [eventList, markers] = await Promise.all([
        ScenesService.getSceneEvents(sceneId),
        ScenesService.getSceneMarkers(sceneId)
      ]);
      commit("SET_SCENE_EVENTS", eventList);
      commit("SET_SCENE_MARKERS", markers);
      commit("SET_DELETING", sceneId);
    } catch (e) {
      ErrorService.handleError(e);
    } finally {
      commit("UNLOAD");
    }
  }
};
