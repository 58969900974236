import {
  makeTranslatableText,
  type TranslatableText
} from "../../../types/TranslatableText.type";

export interface DeeplinkElement {
  id: string;
  position: number;
  titles: TranslatableText;
  type: DeeplinkElementType;
  payload: any | undefined;
  icon: string | undefined;
  openInWebview: boolean;
  privateSectionSecret: string | undefined;
  defaultSegment: "BY_SCENE" | "BY_HOUR";
}

export class MenuElement implements DeeplinkElement {
  privateSectionSecret: string | undefined;
  id: string = "";
  position: number = -1;
  titles: TranslatableText = makeTranslatableText();
  type: DeeplinkElementType = DeeplinkElementType.WEBVIEW;
  payload: any | undefined = undefined;
  icon: string | undefined = undefined;
  removable: boolean = true;
  openInWebview: boolean = true;
  defaultSegment: "BY_SCENE" | "BY_HOUR" = "BY_SCENE";
}

export type MenuChoiceData = {
  data: string;
  name: TranslatableText;
  hidden: boolean;
};

export type MenuChoice = MenuElement & {
  choices: MenuChoiceData[];
  multiselect: boolean;
};

export type NavBarContent = {
  id?: string;
  type: "BOTTOM_NAV";
  elements: [
    MenuElement?,
    MenuElement?,
    MenuElement?,
    MenuElement?,
    MenuElement?
  ];
};

export enum DeeplinkElementType {
  HOME = "HOME",
  MENU = "MENU",
  PROGRAM = "PROGRAM",
  GUEST_LIST_TYPE = "GUEST_LIST_TYPE",
  GUEST_LIST = "GUEST_LIST",
  PRACTICAL_INFO = "PRACTICAL_INFO",
  PRACTICAL_INFO_LIST = "PRACTICAL_INFO_LIST",
  SPONSOR_LIST = "SPONSOR_LIST",
  TICKETING = "TICKETING",
  CASHLESS = "CASHLESS",
  WEBVIEW = "WEBVIEW",
  MAP = "MAP",
  NOTIFICATIONS = "NOTIFICATIONS",
  ABOUT = "ABOUT",
  GALLERY = "GALLERY",
  EMPTY = "EMPTY"
}

export type DraggableChangeType<T> = {
  added:
    | {
        newIndex: number;
        element: T;
      }
    | undefined;
  removed:
    | {
        oldIndex: number;
        element: T;
      }
    | undefined;
  moved:
    | {
        newIndex: number;
        oldIndex: number;
        element: T;
      }
    | undefined;
};
