import { type ActionContext, type Module } from "vuex";

import { ErrorService } from "../service/errorService";
import { type RootState } from "./../../store";
import { WarningBannerService } from "./../service/warningBanner.service";

export class WarningBannerState {
  message: string = "";
  loaded: boolean = false;
}

export const warningBanner: Module<WarningBannerState, RootState> = {
  namespaced: true,
  state: new WarningBannerState(),
  actions: {
    async loadWarningBanner({
      commit
    }: ActionContext<WarningBannerState, RootState>) {
      try {
        const data = await WarningBannerService.getWarningBanner();
        commit("SET_MESSAGE", data.message);
      } catch (e) {
        ErrorService.handleError(e, false);
      }
    }
  },

  mutations: {
    SET_MESSAGE(state: WarningBannerState, message: string) {
      state.message = message;
      state.loaded = true;
    }
  },

  getters: {
    getWarningBannerIsLoaded: (state: WarningBannerState) => {
      return state.loaded;
    }
  }
};
