import store from "../../store";

function showSnackbar(message: string, color: string) {
  store.dispatch("snackbar/SHOW", { message, color });
}

export const SnackbarService = {
  info(message: string) {
    showSnackbar(message, "green");
  },
  warn(message: string) {
    showSnackbar(message, "orange");
  },
  error(message: string) {
    showSnackbar(message, "red");
  }
};
