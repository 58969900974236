export const state = {
  mode: "view",
  attendees: [],
  currentAttendee: null,

  pagination: {
    sortBy: ["lastName"],
    sortDesc: [true],
    itemsPerPage: 25,
    page: 1,
    totalItems: 0
  },
  filter: {
    search: "",
    onlyReported: false
  },

  loading: false
};
