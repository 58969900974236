import { type Route, type RouteConfig } from "vue-router";

import store from "../../store";

export const GuestTypeRouter: RouteConfig[] = [
  {
    path: "/guest-types",
    component: () => import("./GuestTypes.vue"),
    beforeEnter: async (to: Route, from: Route, next: any) => {
      await store.dispatch("LOAD_APP_CONFIG");
      await store.dispatch("guestType/list");
      next();
    }
  }
];
