import { ErrorService } from "@/shared/service/errorService";

import { i18n } from "../../../i18n/i18n";
import { SnackbarService } from "../../../shared/snackbar/snackbar.service";
import { NotificationsTopicsService } from "../../notificationsTopics/notificationsTopics.service";
import { NotificationsService } from "../notifications.service";

async function handleCall(commit, dispatch, message, call, additionalAction) {
  commit("LOAD");
  try {
    await call();
    if (additionalAction) {
      additionalAction();
    }
    await dispatch("list");
    SnackbarService.info(message);
  } catch (e) {
    ErrorService.handleError(e);
  } finally {
    commit("SET_VIEW");
  }
}

export const actions = {
  async list({ commit }) {
    try {
      commit("LOAD");
      const notifications = await NotificationsService.getAll();
      commit("SET_NOTIFICATIONS", notifications);
    } catch (e) {
      ErrorService.handleError(e);
    }
  },

  async delete({ commit, state, dispatch }) {
    handleCall(
      commit,
      dispatch,
      i18n.t("NOTIFICATIONS.SNACK_BAR.NOTIF_DELETE"),
      () =>
        NotificationsService.deleteNotification(state.currentNotification._id),
      () => commit("DELETE_NOTIFICATION")
    );
  },

  async addNotification({ commit, state, dispatch }, payload) {
    commit("LOAD");
    try {
      payload.sendToDevTopic = state.mode === "add_admin";

      await NotificationsService.addNotification(payload);
      const message = i18n.t("NOTIFICATIONS.SNACK_BAR.NOTIF_SENT");

      SnackbarService.info(message);
    } catch (e) {
      ErrorService.handleError(e);
    } finally {
      await dispatch("list");
      commit("SET_VIEW");
    }
  },

  async addNotificationNow({ commit, state, dispatch }, payload) {
    commit("LOAD");
    try {
      payload.sendToDevTopic = state.mode === "add_admin";
      await NotificationsService.addNotificationNow(payload);
      const message = i18n.t("NOTIFICATIONS.SNACK_BAR.NOTIF_SENT");

      SnackbarService.info(message);
    } catch (e) {
      ErrorService.handleError(e);
    } finally {
      await dispatch("list");
      commit("SET_VIEW");
    }
  },

  async sendOnDevice({ commit, dispatch }, payload) {
    handleCall(
      commit,
      dispatch,
      i18n.t("NOTIFICATIONS.SNACK_BAR.SENT_ON_DEVICE"),
      () =>
        NotificationsService.sendOnDevice(payload.notification, payload.token)
    );
  },

  async forceGrabDevice({ commit, dispatch }, payload) {
    handleCall(
      commit,
      dispatch,
      i18n.t("NOTIFICATIONS.SNACK_BAR.SENT_ON_DEVICE"),
      () => NotificationsService.forceGrabDevice(payload.token)
    );
  },

  async loadTopics({ commit }) {
    commit("LOAD");
    const notifTopics = await NotificationsTopicsService.getAll();
    commit("SET_TOPICS", notifTopics);
  },

  async deleteAll({ commit, dispatch }) {
    handleCall(
      commit,
      dispatch,
      i18n.t("NOTIFICATIONS.SNACK_BAR.DELETE_ALL"),
      () => NotificationsService.deleteAll()
    );
  }
};
