import tinycolor from "tinycolor2";

import { i18n } from "@/i18n/i18n";
import { AnalyticsService } from "@/pages/analytics/analyticsService";
import {
  type ApplicationConfig,
  type ApplicationConfigFontKeys,
  ApplicationConfigService
} from "@/shared/service/applicationConfig.service";
import { ErrorService } from "@/shared/service/errorService";
import { FilesService } from "@/shared/service/files.service";
import { SnackbarService } from "@/shared/snackbar/snackbar.service";

import { type Font } from "../../../shared/service/fontsService";
import { commitAppConfig } from "../../../shared/store/app-config.store";
import { InternalType } from "../../../shared/types/files.type";
import { keys } from "../../../utils";
import { createStore } from "../../../utils/createStore";

export class ApplicationCfgState {
  applicationCfg: ApplicationConfig | null = null;
  splashImage: string | null = null;
  loading = false;

  isDrawerAdsPercentageValid = false;
  isSplashAdsPercentageValid = false;

  cropDrawerAdImage: string | null = null;
  cropSplashImage: string | null = null;
  cropMenuImage: string | null = null;

  availableFonts: string[] = [];

  colorBackground: string | null = null;
  colorTitle: string | null = null;
  colorAction: string | null = null;
  colorBlockBackground: string | null = null;
  colorBlockText: string | null = null;

  fontChanges: { [k in ApplicationConfigFontKeys]?: File } = {};
  fontTitle: Font | null = null;
  fontTextRegular: Font | null = null;
  fontTextSemiBold: Font | null = null;
  fontTextBold: Font | null = null;
}

export const getColorPrimaryDark = (primaryColor: string) =>
  tinycolor(primaryColor).darken(15).toString();

export const getColorPrimaryLight = (primaryColor: string) =>
  tinycolor(primaryColor).lighten(15).toString();

export const {
  applicationCfg,
  commit: commitApplicationCfg,
  dispatch: dispatchApplicationCfg,
  mapGetters: mapGettersApplicationCfg,
  mapState: mapStateApplicationCfg,
  useGetter: useApplicationCfgGetter,
  useState: useApplicationCfgState
} = createStore({
  namespaced: true,
  moduleName: "applicationCfg",
  initState: new ApplicationCfgState(),
  mutations: {
    SET_APPLICATION_CFG(state, applicationCfg: ApplicationConfig) {
      state.applicationCfg = applicationCfg;
      state.splashImage = applicationCfg.splashImage;
    },

    LOAD(state) {
      state.loading = true;
    },

    UNLOAD(state) {
      state.loading = false;
    },

    SET_SPLASH_IMAGE(state, image: string) {
      state.splashImage = image;
    },

    SET_SPLASH_IMAGE_CROP(state, crop: string) {
      state.cropSplashImage = crop;
    },

    SET_DRAWER_AD_IMAGE_CROP(state, crop: string) {
      state.cropDrawerAdImage = crop;
    },

    SET_FONT_CHANGE(
      state,
      { file, key }: { file: File; key: ApplicationConfigFontKeys }
    ) {
      state.fontChanges[key] = file;
    }
  },
  actions: {
    async loadApplicationCfg() {
      try {
        commitApplicationCfg("LOAD");
        const applicationCfg = await ApplicationConfigService.get();
        commitApplicationCfg("SET_APPLICATION_CFG", applicationCfg);
        commitAppConfig("SET_APP_CONFIG", applicationCfg);
      } catch (e) {
        ErrorService.handleError(e);
      } finally {
        commitApplicationCfg("UNLOAD");
      }
    },

    async updateApplicationCfg({ state }, applicationCfg: ApplicationConfig) {
      try {
        commitApplicationCfg("LOAD");
        await dispatchApplicationCfg("saveAllImg");
        await Promise.all(
          keys(state.fontChanges).map(async key => {
            if (applicationCfg[key] != null) {
              const newFontId = await FilesService.uploadFont(
                state.fontChanges[key]!
              );
              applicationCfg[key].id = newFontId.filename;
              applicationCfg[key].postScriptName = newFontId.postScriptName;
            }
          })
        );
        applicationCfg.splashImage = state.splashImage;

        // applicationCfg.colorPrimaryLight = getColorPrimaryLight(
        //   state.applicationCfg.colorPrimary
        // );
        // applicationCfg.colorPrimaryDark = getColorPrimaryDark(
        //   state.applicationCfg.colorPrimary
        // );
        await ApplicationConfigService.updateApplicationCfg(applicationCfg);
        SnackbarService.info(i18n.t("APPLICATION_CFG.SAVE").toString());
        await dispatchApplicationCfg("loadApplicationCfg");
      } catch (e) {
        ErrorService.handleError(e);
      } finally {
        commitApplicationCfg("UNLOAD");
      }
    },

    async updateApplicationCfgFeatures({}, applicationCfg) {
      try {
        commitApplicationCfg("LOAD");
        await ApplicationConfigService.updateApplicationCfgFeatures(
          applicationCfg
        );
        SnackbarService.info(i18n.t("APPLICATION_CFG.SAVE").toString());
        await dispatchApplicationCfg("loadApplicationCfg");
      } catch (e) {
        SnackbarService.error(e.response.data.message);
      } finally {
        commitApplicationCfg("UNLOAD");
      }
    },

    async saveAllImg({ state }) {
      if (!state.cropSplashImage) {
        return;
      }
      const { filename } = await FilesService.uploadImage({
        image: state.cropSplashImage,
        internalType: InternalType.PICTURE
      });
      commitApplicationCfg("SET_SPLASH_IMAGE", filename);
    },

    async shiftDates() {
      try {
        commitApplicationCfg("LOAD");
        await ApplicationConfigService.shiftDates();
        SnackbarService.info(i18n.t("APPLICATION_CFG.SAVE").toString());
      } catch (e) {
        ErrorService.handleError(e);
      } finally {
        commitApplicationCfg("UNLOAD");
      }
    },

    async forceMatomoInit() {
      await AnalyticsService.init();
      await dispatchApplicationCfg("loadApplicationCfg");
    }
  },
  getters: {
    getApplicationCfg: state => {
      return state.applicationCfg;
    },
    availableFonts: state => {
      return state.availableFonts;
    }
  }
});
