import { type RouteConfig } from "vue-router";

export const privateSectionsRouter: RouteConfig[] = [
  {
    path: "/privateSections",
    component: () => import("./PrivateSections.vue"),
    beforeEnter: async (to, from, next) => {
      next();
    }
  }
];
