import { i18n } from "@/i18n/i18n";

import { SnackbarService } from "../snackbar/snackbar.service";

export const ErrorService = {
  handleError(error: any, showError: boolean = true) {
    if (showError) {
      SnackbarService.error(i18n.t("GENERAL.SNACKBAR_ERROR").toString());
      console.log(error);
    }
  }
};
