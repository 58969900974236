import { actions } from "./orphaned.actions";
import { getters } from "./orphaned.getters";
import { mutations } from "./orphaned.mutations";
import { state } from "./orphaned.state";

export const orphaned = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
