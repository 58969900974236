import axios from "axios";

export const AttendeeWhiteListService = {
  async getAll(query, page, itemsPerPage, sortBy, desc, onlyReported) {
    const { data } = await axios.get("/api/attendee/whitelist", {
      params: {
        query: query,
        offset: (page - 1) * itemsPerPage,
        limit: itemsPerPage,
        order: sortBy,
        desc,
        onlyReported: !!onlyReported
      }
    });
    return data;
  },

  async delete(id) {
    return await axios.delete("/api/attendee/whitelist/id/" + id);
  },

  async deleteAll() {
    return await axios.delete("/api/attendee/whitelist/all");
  },

  async update(attendeeWhiteList) {
    return await axios.post("/api/attendee/whitelist", attendeeWhiteList);
  },
  async uploadFile(file) {
    return await axios.post("/api/attendee/whitelist/importXls", {
      file: file
    });
  }
};
