export const getters = {
  getFilteredUsers: state => {
    return state.search && state.search.length > 0
      ? Object.values(state.users).filter(
          u => u.name.includes(state.search) || u.email.includes(state.search)
        )
      : Object.values(state.users);
  },

  getUser: state => {
    return state.editingUser
      ? state.editingUser
      : {
          name: "",
          email: "",
          expiryDate: "",
          blocked: "false"
        };
  }
};
