import { i18n } from "../../../i18n/i18n";
import { PAGE_MODE } from "../../../shared/constants";

export const mutations = {
  LOAD(state) {
    state.loading = true;
  },

  SET_DELETING(state, payload) {
    state.mode = PAGE_MODE.DELETE;
    state.currentNotification = { ...payload };
  },

  SET_VIEW(state) {
    state.mode = PAGE_MODE.VIEW;
    state.currentNotification = null;
    state.title = i18n.t("NOTIFICATIONS.TITLE.LIST");
    state.loading = false;
  },

  SET_NOTIFICATIONS(state, notifications) {
    state.notifications = notifications;
    state.loading = false;
  },

  DELETE_NOTIFICATION(state) {
    state.notifications = state.notifications.filter(
      n => n._id !== state.currentNotification._id
    );
  },

  SET_ADDING(state) {
    state.mode = PAGE_MODE.ADD;
  },

  SET_EDIT(state, notification) {
    state.mode = PAGE_MODE.EDIT;
    state.currentNotification = { ...notification };
  },

  SET_ADDING_ADMIN(state) {
    state.mode = "add_admin";
  },

  SET_DELETE_ALL(state) {
    state.mode = "delete_all";
  }
};
