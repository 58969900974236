import { ErrorService } from "@/shared/service/errorService";

import router from "../../../router.ts";
import { AttendeeWhiteListService } from "../attendeeWhiteList.service";

export const actions = {
  async list({ commit, state }, payload) {
    commit("LOAD");
    if (payload && payload.pagination) {
      commit("SET_PAGINATION", { ...state.pagination, ...payload.pagination });
    }
    if (payload && payload.filter) {
      commit("SET_FILTER", { ...state.filter, ...payload.filter });
    }
    const { sortBy, sortDesc, page, itemsPerPage } = state.pagination;
    const { attendeeWhiteLists, total } = await AttendeeWhiteListService.getAll(
      state.filter.search,
      page,
      itemsPerPage,
      sortBy[0],
      sortDesc[0],
      state.filter.onlyReported
    );
    commit("SET_ATTENDEE_WHITE_LIST", { attendeeWhiteLists, total });
  },

  async save({ commit, dispatch, state }) {
    commit("LOAD");
    try {
      await AttendeeWhiteListService.update(state.currentAttendeeWhiteList);
      await dispatch("list");
      router.push("/attendee-white-list");
    } catch (e) {
      ErrorService.handleError(e);
    } finally {
      commit("SET_VIEW");
    }
  },

  async saveFile({ commit, dispatch }, file) {
    commit("LOAD");
    await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = error => reject(error);
    }).then(async x64File => {
      try {
        await AttendeeWhiteListService.uploadFile(x64File);
        await dispatch("list");
        router.push("/attendee-white-list");
      } catch (e) {
        ErrorService.handleError(e);
      } finally {
        commit("SET_VIEW");
      }
    });
  },

  async delete({ commit, state }) {
    commit("LOAD");
    try {
      await AttendeeWhiteListService.delete(state.currentAttendeeWhiteList._id);

      commit("SET_ATTENDEE_WHITE_LIST", {
        attendeeWhiteLists: state.attendeeWhiteLists.filter(
          item => item._id !== state.currentAttendeeWhiteList._id
        ),
        total: state.pagination.totalItems - 1
      });
    } catch (e) {
      ErrorService.handleError(e);
    } finally {
      commit("SET_VIEW");
    }
  },

  async deleteAll({ commit }) {
    commit("LOAD");
    try {
      await AttendeeWhiteListService.deleteAll();
      commit("SET_ATTENDEE_WHITE_LIST", { attendeeWhiteLists: [], total: 0 });
    } catch (e) {
      ErrorService.handleError(e);
    } finally {
      commit("SET_VIEW");
    }
  }
};
