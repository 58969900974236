import { actions } from "./sponsorsCategories.actions";
import { getters } from "./sponsorsCategories.getters";
import { mutations } from "./sponsorsCategories.mutations";
import { state } from "./sponsorsCategories.state";

export const sponsorsCategories = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
