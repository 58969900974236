import { type Module } from "vuex";

import { type RootState } from "@/store";

export class AppBarState {
  text: string = "";
}

export const appBar: Module<AppBarState, RootState> = {
  namespaced: false,
  state: new AppBarState(),
  mutations: {
    SET_TEXT(state: AppBarState, text: string) {
      state.text = text;
    }
  }
};
