import cloneDeep from "lodash/cloneDeep";

import { i18n } from "@/i18n/i18n";
import {
  type MenuChoice,
  type MenuElement,
  type NavBarContent
} from "@/pages/menuEntries/MenuEntry.type";
import { NavbarContentService } from "@/pages/menuEntries/navbarContentService";
import { PAGE_MODE, type PageMode } from "@/shared/constants";
import { ErrorService } from "@/shared/service/errorService";
import { SnackbarService } from "@/shared/snackbar/snackbar.service";
import { createStore } from "@/utils/createStore";

export class MenuEntriesState {
  mode: PageMode = PAGE_MODE.VIEW;
  navBar: NavBarContent = {
    elements: [],
    type: "BOTTOM_NAV"
  };
  menuChoices: MenuChoice[] = [];
  currentElement:
    | {
        element: MenuElement | undefined;
        wantedIndex: number | undefined;
      }
    | undefined = undefined;
  showEditElementModal = false;
  showDeleteElementModal = false;
  loading = 0;
}

export const {
  menuEntries,
  commit: menuEntriesCommit,
  dispatch: menuEntriesDispatch,
  mapGetters: menuEntriesMapGetters,
  mapState: menuEntriesMapState,
  useGetter: useMenuEntriesGetter,
  useState: useMenuEntriesState
} = createStore({
  moduleName: "menuEntries",
  initState: new MenuEntriesState(),
  mutations: {
    LOAD(state: MenuEntriesState) {
      state.loading++;
    },
    UNLOAD(state: MenuEntriesState) {
      state.loading--;
    },
    SHOW_EDIT_ELEMENT_MODAL(
      state: MenuEntriesState,
      { element }: { element: MenuElement }
    ) {
      state.mode = PAGE_MODE.EDIT;
      state.currentElement = {
        element: element,
        wantedIndex: undefined
      };
      state.showEditElementModal = true;
    },
    SHOW_ADD_ELEMENT_MODAL(
      state: MenuEntriesState,
      { index }: { index: number }
    ) {
      state.mode = PAGE_MODE.ADD;
      state.currentElement = {
        element: undefined,
        wantedIndex: index
      };
      state.showEditElementModal = true;
    },
    SHOW_DELETE_ELEMENT_MODAL(
      state: MenuEntriesState,
      { element }: { element: MenuElement }
    ) {
      state.mode = PAGE_MODE.DELETE;
      state.currentElement = {
        element: element,
        wantedIndex: undefined
      };
      state.showDeleteElementModal = true;
    },
    GO_TO_VIEW(state: MenuEntriesState) {
      state.mode = PAGE_MODE.VIEW;
      state.loading--;
      state.currentElement = undefined;
      state.showEditElementModal = false;
      state.showDeleteElementModal = false;
    }
  },
  actions: {
    async getAllContent({ commit, state }) {
      commit("LOAD");
      try {
        const navBarContent = await NavbarContentService.getNavbarContent();
        state.navBar = navBarContent;
      } catch (e) {
        ErrorService.handleError(e);
      } finally {
        commit("UNLOAD");
      }
    },

    async getAllChoices({ commit, state }) {
      commit("LOAD");
      try {
        const choices = await NavbarContentService.getChoices();
        state.menuChoices = choices;
      } catch (e) {
        ErrorService.handleError(e);
      } finally {
        commit("UNLOAD");
      }
    },

    async editElement(
      { commit, state },
      { element }: { element: MenuElement }
    ) {
      commit("LOAD");
      try {
        const foundElement = state.navBar.elements.find(
          listElement => listElement && listElement.id === element.id
        );
        if (!foundElement) {
          console.error("Menu element not found");
        }
        Object.assign(foundElement ?? {}, cloneDeep(element));
        await NavbarContentService.save(state.navBar);
      } catch (e) {
        ErrorService.handleError(e);
      } finally {
        commit("UNLOAD");
      }
    },

    async deleteElement(
      { commit, state },
      { element }: { element: MenuElement }
    ) {
      commit("LOAD");
      try {
        if (element.removable === false) {
          return;
        }
        const index = state.navBar.elements.indexOf(element);
        if (index > -1) {
          state.navBar.elements.splice(index, 1);
        }
        await NavbarContentService.save(state.navBar);
      } catch (e) {
        ErrorService.handleError(e);
      } finally {
        commit("UNLOAD");
      }
    },

    async addElement(
      { commit, state },
      { element, index }: { element: MenuElement; index: number }
    ) {
      commit("LOAD");
      try {
        if (state.navBar.elements.length >= 5 || index >= 5) {
          // Only a debug error, should not happen if the code is valid
          SnackbarService.error(
            "Cannot add another element inside the menu, the array is already at max size or the set index is out of bounds"
          );
        } else {
          element.position = index;
          state.navBar.elements.splice(index, 0, element);
        }
        await NavbarContentService.save(state.navBar);
        commit("SHOW_EDIT_ELEMENT_MODAL", { element });
      } catch (e) {
        ErrorService.handleError(e);
      } finally {
        commit("UNLOAD");
      }
    },

    async moveElement(
      { commit, state },
      {
        oldPosition,
        newPosition,
        element
      }: {
        oldPosition: number;
        newPosition: number;
        element: MenuElement;
      }
    ) {
      commit("LOAD");
      try {
        if (oldPosition === newPosition) {
          return;
        } else if (newPosition > oldPosition) {
          // When moving to the right
          state.navBar.elements.forEach(el => {
            if (
              el &&
              el.position <= newPosition &&
              el.position >= oldPosition
            ) {
              el.position--;
            }
          });
        } else {
          // When moving to the left
          state.navBar.elements.forEach(el => {
            if (
              el &&
              el.position >= newPosition &&
              el.position <= oldPosition
            ) {
              el.position++;
            }
          });
        }
        if (element) {
          element.position = newPosition;
        }
        state.navBar.elements = state.navBar.elements.sort(
          (e1, e2) => (e1 ? e1.position : 1000) - (e2 ? e2.position : 1000)
        );
        await NavbarContentService.save(state.navBar);
      } catch (e) {
        ErrorService.handleError(e);
      } finally {
        commit("UNLOAD");
      }
    },
    async openHome({ state, commit }) {
      const home = state.navBar.elements.find(e => e?.type === "HOME");
      if (home) {
        commit("SHOW_EDIT_ELEMENT_MODAL", {
          element: home
        });
      }
    }
  },
  getters: {
    menuChoices: (state: MenuEntriesState): MenuChoice[] => {
      return state.menuChoices.sort((sort1: MenuChoice, sort2: MenuChoice) => {
        const title1 = i18n.t(`MENU_ENTRIES.TYPE.${sort1.type}`) as string;
        const title2 = i18n.t(`MENU_ENTRIES.TYPE.${sort2.type}`) as string;
        return title1.localeCompare(title2);
      });
    },
    currentElement: (state: MenuEntriesState) => {
      return state.currentElement;
    },
    showEditElementModal: (state: MenuEntriesState) => {
      return state.showEditElementModal;
    },
    showDeleteElementModal: (state: MenuEntriesState) => {
      return state.showDeleteElementModal;
    }
  }
});
