import { type Route } from "vue-router";

import { dispatchApplicationCfg } from "../applicationCfg/store/applicationCfg.store";

export const ConfigDevRouter = [
  {
    path: "/applicationConfig",
    component: () => import("./ConfigDev.vue"),
    beforeEnter: async (to: Route, from: Route, next: any) => {
      await dispatchApplicationCfg("loadApplicationCfg");
      next();
    }
  }
];
