import type { TranslatableText } from "./../../../types/TranslatableText.type";

export class GalleryItem {
  _id: string = "";
  titles: TranslatableText = {
    en: "",
    fr: ""
  };
  thumbnailImage: string = "";
  image: string = "";
  weight: number = -1;
  visible: boolean = false;
}
