import { type ActionContext } from "vuex";

import { type ProgramsState } from "@/pages/programs/store/programs.state";
import { ErrorService } from "@/shared/service/errorService";
import { type Program } from "@/shared/types/program.type";
import { type RootState } from "@/store";

import { i18n } from "../../../i18n/i18n";
import { SnackbarService } from "../../../shared/snackbar/snackbar.service";
import { ProgEventService } from "../../progEvent/progEvent.service";
import { ProgramsService } from "../programs.service";

export const actions = {
  async list({ commit }: ActionContext<ProgramsState, RootState>) {
    commit("LOAD");
    try {
      const programs = await ProgramsService.getAll();
      commit("SET_PROGRAMS", programs);
    } catch (e) {
      ErrorService.handleError(e);
    } finally {
      commit("UNLOAD");
    }
  },

  async setDeleting(
    { state, commit }: ActionContext<ProgramsState, RootState>,
    id: string
  ) {
    commit("LOAD");
    try {
      commit("SET_DELETING", id);
      const events = await ProgEventService.getProgramEventByProgram(
        state.deletingProgramId!
      );
      commit("SET_EVENTS", events);
    } catch (e) {
      ErrorService.handleError(e);
    } finally {
      commit("UNLOAD");
    }
  },

  async deleteProgram({
    state,
    commit,
    dispatch
  }: ActionContext<ProgramsState, RootState>) {
    commit("LOAD");
    try {
      await ProgramsService.delete(state.deletingProgramId!);
      SnackbarService.info(
        i18n
          .t("PROGRAMS.SNACK_BAR.DELETE", {
            string: state.programs[state.deletingProgramId!].names["fr"]
          })
          .toString()
      );
      await dispatch("list");
    } catch (e) {
      ErrorService.handleError(e);
    } finally {
      commit("SET_NOT_DELETING");
    }
  },

  async addProgram(
    { commit, state, dispatch }: ActionContext<ProgramsState, RootState>,
    program: Program
  ) {
    commit("LOAD");
    try {
      let message = "";
      if (state.editingProgramId) {
        await ProgramsService.updateProgram(program);
        message = i18n
          .t("PROGRAMS.SNACK_BAR.EDIT", {
            string: program.names["fr"]
          })
          .toString();
      } else {
        await ProgramsService.addProgram(program);
        message = i18n
          .t("PROGRAMS.SNACK_BAR.ADD", {
            string: program.names["fr"]
          })
          .toString();
      }
      SnackbarService.info(message);
      await dispatch("list");
      commit("RESET");
    } catch (e) {
      ErrorService.handleError(e);
    }
  }
};
