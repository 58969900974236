export const exhaustiveCheck = (_param: never): never => {
  throw new Error("exhaustive check");
};

/**
 * Same as Object.keys() but with better typing
 * */
export function keys<T extends object>(o: T): Array<keyof T> {
  return Object.keys(o) as Array<keyof T>;
}

/**
 * Not nullish typeguard
 * */
export function isNotNullish<T>(value: T | null | undefined): value is T {
  return value != null;
}

type ObjectEntry<BaseType> = [keyof BaseType, BaseType[keyof BaseType]];
/**
 * Same as Object.entries() but with better typing
 * */
export function entries<T extends object>(obj: T): Array<ObjectEntry<T>> {
  return Object.entries(obj) as Array<ObjectEntry<T>>;
}

/**
 * Same as Array.includes() but with better typing
 * */
export function includes<T extends U, U>(
  coll: ReadonlyArray<T>,
  el: U
): el is T {
  return coll.includes(el as T);
}

/**
 * Same as Object.fromEntries() but with better typing
 * */
export function fromEntries<K extends string, T>(
  entries: Iterable<readonly [K, T]>
): { [k in K]: T } {
  return Object.fromEntries(entries) as unknown as { [k in K]: T };
}

/**
 * Creates an array with [0 .. arraySize - 1]
 * */
export function range(arraySize: number): number[] {
  return [...Array(arraySize).keys()];
}

/**
 * Map an object by its entries (tuples key/value)
 * */
export function mapByEntries<T extends object, MappedK extends string, MappedV>(
  obj: T,
  mapper: (entry: ObjectEntry<T>) => [MappedK, MappedV]
): { [k in MappedK]: MappedV } {
  return fromEntries(entries(obj).map(mapper));
}

/**
 * zip([a,a,..,a],[b,b,..,b]) === [[a,b],[a,b],..,[a,b]]
 */
export function zip<T extends ReadonlyArray<unknown>[]>(
  ...args: T
): { [K in keyof T]: T[K] extends (infer V)[] ? V : never }[] {
  const minLength = Math.min(...args.map(arr => arr.length));
  return range(minLength).map(i => args.map(arr => arr[i])) as any;
}

// https://github.com/piotrwitek/utility-types/blob/411e83ecf70e428b529fc2a09a49519e8f36c8fa/src/mapped-types.ts#L616
/**
 * @desc From `T` make a set of properties by key `K` become required
 * @example
 * ```ts
 *    type Props = {
 *      name?: string;
 *      age?: number;
 *      visible?: boolean;
 *    };
 *
 *    // Expect: { name: string; age: number; visible: boolean; }
 *    type Props = AugmentedRequired<Props>;
 *
 *    // Expect: { name?: string; age: number; visible: boolean; }
 *    type Props = AugmentedRequired<Props, 'age' | 'visible'>;
 * ```
 */
export type AugmentedRequired<
  T extends object,
  K extends keyof T = keyof T
> = Omit<T, K> & Required<Pick<T, K>>;

// https://github.com/piotrwitek/utility-types/blob/411e83ecf70e428b529fc2a09a49519e8f36c8fa/src/mapped-types.ts#L556
/**
 * Optional
 * @desc From `T` make a set of properties by key `K` become optional
 * @example
 *    type Props = {
 *      name: string;
 *      age: number;
 *      visible: boolean;
 *    };
 *
 *    // Expect: { name?: string; age?: number; visible?: boolean; }
 *    type Props = Optional<Props>;
 *
 *    // Expect: { name: string; age?: number; visible?: boolean; }
 *    type Props = Optional<Props, 'age' | 'visible'>;
 */
export type Optional<T extends object, K extends keyof T = keyof T> = Omit<
  T,
  K
> &
  Partial<Pick<T, K>>;

/**
 * Determines whether a given color is dark or light.
 * @param color The color in hex format.
 */
export function isColorDark(color: string): "light" | "dark" {
  const _color = color.charAt(0) === "#" ? color.substring(1, 7) : color;
  const r = parseInt(_color.substring(0, 2), 16); // hexToR
  const g = parseInt(_color.substring(2, 4), 16); // hexToG
  const b = parseInt(_color.substring(4, 6), 16); // hexToB
  return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? "light" : "dark";
}
