import { type FeatureName } from "../../pages/configDev/features";
import { createStore } from "../../utils/createStore";
import {
  type ApplicationConfig,
  ApplicationConfigService
} from "../service/applicationConfig.service";
import { ErrorService } from "../service/errorService";

class AppConfigState {
  appConfig?: ApplicationConfig;
}
export const {
  appConfig,
  commit: commitAppConfig,
  dispatch: dispatchAppConfig,
  mapGetters: mapGettersAppConfig,
  mapState: mapStateAppConfig,
  useGetter: useAppConfigGetter,
  useState: useAppConfigState
} = createStore({
  moduleName: "appConfig",
  namespaced: false,
  initState: new AppConfigState(),
  mutations: {
    SET_APP_CONFIG(state, appConfig: ApplicationConfig) {
      state.appConfig = appConfig;
    }
  },
  actions: {
    async LOAD_APP_CONFIG() {
      try {
        commitAppConfig("SET_APP_CONFIG", await ApplicationConfigService.get());
      } catch (e) {
        ErrorService.handleError(e, false);
      }
    },
    async MARK_DIRTY() {
      try {
        const applicationCfg = await ApplicationConfigService.markDirty();
        commitAppConfig("SET_APP_CONFIG", applicationCfg);
      } catch (e) {
        ErrorService.handleError(e);
      }
    }
  },
  getters: {
    featureIsEnable:
      state =>
      (feature: FeatureName): boolean => {
        return state.appConfig?.features[feature] ?? false;
      },

    getTimezone: state => {
      return state.appConfig?.timezone ?? "Europe/Paris";
    },
    isDirty: state => {
      return state.appConfig?.isDirty ?? false;
    }
  }
});
