import axios from "axios";

export const SponsorsCategoriesService = {
  async getAll() {
    const { data } = await axios.get("/api/sponsorsCategories");
    return data;
  },

  async updateSponsorCategory(sponsorCategory) {
    return await axios.put(
      `/api/sponsorsCategories/${sponsorCategory._id}`,
      sponsorCategory
    );
  },

  async deleteSponsorCategory(id) {
    return await axios.delete(`/api/sponsorsCategories/${id}`);
  },

  async addSponsorCategory(sponsorCategory) {
    return await axios.post("/api/sponsorsCategories", sponsorCategory);
  },

  async getSponsorCategoryById(id) {
    const { data } = await axios.get(`/api/sponsorsCategories/${id}`);
    return data;
  },

  async moveSponsorCategory(id, upDown) {
    const direction = upDown === 1 ? "up" : "down";
    await axios.put(`/api/sponsorsCategories/${id}/${direction}`);
  }
};
