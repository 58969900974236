import { type ActionContext, type Module } from "vuex";

import { useSessionGetter } from "../../shared/store/session.store";
import { dispatchApplicationCfg } from "../applicationCfg/store/applicationCfg.store";
import { dispatchStoresInformations } from "../storesInformations/storesInformations.store";
import { SnackbarService } from "./../../shared/snackbar/snackbar.service";
import { type RootState } from "./../../store";
import { LoginService } from "./login.service";

class SessionState {}

function handleError() {
  SnackbarService.error("veuillez vérifier votre saisie");
}

export const login: Module<SessionState, RootState> = {
  namespaced: true,

  actions: {
    async login(
      _: ActionContext<SessionState, RootState>,
      { username, password }: { username: string; password: string }
    ) {
      try {
        const session = await LoginService.login(username, password);
        this.commit("SET_SESSION", session);
        // SessionService.fsIdentify(session.principal);
        dispatchApplicationCfg("loadApplicationCfg");
        dispatchStoresInformations("loadAppStore");
        if (!this.getters["warningBanner/getWarningBannerIsLoaded"]) {
          this.dispatch("warningBanner/loadWarningBanner");
        }
        if (useSessionGetter("isAdmin").value) {
          this.dispatch("CHECK_VERSION");
        }
      } catch (e) {
        handleError();
      }
    }
  }
};
