import { type RouteConfig } from "vue-router";

import store from "../../store";
import { dispatchApplicationCfg } from "../applicationCfg/store/applicationCfg.store";
import { dispatchFestival } from "../festival/festival.store";
import { dispatchStoresInformations } from "../storesInformations/storesInformations.store";

export const StaticCfgRouter: RouteConfig[] = [
  {
    path: "/staticCfgs",
    component: () => import("./staticCfgList.vue"),
    async beforeEnter(from, to, next) {
      await store.dispatch("staticCfgs/list");
      next();
    },
    children: []
  },
  {
    path: "/staticCfg/create",
    component: () => import("./staticCfg.vue"),
    beforeEnter: async (to, from, next) => {
      await dispatchApplicationCfg("loadApplicationCfg");
      await Promise.all([
        store.dispatch("staticConfig/createStaticConfig"),
        dispatchStoresInformations("loadAppStore"),
        dispatchFestival("loadFestival"),
        store.dispatch("programs/list"),
        store.dispatch("guestType/list")
      ]);
      next();
    }
  },
  {
    path: "/staticCfg/:id",
    component: () => import("./staticCfg.vue"),
    beforeEnter: async (to, from, next) => {
      await Promise.all([
        store.dispatch("staticConfig/loadStaticConfig", { oid: to.params.id }),
        dispatchStoresInformations("loadAppStore"),
        dispatchFestival("loadFestival"),
        dispatchApplicationCfg("loadApplicationCfg"),
        store.dispatch("programs/list"),
        store.dispatch("guestType/list")
      ]);
      next();
    }
  }
];
