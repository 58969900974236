import { keys } from "@/utils";

import { ApplicationConfigService } from "./applicationConfig.service";

export type Font = {
  id: string;
  name: string;
  postScriptName: string;
};
export type FallbackFont = {
  publicResourceName: string;
  fontName: string;
};

export type FontSet = { [fontName: string]: Font | undefined | null };
export type FallbackFontSet<Set extends FontSet> = Record<
  keyof Set,
  FallbackFont
>;

export type PreviewFontSet = {
  fontTitle?: Font | null;
  fontTextRegular?: Font | null;
  fontTextSemiBold?: Font | null;
  fontTextBold?: Font | null;
};
export const MobilePreviewFallbackFonts: FallbackFontSet<PreviewFontSet> = {
  fontTextBold: {
    publicResourceName: "font-default-text-bold.ttf",
    fontName: "fontTextBold"
  },
  fontTextRegular: {
    publicResourceName: "font-default-text-regular.ttf",
    fontName: "fontTextRegular"
  },
  fontTextSemiBold: {
    publicResourceName: "font-default-text-semibold.ttf",
    fontName: "fontTextSemiBold"
  },
  fontTitle: {
    publicResourceName: "font-default-title.ttf",
    fontName: "fontTitle"
  }
};

export type StaticPreviewFontSet = {
  fontText: Font | undefined;
  fontTitle: Font | undefined;
};
const StaticPreviewFallbackFonts: FallbackFontSet<StaticPreviewFontSet> = {
  fontText: {
    publicResourceName: "font-default-text-regular.ttf",
    fontName: "ChapitoText"
  }, // these two names are hard-coded in the static site
  fontTitle: {
    publicResourceName: "font-default-title.ttf",
    fontName: "ChapitoTitle"
  }
};

const state: {
  styleElement: HTMLStyleElement | undefined;
} = {
  styleElement: undefined
};

export const FontsService = {
  MobilePreviewFallbackFonts,
  StaticPreviewFallbackFonts,

  async createPreviewStylesFromAppConfig(): Promise<void> {
    const {
      fontTitle,
      fontTextBold,
      fontTextRegular,
      fontTextSemiBold
    }: PreviewFontSet = await ApplicationConfigService.get();
    const allFonts = {
      fontTitle,
      fontTextBold,
      fontTextRegular,
      fontTextSemiBold
    };
    this.createStylesForFontSet(allFonts, this.MobilePreviewFallbackFonts);
  },

  createStylesForFontSet<Fonts extends FontSet>(
    fonts: Fonts,
    fallbacks: FallbackFontSet<Fonts>
  ): void {
    if (!state.styleElement) {
      state.styleElement = document.createElement("style");
      document.head.appendChild(state.styleElement);
    }
    state.styleElement.innerHTML = keys(fonts)
      .map(fontName => {
        const font = fonts[fontName];
        const fallback = fallbacks[fontName];
        return font && font.id
          ? `@font-face { font-family: ${font.name}; src: url('/api/files/${font.id}') }\n`
          : `@font-face { font-family: ${fallback.fontName}; src: url('/api/public-files/${fallback.publicResourceName}') }\n`;
      })
      .join("");
  }
};
