import axios from "axios";

export const SessionService = {
  async getSession() {
    const { data } = await axios.get(`/api/session/current`);
    return data;
  },

  async logout() {
    await axios.post(`/api/logout`);
  }
};
