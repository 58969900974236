import { type PreviewGrabData } from "@/shared/types/applicationData.type";

import { type Lang, type StaticConfig } from "../staticCfg.types";
import { type StaticConfigState } from "./staticConfig.state";

export const StaticConfigMutations = {
  SET_CONFIG(state: StaticConfigState, config: StaticConfig): void {
    state.config = config;
  },

  SET_DATASETS(
    state: StaticConfigState,
    dataSets: Record<Lang, PreviewGrabData>
  ) {
    state.dataSets = dataSets;
  }
};
