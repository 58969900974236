import {
  type AdImageLinkWithFiles,
  type AdModeType,
  type ApplicationCfgWithAdsFiles
} from "@/pages/ads/ads.types";

export class AdsState {
  applicationCfg: ApplicationCfgWithAdsFiles | undefined = undefined;
  loading = false;
  isDrawerAdsPercentageValid = false;
  isSplashAdsPercentageValid = false;
  currentAdIndex: number | undefined = undefined;
  adMode: AdModeType | undefined = undefined;
  showDeleteDialog = false;
  showAdModal = false;
  splashAds: AdImageLinkWithFiles[] = [];
  drawerAds: AdImageLinkWithFiles[] = [];
  splashDuration: number = 2;
  currentAd: AdImageLinkWithFiles | undefined = undefined;
}

export const state = new AdsState();
