import { translatableText } from "@/shared/filters/translatableText.filter";

import { weightService } from "../../../shared/service/utils.service";

export function sortScenes(a, b) {
  if (a.weight === b.weight) {
    if (translatableText(a.names) < translatableText(b.names)) return -1;
    if (translatableText(a.names) > translatableText(b.names)) return 1;
    return 0;
  } else {
    return b.weight - a.weight;
  }
}

export const getters = {
  getScenes: state => {
    return Object.values(state.scenes);
  },

  getScene: state => {
    return state.editingSceneId
      ? state.scenes[state.editingSceneId]
      : {
          names: { fr: "" },
          color: "#FFFFFFFF",
          weight: 100,
          address: "",
          googleAddress: null,
          addressPlaceId: null,
          addressPlaceLatitude: null,
          addressPlaceLongitude: null,
          addressPlaceUrl: null
        };
  },

  getMinWeight: state => {
    return weightService.getMinWeight(state.scenes);
  },

  getIndex: state => {
    return sceneId => {
      return weightService.getIndex(state.scenes, sceneId, sortScenes);
    };
  },

  getSceneEvents: state => {
    return state.deletingSceneEvents;
  },

  sceneIsDeletable: state => {
    return state.deletingSceneEvents
      ? state.deletingSceneEvents.length === 0
      : true;
  },

  isMaxWeight: state => {
    return scene => {
      return weightService.isMaxWeight(state.scenes, scene, sortScenes);
    };
  },

  isMinWeight: (state, getters) => {
    return scene => {
      return scene.weight === getters.getMinWeight;
    };
  }
};
