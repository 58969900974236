import { type Route } from "vue-router";

import store from "../../store";
import { guestsCommit, guestsDispatch } from "./store/guests.store";

export const GuestsRouter = [
  {
    path: "/guests",
    component: () => import("./Guests.vue"),
    beforeEnter: async (to: Route, from: Route, next: any) => {
      if (!from.path.startsWith("/guests")) {
        guestsCommit("RESET_PAGINATION");
      }
      await store.dispatch("guestType/list");
      next();
    },
    children: []
  },
  {
    path: "/guests/create",
    component: () => import("./AddingGuests.vue"),
    beforeEnter: async (to: Route, from: Route, next: any) => {
      await guestsDispatch("getGuestsLinks");
      await store.dispatch("guestType/list");
      await store.dispatch("scenes/list");
      await store.dispatch("programs/list");
      next();
    }
  },
  {
    path: "/guests/import",
    component: () => import("./GuestImport.vue"),
    beforeEnter: async (to: Route, from: Route, next: any) => {
      await guestsDispatch("resetImport");
      await store.dispatch("guestType/list");
      next();
    }
  },
  {
    path: "/guests/:id",
    component: () => import("./AddingGuests.vue"),
    beforeEnter: async (to: Route, from: Route, next: any) => {
      await guestsDispatch("loadGuest", to.params.id);
      await store.dispatch("guestType/list");
      await store.dispatch("scenes/list");
      await store.dispatch("programs/list");
      next();
    }
  }
];
