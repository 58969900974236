export type Lang = "fr" | "en";

export type TranslatableText = {
  [key in Lang]: string;
};

export function makeTranslatableText(fr: string = "", en: string = "") {
  return {
    fr,
    en
  };
}
