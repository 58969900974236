import { type Route } from "vue-router";

import store from "../../store";

export const GenresRouter = [
  {
    path: "/genres",
    component: () => import("./Genres.vue"),
    beforeEnter: async (to: Route, from: Route, next: any) => {
      await store.dispatch("guests/genres/getGenres");
      next();
    },
    children: []
  }
];
