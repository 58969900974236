import { cloneDeep } from "lodash";

import { type GuestType } from "@/pages/guestTypes/GuestType.model";
import { type GuestTypeState } from "@/pages/guestTypes/store/guestType.state";
import { PAGE_MODE } from "@/shared/constants";

export const mutations = {
  LOAD(state: GuestTypeState) {
    state.loading = true;
  },

  SET_DELETING(state: GuestTypeState, payload: GuestType) {
    state.mode = PAGE_MODE.DELETE;
    state.currentGuestType = { ...payload };
  },

  SET_VIEW(state: GuestTypeState) {
    state.mode = PAGE_MODE.VIEW;
    state.currentGuestType = null;
    state.guestNames = [];
    state.loading = false;
  },

  SET_GUEST_TYPES(state: GuestTypeState, guestTypes: GuestType[]) {
    state.guestTypes = guestTypes.reduce(
      (acc: { [key: string]: GuestType }, current) => {
        acc[current._id] = current;
        return acc;
      },
      {}
    );
    state.loading = false;
  },

  SET_ADDING(state: GuestTypeState) {
    state.mode = PAGE_MODE.ADD;
  },

  SET_EDITING(state: GuestTypeState, payload: GuestType) {
    state.mode = PAGE_MODE.EDIT;
    state.currentGuestType = cloneDeep(payload);
  },

  SET_GUEST_NAMES(state: GuestTypeState, names: string[]) {
    state.guestNames = names;
    state.loading = false;
  }
};
