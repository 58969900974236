import { type SponsorsState } from "@/pages/sponsors/store/sponsors.state";

export const getters = {
  getSponsors: (state: SponsorsState) => {
    return Object.values(state.sponsors);
  },

  getSponsor: (state: SponsorsState) => {
    return state.editingSponsor
      ? state.editingSponsor
      : {
          names: { fr: "" },
          category: "",
          url: "",
          image: null
        };
  }
};
