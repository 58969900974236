<template>
  <div v-if="!!message">
    <v-alert :dismissible="true" type="error">
      <v-row align="center" justify="center">
        <v-col sm="6">
          <v-card-text>
            <span v-html="message" />
          </v-card-text>
        </v-col>
      </v-row>
    </v-alert>
  </div>
</template>
<script lang="ts">
import Vue from "vue";
import { mapState } from "vuex";

export default Vue.extend({
  computed: {
    ...mapState("warningBanner", {
      message: "message"
    })
  }
});
</script>
<style lang="scss" scoped></style>
