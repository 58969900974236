import { type RouteConfig } from "vue-router";

import store from "../../store";
import { dispatchFestival } from "../festival/festival.store";
import { progEventDispatch } from "./progEvent.store";

export const ProgEventRouter: RouteConfig[] = [
  {
    path: "/programmation",
    component: () => import("./Programmation.vue"),

    beforeEnter: async (_to, _from, next) => {
      await Promise.all([
        progEventDispatch("list"),
        dispatchFestival("loadFestival"),
        store.dispatch("scenes/list"),
        store.dispatch("programs/list")
      ]);
      next();
    }
  },
  {
    path: "/programmation/create",
    component: () => import("./AddingProgEvent.vue")
  },
  {
    path: "/programmation/import",
    component: () => import("./EventsImport.vue")
  },
  {
    path: "/programmation/:id",
    component: () => import("./AddingProgEvent.vue"),
    beforeEnter: async (to, _from, next) => {
      await progEventDispatch("loadProgEvent", {
        id: to.params.id,
        loadType: "edit"
      });
      next();
    }
  },
  {
    path: "/programmation/duplicate/:id",
    component: () => import("./AddingProgEvent.vue"),
    beforeEnter: async (to, _from, next) => {
      await progEventDispatch("loadProgEvent", {
        id: to.params.id,
        loadType: "duplicate"
      });
      next();
    }
  }
];
