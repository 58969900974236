import { type PreviewGrabData } from "@/shared/types/applicationData.type";

import { type Lang, type StaticConfig } from "../staticCfg.types";
import { type StaticConfigState } from "./staticConfig.state";

export const StaticConfigGetters = {
  getStaticConfig: (state: StaticConfigState): StaticConfig | undefined => {
    return state.config;
  },

  getDataSets: (
    state: StaticConfigState
  ): Record<Lang, PreviewGrabData | undefined> => {
    return state.dataSets;
  }
};
