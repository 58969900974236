import { type Module } from "vuex";

import { type RootState } from "@/store";

import { actions } from "./import.actions";
import { getters } from "./import.getters";
import { mutations } from "./import.mutations";
import { state, type StateImport } from "./import.state";

export const imports: Module<StateImport, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
