import axios from "axios";

import { i18n } from "@/i18n/i18n";
import {
  type ExportUrl,
  type ImportStatus
} from "@/pages/import/store/import.type";
import { ErrorService } from "@/shared/service/errorService";
import { SnackbarService } from "@/shared/snackbar/snackbar.service";

async function updloadFile(file: any, url: string): Promise<void> {
  const data = new FormData();
  data.append("file", file);
  return await axios
    .post(url, data)
    .then(_ => {
      SnackbarService.info(i18n.t("IMPORT.SYNC_STARTED").toString());
    })
    .catch(err => {
      ErrorService.handleError(err);
    });
}

export const ImportService = {
  async uploadArtist(file: any): Promise<void> {
    return await updloadFile(file, "/api/bridging/heed/data");
  },

  async uploadScene(file: any): Promise<void> {
    return await updloadFile(file, "/api/bridging/gc/scenes");
  },

  async uploadEvent(file: any): Promise<void> {
    return await updloadFile(file, "/api/bridging/gc/events");
  },

  async uploadHeeds(file: any): Promise<void> {
    return await updloadFile(file, "/api/bridging/heeds");
  },

  async syncHeeds(clean: boolean): Promise<void> {
    return await axios
      .post("/api/bridging/heeds/sync", { clean })
      .then(_ => {
        SnackbarService.info(i18n.t("IMPORT.SYNC_STARTED").toString());
      })
      .catch(err => {
        ErrorService.handleError(err);
      });
  },

  async sync(urls: ExportUrl[], clean: boolean): Promise<void> {
    return await axios
      .post("/api/bridging/gc/urls", { urls, clean }, { timeout: 240000 })
      .then(_ => {
        SnackbarService.info(i18n.t("IMPORT.SYNC_SUCCESS").toString());
      })
      .catch(err => {
        ErrorService.handleError(err);
      });
  },

  async getLastImportStatus(): Promise<ImportStatus> {
    const { data } = await axios.get("/api/bridging/status");
    return data;
  }
};
