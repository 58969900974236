import { type StateImport } from "@/pages/import/store/import.state";
import {
  type FestivalImportUrl,
  type ImportStatus
} from "@/pages/import/store/import.type";

export const mutations = {
  LOAD(state: StateImport) {
    state.loading = true;
    state.disabled = true;
  },
  UNLOAD(state: StateImport) {
    state.loading = false;
  },
  ENABLE(state: StateImport) {
    state.disabled = false;
  },
  SET_IMPORT_STATUS(state: StateImport, payload: ImportStatus) {
    state.lastImportStatus = payload;
  },
  SET_SYNC_URL(state: StateImport, payload: FestivalImportUrl) {
    if (!payload.gcExportUrls) {
      payload.gcExportUrls = [];
    }

    if (payload.gcExportUrls.length < 3) {
      const artist = payload.gcExportUrls.find(
        value => value.type === "ARTIST"
      );
      const scene = payload.gcExportUrls.find(value => value.type === "SCENE");
      const event = payload.gcExportUrls.find(value => value.type === "EVENT");
      state.syncUrls = [
        { type: "ARTIST", url: artist ? artist.url : "" },
        { type: "SCENE", url: scene ? scene.url : "" },
        { type: "EVENT", url: event ? event.url : "" }
      ];
    } else {
      state.syncUrls = payload.gcExportUrls;
    }
  }
};
