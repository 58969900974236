import axios from "axios";

export const UsersService = {
  async getAll() {
    const { data } = await axios.get("/api/users");
    return data;
  },

  async deleteUser(id) {
    return await axios.delete(`/api/users/${id}`);
  },

  async addUser(user) {
    return await axios.post("/api/users", user);
  },

  async updateUser(user) {
    return await axios.put(`/api/users/${user._id}`, user);
  },

  async resetPassword(id) {
    return await axios.post(`/api/users/resetPassword/${id}`);
  },

  async getUserById(id) {
    const { data } = await axios.get(`/api/users/${id}`);
    return data;
  }
};
