import { type Module } from "vuex";

import { type RootState } from "@/store";

import { actions } from "./map.actions";
import { getters } from "./map.getters";
import { mutations } from "./map.mutations";
import { StateMap } from "./map.state";

export const map: Module<StateMap, RootState> = {
  namespaced: true,
  state: new StateMap(),
  mutations,
  actions,
  getters
};
