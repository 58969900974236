import axios from "axios";

export type RedirectPage = {
  androidCounter: number;
  iosCounter: number;
};

export const RedirectPageService = {
  async getRedirectPage(): Promise<RedirectPage> {
    const { data } = await axios.get<RedirectPage>("/api/redirect/counter");
    return data;
  }
};
