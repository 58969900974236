import {
  type Coords,
  type MapCopy,
  type MapEntityChoicesByType,
  type MapObject,
  type MarkerIcon,
  type POI,
  type ZOI
} from "../map.type";

export enum MapModalStates {
  DELETE = "DELETE",
  CHANGE = "CHANGE",
  HIDE = "HIDE"
}

export class StateMap {
  zoiList: { [key: string]: ZOI } = {};

  map: MapObject | null = null;
  mapCopy: MapCopy = {
    corners: [],
    imageOpacity: 100,
    mapOverlay: null,
    markerIcons: {},
    poiList: {},
    zoiList: {}
  };
  loading: boolean = false;
  satelliteView: boolean = false;

  //actions bar
  addingPoi: boolean = false;
  addingZoi: boolean = false;
  addingLayer: boolean = false;
  importGeoJson: boolean = false;

  //markers
  markerIcons: { [key: string]: MarkerIcon } = {};
  editingMarkerId: string = "";
  pic: string = "";
  picCrop: string = "";

  //layer
  mapOverlay: string | null = null;
  corners: Coords[] = [];
  resetting: boolean = false;
  showLayerModal: MapModalStates = MapModalStates.HIDE;
  imageOpacity: number = 100;

  //import
  showImportGeoJsonModal: MapModalStates = MapModalStates.HIDE;
  importGeoJsonResult: GeoJsonImportSummary | null = null;

  preparedMap: MapObject | null = null;

  mapFocus: string = "";

  newIcon: string = "";

  latLength: number = 0;
  lngLength: number = 0;

  //pois
  poiList: { [key: string]: POI } = {};

  //deleting modal
  type: "ZOI" | "POI" | "MARKER" | "" = "";
  deletingId: string = "";

  entityIdChoicesByType: MapEntityChoicesByType = {
    NONE: undefined,
    GUEST: undefined,
    SCENE: undefined
  };
}
