import cloneDeep from "lodash/cloneDeep";

export const mutations = {
  SET_USERS(state, users) {
    state.users = users.reduce((acc, user) => {
      acc[user._id] = user;
      return acc;
    }, {});
  },

  LOAD(state) {
    state.loading = true;
  },

  UNLOAD(state) {
    state.loading = false;
  },

  SET_SEARCH(state, val) {
    state.search = val;
  },

  RESET(state) {
    state.search = "";
    state.deletingUserId = null;
    state.editingUser = null;
    state.loading = false;
  },

  SET_DELETING(state, id) {
    state.deletingUserId = id;
  },

  SET_NOT_DELETING(state) {
    state.deletingUserId = null;
  },

  SET_EDITING(state, user) {
    state.editingUser = cloneDeep(user);
  }
};
