import { ErrorService } from "@/shared/service/errorService";

import { i18n } from "../../../i18n/i18n";
import { SnackbarService } from "../../../shared/snackbar/snackbar.service";
import { NotificationsTopicsService } from "../notificationsTopics.service";

export const actions = {
  async list({ commit }) {
    commit("LOAD");
    const notificationsTopics = await NotificationsTopicsService.getAll();
    commit("SET_NOTIF_TOPICS", notificationsTopics);
  },

  async delete({ commit, state, dispatch }) {
    commit("LOAD");
    try {
      if (
        this.getters["notifications/getNotifList"].some(element => {
          return element.topicId === state.deleteNotifTopic._id;
        })
      ) {
        commit("SET_DELETED");
        await NotificationsTopicsService.editNotifTopic(
          state.deleteNotifTopic._id,
          state.deleteNotifTopic
        );
      } else {
        await NotificationsTopicsService.deleteNotifTopic(
          state.deleteNotifTopic._id
        );
      }
      await dispatch("list");
      SnackbarService.info(
        i18n.t("NOTIFICATIONS_TOPICS.SNACK_BAR.DELETE", {
          string: state.deleteNotifTopic.names["fr"]
        })
      );
    } catch (e) {
      ErrorService.handleError(e);
    } finally {
      commit("SET_VIEW");
    }
  },

  async addNotifTopic({ state, commit, dispatch }, topic) {
    commit("LOAD");
    try {
      if (state.editNotifTopic == null) {
        await NotificationsTopicsService.addNotifTopic(topic);
        SnackbarService.info(
          i18n.t("NOTIFICATIONS_TOPICS.SNACK_BAR.ADD", {
            string: topic.names["fr"]
          })
        );
      } else {
        await NotificationsTopicsService.editNotifTopic(
          state.editNotifTopic._id,
          topic
        );
        SnackbarService.info(
          i18n.t("NOTIFICATIONS_TOPICS.SNACK_BAR.EDIT", {
            string: topic.names["fr"]
          })
        );
      }
      await dispatch("list");
    } catch (e) {
      ErrorService.handleError(e);
    } finally {
      commit("SET_VIEW");
    }
  }
};
