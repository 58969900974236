import { PAGE_MODE } from "../../../shared/constants";

export const mutations = {
  LOAD(state) {
    state.loading = true;
  },

  SET_NOTIF_TOPICS(state, notifTopics) {
    state.notifTopics = notifTopics;
    state.loading = false;
  },

  SET_DELETING(state, payload) {
    state.mode = PAGE_MODE.DELETE;
    state.deleteNotifTopic = { ...payload };
  },

  SET_VIEW(state) {
    state.mode = PAGE_MODE.VIEW;
    state.deleteNotifTopic = null;
    state.editNotifTopic = null;
    state.loading = false;
  },

  SET_ADDING(state) {
    state.mode = PAGE_MODE.ADD;
    state.loading = false;
  },

  SET_EDITING(state, notifTopic) {
    state.editNotifTopic = notifTopic;
    state.mode = PAGE_MODE.EDIT;
    state.loading = false;
  },

  SET_DELETED(state) {
    state.deleteNotifTopic.deleted = true;
  }
};
