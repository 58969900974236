import axios from "axios";

import {
  type MenuChoice,
  type NavBarContent
} from "@/pages/menuEntries/MenuEntry.type";

export const NavbarContentService = {
  async getNavbarContent(): Promise<NavBarContent> {
    const { data } = await axios.get("/api/navbarContent");
    return data;
  },

  async save(menuContent: NavBarContent): Promise<NavBarContent> {
    const { data } = await axios.post("/api/navbarContent", menuContent);
    return data;
  },

  async getChoices(): Promise<MenuChoice[]> {
    const { data } = await axios.get("/api/navbarContent/choices");
    return data;
  },

  async getAllSecrets(): Promise<string[]> {
    const { data } = await axios.get("/api/navbarContent/secrets");
    return data;
  },

  async removeSecret(secret: string): Promise<void> {
    await axios.delete(`/api/navbarContent/secrets/${secret}`);
  }
};
