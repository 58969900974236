export const getters = {
  getSponsorsCategories: state => {
    return Object.values(state.sponsorsCategories);
  },

  getSponsorCategory: state => {
    return state.editingSponsorCategoryId
      ? state.sponsorsCategories[state.editingSponsorCategoryId]
      : {
          name: ""
        };
  },

  getFilteredCategories: state => {
    const categoriesTab = [
      ...Object.values(state.sponsorsCategories),
      ...Object.values(state.addedCategories)
    ];

    return state.categoriesSearch && state.categoriesSearch.length > 0
      ? [
          ...categoriesTab,
          {
            name: state.categoriesSearch,
            nameNormalized: state.categoriesSearch.toLowerCase(),
            _id: null
          }
        ]
      : categoriesTab;
  }
};
