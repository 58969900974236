import { type ScreenTableDateDto } from "@/pages/analytics/models/analytics.type";
import { type AdvertisementDto } from "@/pages/analytics/models/analytics-advertisement.type";
import { type AnalyticsNotification } from "@/pages/analytics/models/analytics-notification.type";
import {
  type UserCountryDto,
  type UserSectionDashboardDto,
  type UserSessionPeriodDto
} from "@/pages/analytics/models/analytics-user.type";
import {
  type DashboardMatomoState,
  type Period,
  STAT_REQUEST_ERROR,
  STAT_REQUEST_LOADING,
  type StatName
} from "@/pages/analytics/store/dashboardAnalytics.store";

export const mutations = {
  SET_USER_METRICS(state: DashboardMatomoState, data: Record<string, number>) {
    state.userMetrics = new Map(Object.entries(data));
  },

  SET_USER_SECTION_DASHBOARD_METRICS(
    state: DashboardMatomoState,
    data: UserSectionDashboardDto
  ) {
    state.userSectionDashboardMetrics = data;
  },

  SET_USER_COUNTRY_METRICS(
    state: DashboardMatomoState,
    data: UserCountryDto[]
  ) {
    state.userCountryMetrics = data;
  },

  SET_USER_SESSION_METRICS(
    state: DashboardMatomoState,
    data: Record<string, UserSessionPeriodDto[]>
  ) {
    state.userSessionMetrics = new Map(Object.entries(data));
  },

  SET_SCHEDULES_METRICS(
    state: DashboardMatomoState,
    data: ScreenTableDateDto[]
  ) {
    state.schedulesMetrics = data;
  },

  SET_EVENT_METRICS(state: DashboardMatomoState, data: ScreenTableDateDto[]) {
    state.eventMetrics = data;
  },

  SET_FAVORITE_EVENT_METRICS(
    state: DashboardMatomoState,
    data: ScreenTableDateDto[]
  ) {
    state.favoriteEventMetrics = data;
  },

  SET_COMMENTED_EVENT_METRICS(
    state: DashboardMatomoState,
    data: ScreenTableDateDto[]
  ) {
    state.commentedEventMetrics = data;
  },

  SET_RATED_EVENT_METRICS(
    state: DashboardMatomoState,
    data: ScreenTableDateDto[]
  ) {
    state.ratedEventMetrics = data;
  },

  SET_PARTICIPATED_EVENT_METRICS(
    state: DashboardMatomoState,
    data: ScreenTableDateDto[]
  ) {
    state.participatedEventMetrics = data;
  },

  SET_GUESTS_METRICS(
    state: DashboardMatomoState,
    data: Record<string, ScreenTableDateDto[]>
  ) {
    state.guestMetrics = new Map(Object.entries(data));
  },

  SET_FAVORITE_GUEST_METRICS(
    state: DashboardMatomoState,
    data: Record<string, ScreenTableDateDto[]>
  ) {
    state.favoriteGuestMetrics = new Map(Object.entries(data));
  },

  SET_GUEST_LIST_METRICS(
    state: DashboardMatomoState,
    data: ScreenTableDateDto[]
  ) {
    state.guestListMetrics = data;
  },

  SET_GALLERY_METRICS(state: DashboardMatomoState, data: ScreenTableDateDto[]) {
    state.galleryMetrics = data;
  },

  SET_INFO_PRATIQUE_METRICS(
    state: DashboardMatomoState,
    data: ScreenTableDateDto[]
  ) {
    state.infoPratiqueMetrics = data;
  },

  SET_WEBVIEW_METRICS(state: DashboardMatomoState, data: ScreenTableDateDto[]) {
    state.webviewMetrics = data;
  },

  SET_ADVERTISEMENT_METRICS(
    state: DashboardMatomoState,
    data: AdvertisementDto
  ) {
    state.advertisementMetrics = data;
  },

  SET_NOTIFICATION_METRICS(
    state: DashboardMatomoState,
    data: AnalyticsNotification[]
  ) {
    state.notificationMetrics = data;
  },

  SET_OTHER_SCREEN_METRICS(
    state: DashboardMatomoState,
    data: ScreenTableDateDto[]
  ) {
    state.otherScreenMetrics = data;
  },

  SET_FILTER_DATES_MODEL(state: DashboardMatomoState, data: Period) {
    localStorage.setItem("ANALYTICS_PERIOD", data.id);
    state.filterDatesModel = data;
  },

  SET_STAT_LOADING(state: DashboardMatomoState, stat: StatName) {
    state[stat] = STAT_REQUEST_LOADING;
  },

  SET_STAT_ERROR(state: DashboardMatomoState, stat: StatName) {
    state[stat] = STAT_REQUEST_ERROR;
  }
};
