import cloneDeep from "lodash/cloneDeep";
import groupBy from "lodash/groupBy";

import { i18n } from "@/i18n/i18n";
import { AttendeesService } from "@/pages/attendee/attendees.service";
import { GuestsService } from "@/pages/guests/guests.service";
import router from "@/router";
import { translatableText } from "@/shared/filters/translatableText.filter";
import { ErrorService } from "@/shared/service/errorService";
import {
  FilesService,
  type ImageTransformations
} from "@/shared/service/files.service";
import { SnackbarService } from "@/shared/snackbar/snackbar.service";
import {
  type Attendee,
  type AttendeeWithRateAndComment
} from "@/shared/types/attendee.type";
import { type Pagination } from "@/shared/types/pagination.type";
import { type ProgEvent } from "@/shared/types/progEvent.type";
import { type Program } from "@/shared/types/program.type";
import { type Scene } from "@/shared/types/scene.type";

import store from "../../store";
import { createStore } from "../../utils/createStore";
import { ProgEventService, type ProgramEventUsage } from "./progEvent.service";

export type ImportResult = {
  successes: ImportResultMessage[];
  errors: ImportResultMessage[];
  toProcess: ImportResultMessage[];
};
export type ImportResultMessage = {
  key: string;
  content: Record<string, unknown>;
};

export class ProgEventState {
  showMultipleDeleting: boolean = false;
  showMultipleEdit: boolean = false;
  progEventList: { [key: string]: ProgEvent } = {};
  loading = false;
  deletingEventId: null | string = null;
  gridMode = false;

  selectedEventIdForDetails = "";
  attendeesList: AttendeeWithRateAndComment[] = [];

  options: Pagination = {} as Pagination;

  search = {
    guest: "",
    program: "",
    scene: "",
    day: ""
  };
  selectedProgram: Program | null = null;
  editingProgEvent: ProgEvent | null = null;
  selectedRows: ProgEvent[] = [];
  image: any = null;
  transformations: ImageTransformations | null = null;

  importBatchId: string = "";
  importFeedback: ImportResult = {
    successes: [],
    errors: [],
    toProcess: []
  };

  togglingVisibilityEventId: null | string = null;
  showVisibilityModal = false;
  batchShowMode = null as null | boolean;
  progEventUsage: ProgramEventUsage = {
    pageContents: []
  };
}

const state = new ProgEventState();

export const {
  commit: progEventCommit,
  dispatch: progEventDispatch,
  mapGetters: progEventMapGetters,
  mapState: progEventMapState,
  useGetter: useProgEventGetter,
  useState: useProgEventState,
  progEvent
} = createStore({
  moduleName: "progEvent",
  initState: state,
  mutations: {
    SET_PROG_EVENT_LIST(state: ProgEventState, progEventList: ProgEvent[]) {
      state.progEventList = progEventList.reduce(
        (acc: { [key: string]: ProgEvent }, progEvent) => {
          acc[progEvent._id] = progEvent;
          return acc;
        },
        {}
      );
    },

    LOAD(state: ProgEventState) {
      state.loading = true;
    },

    UNLOAD(state: ProgEventState) {
      state.loading = false;
    },

    SET_SEARCH_GUEST(state: ProgEventState, guest: string) {
      state.search.guest = guest;
      state.options.page = 1;
    },

    SET_SEARCH_PROGRAM(state: ProgEventState, program: string) {
      state.search.program = program;
      state.options.page = 1;
    },

    SET_SEARCH_SCENE(state: ProgEventState, scene: string) {
      state.search.scene = scene;
      state.options.page = 1;
    },

    SET_SEARCH_DAY(state: ProgEventState, day: string) {
      state.search.day = day;
      state.options.page = 1;
    },

    SET_DELETING(state: ProgEventState, progEventId: string) {
      state.deletingEventId = progEventId;
    },

    SET_NOT_DELETING(state: ProgEventState) {
      state.deletingEventId = null as unknown as string;
    },

    SET_SHOW_MULTIPLE_DELETING(state: ProgEventState, show: boolean) {
      state.showMultipleDeleting = show;
    },

    SET_SHOW_MULTIPLE_EDITING(state: ProgEventState, show: boolean) {
      state.showMultipleEdit = show;
    },

    RESET_MULTI_SELECTION(state: ProgEventState) {
      state.selectedRows = [];
    },

    CHANGE_DISPLAY(state: ProgEventState) {
      state.gridMode = !state.gridMode;
    },

    SET_PROGRAM(state: ProgEventState, program: Program) {
      state.selectedProgram = program;
    },

    SET_IMAGE(state: ProgEventState, fileName: string) {
      state.image = fileName;
    },

    SET_IMAGE_TRANSFORMATIONS(
      state: ProgEventState,
      transformations: ImageTransformations
    ) {
      state.transformations = transformations;
    },

    RESET(state: ProgEventState) {
      state.image = null;
      state.editingProgEvent = null as unknown as ProgEvent;
      (this as any).commit("guests/genres/RESET");
    },

    SET_EDITING(state: ProgEventState, progEvent: ProgEvent) {
      state.editingProgEvent = progEvent;
      state.image = progEvent.image;
    },

    SET_DUPLICATE(state: ProgEventState, progEvent: ProgEvent) {
      progEvent._id = "";
      state.editingProgEvent = progEvent;
    },

    SET_OPTIONS(state: ProgEventState, options: Pagination) {
      state.options = options;
    },

    SET_SELECTED_ROWS(state: ProgEventState, progEvents: ProgEvent[]) {
      state.selectedRows = progEvents;
    },

    //Attending

    SET_EVENT_ID_FOR_DETAILS(state: ProgEventState, id: string) {
      state.selectedEventIdForDetails = id;
    },

    RESET_EVENT_ID_FOR_DETAILS(state: ProgEventState) {
      state.selectedEventIdForDetails = "";
      state.attendeesList = [];
    },

    SET_ATTENDEES_LIST(state: ProgEventState, attendeesList: Attendee[]) {
      state.attendeesList = attendeesList.map(attendee => {
        const tmp = state.progEventList[
          state.selectedEventIdForDetails
        ].eventAttendeesList.filter(
          a => a.firebaseUserId === attendee.firebaseUserId
        );
        const newAttendee: AttendeeWithRateAndComment = {
          ...attendee
        } as AttendeeWithRateAndComment;
        newAttendee.comment = tmp.length > 0 ? tmp[0].comment : "";
        newAttendee.rate = tmp.length > 0 ? tmp[0].rate : -1;
        return newAttendee;
      });
    },

    SET_IMPORT_FEEDBACK(
      state: ProgEventState,
      importResult: ProgEventState["importFeedback"]
    ) {
      state.importFeedback = importResult;
    },

    SET_IMPORT_BATCH_ID(state: ProgEventState, importBatchId: string) {
      state.importBatchId = importBatchId;
    },
    CLOSE_VISIBILITY_MODAL(state: ProgEventState) {
      state.showVisibilityModal = false;
      state.togglingVisibilityEventId = null;
      state.progEventUsage = {
        pageContents: []
      };
    }
  },
  actions: {
    async list() {
      progEventCommit("LOAD");
      try {
        const progEventList = await ProgEventService.getAll();
        progEventCommit("SET_PROG_EVENT_LIST", progEventList);
      } catch (e) {
        ErrorService.handleError(e);
      } finally {
        progEventCommit("UNLOAD");
      }
    },

    async initHide({ state }, programEventId: string) {
      // fetch usage only when hidding the event
      const usage = state.progEventList[programEventId].isVisible
        ? await ProgEventService.checkUsage([programEventId])
        : {
            pageContents: []
          };
      // if used somewhere, show confirmation dialog
      if (usage.pageContents.length > 0) {
        state.togglingVisibilityEventId = programEventId;
        state.progEventUsage = usage;
        state.showVisibilityModal = true;
      } else {
        // else hide directly
        await progEventDispatch("hide", programEventId);
      }
    },

    async hide({ state }, programEventId: string) {
      progEventCommit("LOAD");
      try {
        const programEvent = cloneDeep(state.progEventList[programEventId]);
        programEvent.isVisible = !programEvent.isVisible;
        await ProgEventService.updateProgramEvent(programEvent);
        const message = programEvent.isVisible
          ? i18n
              .t("PROGRAMMATION.SNACK_BAR.HIDE_OFF", {
                string: programEvent.titles?.fr || programEvent.fakeTitle
              })
              .toString()
          : i18n
              .t("PROGRAMMATION.SNACK_BAR.HIDE", {
                string: programEvent.titles?.fr || programEvent.fakeTitle
              })
              .toString();
        SnackbarService.info(message);
        await progEventDispatch("list");
      } catch (e) {
        ErrorService.handleError(e);
      } finally {
        progEventCommit("CLOSE_VISIBILITY_MODAL");
      }
    },

    async delete({ state }) {
      progEventCommit("LOAD");
      try {
        if (!state.deletingEventId) return;
        await ProgEventService.deleteProgramEvent(state.deletingEventId);
        SnackbarService.info(
          i18n
            .t("PROGRAMMATION.SNACK_BAR.DELETE", {
              string:
                state.progEventList[state.deletingEventId].titles?.fr ||
                state.progEventList[state.deletingEventId].fakeTitle
            })
            .toString()
        );
        await progEventDispatch("list");
        progEventCommit("SET_NOT_DELETING");
      } catch (e) {
        ErrorService.handleError(e);
      }
    },

    async uploadImage(_, file: any) {
      progEventCommit("LOAD");
      try {
        const savedImage = await FilesService.uploadImageFile(file);
        progEventCommit("SET_IMAGE", savedImage.filename);
      } catch (e) {
        ErrorService.handleError(e);
      } finally {
        progEventCommit("UNLOAD");
      }
    },

    async initHideShowBatch({ state }, show: boolean) {
      // fetch usage only when hidding the event
      const usage = !show
        ? await ProgEventService.checkUsage(state.selectedRows.map(e => e._id))
        : {
            pageContents: []
          };
      // if used somewhere, show confirmation dialog
      if (usage.pageContents.length > 0) {
        state.progEventUsage = usage;
        state.showVisibilityModal = true;
        state.batchShowMode = show;
      } else {
        // else hide directly
        await progEventDispatch("hideShowBatch", show);
      }
    },

    async hideShowBatch({ state }, show: boolean) {
      progEventCommit("LOAD");
      try {
        const nb = state.selectedRows.length;
        await ProgEventService.hideShowBatch(
          state.selectedRows.map(e => e._id),
          show
        );
        await progEventDispatch("list");
        show
          ? SnackbarService.info(
              i18n
                .t("PROGRAMMATION.SNACK_BAR.BATCH_SHOW", { string: nb })
                .toString()
            )
          : SnackbarService.info(
              i18n
                .t("PROGRAMMATION.SNACK_BAR.BATCH_HIDE", { string: nb })
                .toString()
            );
      } catch (e) {
        ErrorService.handleError(e);
      } finally {
        progEventCommit("RESET_MULTI_SELECTION");
        progEventCommit("UNLOAD");
        progEventCommit("CLOSE_VISIBILITY_MODAL");
      }
    },
    async editShowBatch(
      { state },
      { programId, sceneId }: { programId?: string; sceneId?: string }
    ) {
      progEventCommit("LOAD");
      ("LOAD");
      try {
        const nb = state.selectedRows.length;
        await ProgEventService.editShowBatch(
          state.selectedRows.map(e => e._id),
          programId,
          sceneId
        );
        await progEventDispatch("list");
        SnackbarService.info(
          i18n
            .t("PROGRAMMATION.SNACK_BAR.BATCH_EDIT", { string: nb })
            .toString()
        );
      } catch (e) {
        ErrorService.handleError(e);
      } finally {
        progEventCommit("RESET_MULTI_SELECTION");
        progEventCommit("UNLOAD");
      }
    },
    async multipleDelete({ state }) {
      progEventCommit("LOAD");
      try {
        const nb = state.selectedRows.length;
        await ProgEventService.multipleDelete(
          state.selectedRows.map(e => e._id)
        );
        await progEventDispatch("list");
        SnackbarService.info(
          i18n
            .t("PROGRAMMATION.SNACK_BAR.MULTIPLE_DELETE", { string: nb })
            .toString()
        );
      } catch (e) {
        ErrorService.handleError(e);
      } finally {
        progEventCommit("SET_SHOW_MULTIPLE_DELETING", false);
        progEventCommit("RESET_MULTI_SELECTION");
      }
    },
    async addProgEvent({ state, rootGetters }, progEvent: ProgEvent) {
      progEventCommit("LOAD");
      try {
        const event = cloneDeep(progEvent);
        event.image = state.image;
        event.genres = rootGetters["guests/genres/getSelectedGenres"];
        if (event.musicGroups) {
          event.musicGroups = await Promise.all(
            event.musicGroups.map(async group => {
              return await GuestsService.getGuestById(group._id);
            })
          );
        }

        let message = "";
        if (state.editingProgEvent) {
          await ProgEventService.updateProgramEvent(event);
          message = i18n
            .t("PROGRAMMATION.SNACK_BAR.EDIT", {
              string: translatableText(event.titles)
            })
            .toString();
        } else {
          await ProgEventService.addProgramEvent(event);
          message = i18n
            .t("PROGRAMMATION.SNACK_BAR.ADD", {
              string: translatableText(event.titles)
            })
            .toString();
        }

        if (state.transformations) {
          await FilesService.setImageTransform(
            state.transformations.imageId,
            state.transformations
          );
        }
        SnackbarService.info(message);
        await progEventDispatch("list");
        router.push("/programmation");
      } catch (e) {
        ErrorService.handleError(e);
        progEventCommit("UNLOAD");
      }
    },

    async loadProgEvent(
      { rootGetters },
      data: { id: string; loadType: "edit" | "duplicate" }
    ) {
      progEventCommit("LOAD");
      try {
        await this.dispatch("guests/genres/getGenres");
        const progEvent = await ProgEventService.getProgramEventById(data.id);

        progEvent.genres = progEvent.genres
          ? progEvent.genres
              .map((genre: string) =>
                rootGetters["guests/genres/getGenreById"](genre)
              )
              .filter((n: any) => !!n)
          : [];
        progEventCommit(
          data.loadType === "edit" ? "SET_EDITING" : "SET_DUPLICATE",
          progEvent
        );
        if (progEvent.image) {
          const transformations = await FilesService.getImageTransform(
            progEvent.image
          );
          progEventCommit("SET_IMAGE_TRANSFORMATIONS", transformations);
        }
        store.commit("guests/genres/INIT_SELECTION", progEvent.genres);
        progEventCommit("UNLOAD");
      } catch (e) {
        ErrorService.handleError(e);
      }
    },

    async loadAttendees({ state }) {
      try {
        progEventCommit("LOAD");
        const attendeesList = await AttendeesService.getAllByFirebaseUserIds(
          state.progEventList[
            state.selectedEventIdForDetails
          ].eventAttendeesList.map(e => e.firebaseUserId)
        );

        progEventCommit("SET_ATTENDEES_LIST", attendeesList);
      } catch (e) {
        ErrorService.handleError(e);
      } finally {
        progEventCommit("UNLOAD");
      }
    },

    async saveFile(_, file: Blob): Promise<void> {
      progEventCommit("LOAD");
      await new Promise<string | ArrayBuffer | null>((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.onerror = error => reject(error);
      }).then(async x64File => {
        try {
          const batchId = await ProgEventService.uploadFile(x64File);
          progEventCommit("SET_IMPORT_BATCH_ID", batchId);
          await progEventDispatch("updateImportList", batchId);
        } catch (e) {
          ErrorService.handleError(e);
          progEventCommit("UNLOAD");
        }
      });
    },

    async updateImportList(_, batchId: string): Promise<void> {
      const importResult = await ProgEventService.getImportResult(batchId);
      progEventCommit("SET_IMPORT_FEEDBACK", importResult);
      if (importResult.toProcess?.length > 0) {
        await new Promise(res => setTimeout(res, 7000));
        await progEventDispatch("updateImportList", batchId);
      } else {
        progEventCommit("UNLOAD");
      }
    },

    async resetImport() {
      progEventCommit("SET_IMPORT_FEEDBACK", {
        successes: [],
        errors: [],
        toProcess: []
      });
      progEventCommit("SET_IMPORT_BATCH_ID", "");
    }
  },
  getters: {
    getProgEventList: (state: ProgEventState): ProgEvent[] => {
      return Object.values(state.progEventList);
    },
    getSelectedEventIds: (state: ProgEventState) =>
      state.selectedRows.map(e => e._id),

    getSelectedCount: (state: ProgEventState) => {
      return state.selectedRows.length;
    },
    getProgEvent: (state: ProgEventState) => {
      return state.editingProgEvent
        ? state.editingProgEvent
        : {
            musicGroups: null,
            scene: null,
            day: null,
            titles: { fr: null },
            image: null,
            copyright: null,
            descriptions: {
              fr: "",
              en: ""
            },
            genres: [],
            eventTicketLink: null,
            startTime: "12:00",
            endTime: "13:00",
            date: null,
            isVisible: false,
            program: null,
            enableAttending: false,
            enableRating: false,
            programEventNotifSendTime: "NEVER",
            notifPlanned: false,
            hideEndDate: false
          };
    },

    getFilteredEventList: state => {
      const { search } = state;
      const { guest, program, scene, day } = search;

      const includesIgnoreCase = (str: string, substr: string) =>
        str.toLowerCase().includes(substr.toLowerCase());

      const filteredEvents = Object.values(state.progEventList).filter(
        event => {
          if (
            guest &&
            !includesIgnoreCase(translatableText(event.titles), guest) &&
            !event.musicGroups?.some(group =>
              includesIgnoreCase(translatableText(group.names), guest)
            )
          ) {
            return false;
          }
          if (program && event.program._id !== program) return false;
          if (scene && event.scene._id !== scene) return false;
          if (day && event.day !== day) return false;

          return true;
        }
      );

      return filteredEvents;
    },

    getDays: (state: ProgEventState) => {
      return Object.values(state.progEventList)
        .reduce((acc: string[], event) => {
          acc.includes(event.day) ? "" : acc.push(event.day);
          return acc;
        }, [])
        .sort((a, b) => {
          return +(a > b) || -(a < b);
        });
    },

    getDaysByProgram: (state: ProgEventState) => {
      return (programId: string) => {
        const tab = cloneDeep(
          Object.values(state.progEventList).filter(
            n => n.program._id === programId
          )
        );

        return tab
          .reduce((acc: string[], event) => {
            acc.includes(event.day) ? "" : acc.push(event.day);
            return acc;
          }, [])
          .sort((a, b) => {
            return +(a > b) || -(a < b);
          });
      };
    },

    getSelectedProgram: (
      state: ProgEventState,
      _getters,
      _rootState,
      rootGetters
    ) => {
      return state.selectedProgram
        ? state.selectedProgram
        : rootGetters["programs/getPrograms"][0];
    },

    getItems: (state: ProgEventState) => {
      return (
        selectedProgram: Program
      ): Array<
        Record<string, ProgEvent> & {
          scene: string;
        }
      > => {
        const events = cloneDeep(
          Object.values(state.progEventList).filter(
            p => p.program._id === selectedProgram._id
          )
        );

        const listScene = events.reduce((acc: Scene[], event) => {
          acc.filter(s => s._id === event.scene._id).length > 0
            ? ""
            : acc.push(event.scene);
          return acc;
        }, []);

        return listScene.map(scene => {
          return {
            ...groupBy(
              events.filter(n => n.scene._id === scene._id),
              event => event.day
            ),
            scene: translatableText(scene.names)
          } as Record<string, ProgEvent> & {
            scene: string;
          };
        });
      };
    },

    getProgEventDetail: (state: ProgEventState) =>
      state.progEventList[state.selectedEventIdForDetails]
  }
});
