import { type ActionContext, type Module } from "vuex";

import { ErrorService } from "@/shared/service/errorService";
import { type RootState } from "@/store";

import { DashboardService } from "./dashboard.service";

export class DashboardState {
  nbMusicGroup: number = 0;
  nbScenes: number = 0;
  nbPrograms: number = 0;
  nbEvents: number = 0;
  nbSponsors: number = 0;
  nbPOI: number = 0;
  nbNotifications: number = 0;
  nbInfos: number = 0;

  showModal: boolean = false;
}

export const dashboard: Module<DashboardState, RootState> = {
  namespaced: true,
  state: new DashboardState(),
  actions: {
    async loadDashboard({ commit }: ActionContext<DashboardState, RootState>) {
      try {
        const data = await DashboardService.loadDashboard();
        commit("SET_NUMBERS", data);
      } catch (e) {
        ErrorService.handleError(e);
      }
    }
  },

  mutations: {
    SET_NUMBERS(state: DashboardState, data) {
      state.nbMusicGroup = data.nbMusicGroup;
      state.nbScenes = data.nbScenes;
      state.nbPrograms = data.nbPrograms;
      state.nbEvents = data.nbEvents;
      state.nbSponsors = data.nbSponsors;
      state.nbPOI = data.nbPOI;
      state.nbNotifications = data.nbNotifications;
      state.nbInfos = data.nbInfos;
    },

    SHOW_MODAL(state: DashboardState) {
      state.showModal = true;
    },

    HIDE_MODAL(state: DashboardState) {
      state.showModal = false;
    }
  }
};
