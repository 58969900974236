import { type ActionContext, type Module } from "vuex";

import { menuEntriesDispatch } from "@/pages/menuEntries/menuEntries.store";
import {
  type MenuElement,
  type NavBarContent
} from "@/pages/menuEntries/MenuEntry.type";
import {
  type PageContent,
  type PageElement
} from "@/pages/menuEntries/pageSetup/pageContent.type";
import { type RootState } from "@/store";

import { ApplicationDataService } from "../service/applicationDataService";
import { ErrorService } from "../service/errorService";
import { MobilePreviewFallbackFonts } from "../service/fontsService";
import {
  type GrabEvent,
  type PreviewDeeplinkElement,
  type PreviewGrabData,
  type PreviewNavBarContent,
  type PreviewPageContent,
  type PreviewPageElement
} from "../types/applicationData.type";

export class GrabDataState {
  data: PreviewGrabData | null = null;
}

function adaptMenuElement(
  serverMenuElement?: MenuElement
): PreviewDeeplinkElement | undefined {
  if (!serverMenuElement) {
    return undefined;
  }
  return {
    ...serverMenuElement,
    title: serverMenuElement.titles["fr"]
  };
}

function adaptPageElement(serverPageElement: PageElement): PreviewPageElement {
  return {
    ...serverPageElement,
    title: serverPageElement.titles["fr"]
  };
}

function adaptPageContent(serverPageContent: PageContent): PreviewPageContent {
  if (
    serverPageContent.type === "HOME_CUSTOM_FULL" ||
    serverPageContent.type === "HOME_CUSTOM_THIRD" ||
    serverPageContent.type === "HOME_CUSTOM_HALF" ||
    serverPageContent.type === "PAGE_LIST"
  ) {
    return {
      ...serverPageContent,
      title: serverPageContent.title && serverPageContent.title["fr"],
      elements: serverPageContent.elements.map(e => e && adaptPageElement(e))
    };
  } else if (
    serverPageContent.type === "HOME_PROGRAMS" ||
    serverPageContent.type === "HOME_LIVE"
  ) {
    return {
      ...serverPageContent,
      title: serverPageContent.title && serverPageContent.title["fr"]
    };
  } else if (serverPageContent.type === "HOME_IMAGE") {
    return {
      ...serverPageContent,
      id: serverPageContent.id
    };
  } else if (serverPageContent.type === "HOME_COUNTDOWN") {
    return {
      ...serverPageContent,
      title: serverPageContent.title && serverPageContent.title["fr"],
      dateParam: new Date(serverPageContent.dateParam!).getTime()
    };
  } else {
    return { ...serverPageContent };
  }
}

export const appData: Module<GrabDataState, RootState> = {
  namespaced: true,
  state: new GrabDataState(),
  mutations: {
    SET_INITIAL_STATE(state: GrabDataState, initialGrab: PreviewGrabData) {
      state.data = initialGrab;
    }
  },
  getters: {
    getGrabData: (
      state: GrabDataState,
      getters: any,
      rootState: any
    ): PreviewGrabData | null => {
      if (!state.data) return null;

      const newNavBar: NavBarContent = rootState["menuEntries"]["navBar"];
      const newPageContents: PageContent[] =
        rootState["pageSetup"]["pageContents"];

      const adaptedState: PreviewGrabData = {
        ...state.data,
        applicationConfig: {
          ...state.data.applicationConfig,
          fontTitle:
            state.data.applicationConfig.fontTitle ||
            MobilePreviewFallbackFonts.fontTitle.fontName,
          fontTextBold:
            state.data.applicationConfig.fontTextBold ||
            MobilePreviewFallbackFonts.fontTextBold.fontName,
          fontTextRegular:
            state.data.applicationConfig.fontTextRegular ||
            MobilePreviewFallbackFonts.fontTextRegular.fontName,
          fontTextSemiBold:
            state.data.applicationConfig.fontTextSemiBold ||
            MobilePreviewFallbackFonts.fontTextSemiBold.fontName
        }
      };
      if (newNavBar) {
        adaptedState.navBar = {
          ...newNavBar,
          elements: newNavBar.elements.map(
            adaptMenuElement
          ) as PreviewNavBarContent["elements"]
        };
      }
      if (newPageContents && newPageContents.length) {
        const newContents = newPageContents.map(adaptPageContent);
        if (newContents[0]?.page === "MENU_PAGE") {
          adaptedState.menuContents = newContents;
        } else if (newContents[0]?.page === "HOME_PAGE") {
          adaptedState.homeContents = newContents;
        }
      }

      return adaptedState;
    },

    getRandomEvents: (state: GrabDataState): GrabEvent[] => {
      if (!state.data) return [];
      return state.data.events.filter((e, i) => i < 3);
    }
  },
  actions: {
    async loadCurrentData(
      { commit }: ActionContext<GrabDataState, RootState>,
      payload: { filterOnlyVisible: boolean }
    ): Promise<void> {
      try {
        const initialGrab = await ApplicationDataService.getCurrent(
          "fr",
          payload.filterOnlyVisible
        );
        commit("SET_INITIAL_STATE", initialGrab);
        menuEntriesDispatch("getAllContent");
      } catch (e) {
        ErrorService.handleError(e, false);
      }
    }
  }
};
