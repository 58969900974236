import axios from "axios";

export const GenresService = {
  async getAll() {
    const { data } = await axios.get("/api/genres");
    return data;
  },

  async addGenre(genre) {
    delete genre._id;
    const { data } = await axios.post("/api/genres", genre);
    return data;
  },

  async updateGenre(genre) {
    return await axios.put(`/api/genres/${genre._id}`, genre);
  },

  async deleteGenre(genreId) {
    return await axios.delete(`/api/genres/${genreId}`);
  }
};
