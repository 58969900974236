import cloneDeep from "lodash/cloneDeep";

import { i18n } from "@/i18n/i18n";
import { ErrorService } from "@/shared/service/errorService";
import { SnackbarService } from "@/shared/snackbar/snackbar.service";

import { SponsorsCategoriesService } from "../sponsorsCategories.service";

export const actions = {
  async list({ commit }) {
    commit("LOAD");
    try {
      const sponsorsCategories = await SponsorsCategoriesService.getAll();
      commit("SET_SPONSORS_CATEGORIES", sponsorsCategories);
      commit("UNLOAD");
    } catch (e) {
      ErrorService.handleError(e);
    }
  },

  async deleteSponsorCategory({ commit, state, dispatch }) {
    commit("LOAD");
    try {
      await SponsorsCategoriesService.deleteSponsorCategory(
        state.deletingSponsorCategoryId
      );
      SnackbarService.info(
        i18n.t("SPONSORS_CATEGORIES.SNACK_BAR.DELETE", {
          string: state.sponsorsCategories[state.deletingSponsorCategoryId].name
        })
      );
      commit("SET_NOT_DELETING");
      await dispatch("list");
      await this.dispatch("sponsors/list");
      commit("UNLOAD");
    } catch (e) {
      ErrorService.handleError(e);
      commit("UNLOAD");
    }
  },

  async addSponsorCategory({ commit, state, dispatch }, sponsorCategory) {
    commit("LOAD");
    try {
      let message = "";
      if (state.editingSponsorCategoryId) {
        await SponsorsCategoriesService.updateSponsorCategory(sponsorCategory);
        message = i18n.t("SPONSORS_CATEGORIES.SNACK_BAR.EDIT", {
          string: sponsorCategory.name
        });
      } else {
        await SponsorsCategoriesService.addSponsorCategory(sponsorCategory);
        message = i18n.t("SPONSORS_CATEGORIES.SNACK_BAR.ADD", {
          string: sponsorCategory.name
        });
      }
      SnackbarService.info(message);
      await dispatch("list");
      await this.dispatch("sponsors/list");
      commit("RESET_EDITING");
    } catch (e) {
      ErrorService.handleError(e);
    }
  },

  async updateSponsorsCategories({ state, commit, dispatch }) {
    commit("LOAD");
    try {
      const values = cloneDeep(Object.values(state.addedCategories));
      await Promise.all(
        values.map(async item => {
          delete item._id;
          return await SponsorsCategoriesService.addSponsorCategory(item);
        })
      );

      await Promise.all(
        state.deletedCategoriesIds.map(id => {
          return SponsorsCategoriesService.deleteSponsorCategory(id);
        })
      );
      await dispatch("list");
    } catch (e) {
      ErrorService.handleError(e);
    }
  }
};
