import axios from "axios";
import cloneDeep from "lodash/cloneDeep";

import { type Program } from "@/shared/types/program.type";

import { colorService } from "../../shared/service/utils.service";

function toJsFormat(programs: Program[]): Program[] {
  programs.map(program => programToJsFormat(program));
  return programs;
}

function programToJsFormat(program: Program): Program {
  program.color = program.color ? colorService.argb_to_rgba(program.color) : "";
  return program;
}

function programToDbFormat(program: Program): Program {
  const prog = cloneDeep(program);
  prog.color = prog.color ? colorService.rgba_to_argb(prog.color) : "";
  return prog;
}

export const ProgramsService = {
  async getAll(): Promise<Program[]> {
    const { data } = await axios.get("/api/program");
    return toJsFormat(data);
  },

  async updateProgram(program: Program): Promise<Program> {
    return await axios.put(`/api/program`, programToDbFormat(program));
  },

  async delete(id: string) {
    return await axios.delete(`/api/program/${id}`);
  },

  async addProgram(program: Program): Promise<Program> {
    return await axios.post("/api/program", programToDbFormat(program));
  },

  async getProgramById(id: string): Promise<Program> {
    const { data } = await axios.get(`/api/program/${id}`);
    return programToJsFormat(data);
  }
};
