import axios from "axios";
import { type DataOptions } from "vuetify";

import {
  computeFakeTitle,
  eventsToDbFormat
} from "@/pages/progEvent/progEvent.service";
import { type GuestDto } from "@/shared/types/musicGroup/guestDto.type";
import { type MusicGroup } from "@/shared/types/musicGroup/musicGroup.type";
import {
  type MusicGroupEventFormInput,
  musicGroupEventFormInputsToProgEvents
} from "@/shared/types/musicGroup/musicGroupEvent.type";
import { type ProgEvent } from "@/shared/types/progEvent.type";

function eventsToJsFormat(data: ProgEvent[]) {
  data.forEach(element => {
    element["fakeTitle"] = computeFakeTitle(element);
  });
  return data;
}

function guestsToJsFormat(guests: MusicGroup[]): MusicGroup[] {
  return guests.map(guestToJsFormat);
}

function guestToDbFormat(guest: MusicGroup) {
  if (guest.location && !(guest.location.length > 0)) delete guest.location;
  return guest;
}

function guestToJsFormat(guest: MusicGroup): MusicGroup {
  if (!guest.location) guest.location = [];
  return guest;
}

export const GuestsService = {
  async getAll(): Promise<MusicGroup[]> {
    const { data } = await axios.get<MusicGroup[]>("/api/groups");
    return guestsToJsFormat(data);
  },

  async deleteGuest(id: string): Promise<void> {
    return await axios.delete(`/api/groups/${id}`);
  },

  async deleteMultipleGuests(ids: string[]): Promise<void> {
    return await axios.post(`/api/groups/batch-delete`, ids);
  },

  async addGuest(
    guest: MusicGroup,
    guestEvents: MusicGroupEventFormInput[]
  ): Promise<GuestDto> {
    return await axios.post("/api/groups", {
      ...guestToDbFormat(guest),
      newEvents: eventsToDbFormat(
        musicGroupEventFormInputsToProgEvents(guestEvents)
      )
    });
  },

  async updateGuest(
    guest: MusicGroup,
    guestEvents: MusicGroupEventFormInput[] | undefined
  ): Promise<GuestDto> {
    return await axios.put(`/api/groups/${guest._id}`, {
      ...guestToDbFormat(guest),
      newEvents: eventsToDbFormat(
        musicGroupEventFormInputsToProgEvents(guestEvents)
      )
    });
  },

  async hideShowBatch(ids: string[], show: boolean): Promise<void> {
    return await axios.post("/api/groups/batch-hide-show", { ids, show });
  },

  async getGuestById(guestId: string): Promise<GuestDto> {
    const { data } = await axios.get(`/api/groups/${guestId}`);
    return guestToJsFormat(data);
  },

  async getGuestsLinks(): Promise<string[]> {
    const { data } = await axios.get("/api/groupsLinks");
    return data;
  },

  async getGuestsLocations(): Promise<string[][]> {
    const { data } = await axios.get("/api/groupsLocationsList");
    return data;
  },

  async getGuestsLight(): Promise<
    Pick<
      MusicGroup,
      "_id" | "names" | "descriptions" | "image" | "copyright" | "genres"
    >[]
  > {
    const { data } = await axios.get("/api/groupsLight");
    return data;
  },
  async getPaginatedGuests(
    search: string,
    options: Partial<DataOptions>,
    offset: number,
    guestTypeId?: string
  ): Promise<MusicGroup[]> {
    const params = {
      query: search,
      offset,
      limit: options.itemsPerPage
    };
    const sortParams = options?.sortBy?.[0]
      ? { order: options.sortBy[0], desc: options?.sortDesc?.[0] }
      : {};
    const guestParams = guestTypeId ? { type: guestTypeId } : {};
    const { data } = await axios.get(`/api/groupsPaginated`, {
      params: { ...params, ...sortParams, ...guestParams }
    });
    data.musicGroups = guestsToJsFormat(data.musicGroups);
    return data;
  },
  async uploadFile(file: unknown) {
    return await axios.post("/api/groups/batch/importXls", {
      file: file
    });
  },
  async getImportResult(batchId: string) {
    return await axios.get(`/api/groups/batch/importResult/${batchId}`);
  },

  async getGuestEvents(guestIds: string[]) {
    const { data } = await axios.get(`/api/groups/events/${guestIds}`);
    return eventsToJsFormat(data);
  },

  async getGuestMarkers(guestIds: string[]) {
    const { data } = await axios.get(`/api/groups/markers/${guestIds}`);
    return eventsToJsFormat(data);
  },

  async editGuestsBatch(ids: string[], typeId?: string): Promise<void> {
    await axios.post("/api/groups/batch-edit-show", {
      ids,
      typeId
    });
  }
};
