import { type ProgramsState } from "@/pages/programs/store/programs.state";
import { type ProgEvent } from "@/shared/types/progEvent.type";
import { type Program } from "@/shared/types/program.type";

export const mutations = {
  LOAD(state: ProgramsState) {
    state.loading = true;
  },

  UNLOAD(state: ProgramsState) {
    state.loading = false;
  },

  SET_PROGRAMS(state: ProgramsState, programs: Program[]) {
    state.programs = programs.reduce(
      (acc: { [key: string]: Program }, program) => {
        acc[program._id] = program;
        return acc;
      },
      {}
    );
  },

  SET_DELETING(state: ProgramsState, id: string) {
    state.deletingProgramId = id;
  },

  SET_NOT_DELETING(state: ProgramsState) {
    state.deletingProgramId = null;
  },

  SET_ADDING(state: ProgramsState) {
    state.showModal = true;
  },

  SET_EDITING(state: ProgramsState, id: string) {
    state.editingProgramId = id;
    state.showModal = true;
  },

  SET_EVENTS(state: ProgramsState, events: ProgEvent[]) {
    state.events = events;
  },

  RESET(state: ProgramsState) {
    state.showModal = false;
    state.editingProgramId = null;
  }
};
