import "./axios.intercepters";
import "./app.scss";
import "./shared/filters/translatableText.filter";
import "dayjs/locale/fr";
import "@fortawesome/fontawesome-free/css/fontawesome.css";
import "@fortawesome/fontawesome-free/css/brands.css";
import "@fortawesome/fontawesome-free/css/solid.css";

import * as Sentry from "@sentry/vue";
import Vue from "vue";
import Croppa from "vue-croppa";

import App from "./App.vue";
import { i18n } from "./i18n/i18n";
import vuetify from "./plugins/vuetify";
import router from "./router";
import ConfirmationModal from "./shared/components/global/modals/ConfirmationModal.vue";
import DeletingModal from "./shared/components/global/modals/DeletingModal.vue";
import Modal from "./shared/components/global/modals/Modal.vue";
import Page from "./shared/components/global/Page.vue";
import store from "./store";

Vue.config.productionTip = false;
Vue.config.ignoredElements = ["web-mobile-preview", "chapito-static"];

Vue.use(Croppa);

//Global components
Vue.component("Page", Page);
Vue.component("Modal", Modal);
Vue.component("DeletingModal", DeletingModal);
Vue.component("ConfirmationModal", ConfirmationModal);

// Sentry integration, login dev@code-troopers
Sentry.init({
  Vue,
  dsn: "https://c48139fcffa14ba5a9560e8afbf8157c@o100126.ingest.us.sentry.io/1301118",
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration()
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0
});

new Vue({
  router,
  i18n,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
