import { computed, type ComputedRef } from "vue";
import {
  mapGetters,
  mapState,
  type Module,
  type ModuleTree,
  type Store
} from "vuex";

import { default as rootStore, type RootState } from "../store";

export function createStore<
  State,
  const Getters extends {
    [key: string]: (
      state: Readonly<State>,
      getters: unknown,
      rootState: Readonly<RootState>,
      rootGetters: unknown
    ) => any;
  },
  const Mutations extends {
    [key: string]: (state: State, payload?: any) => void;
  },
  const Actions extends {
    [key: string]: (
      this: Store<RootState>,
      context: {
        // FIXME inference for mutation/action doesn't work in actions context
        // dispatch: <const ActionName extends keyof Actions & string>(
        //   action: ActionName,
        //   payload: Parameters<Actions[ActionName]>[1]
        // ) => Promise<void>;
        // commit: <const MutationName extends keyof Mutations & string>(
        //   mutation: MutationName,
        //   payload: Parameters<Mutations[MutationName]>[1]
        // ) => void;
        dispatch: (action: string, payload?: any) => Promise<void>;
        commit: (mutation: string, payload?: any) => void;
        state: State;
        getters: { [K in keyof Getters]: ReturnType<Getters[K]> };
        rootState: RootState;
        rootGetters: unknown;
      },
      payload?: any
    ) => Promise<void> | void;
  },
  ModuleName extends string
>(
  arg: Readonly<{
    moduleName: ModuleName;
    namespaced?: boolean;
    initState: State;
    mutations?: Mutations;
    actions?: Actions;
    getters?: Getters;
    modules?: ModuleTree<RootState>;
  }>
): {
  [key in ModuleName]: Module<State, RootState>;
} & {
  mapGetters: GetterMapper<Getters>;
  mapState: StateMapper<State>;
  dispatch: <const ActionName extends keyof Actions & string>(
    action: ActionName,
    ...payload: WrapOptParams<Parameters<Actions[ActionName]>[1]>
  ) => Promise<unknown>;
  commit: <const MutationName extends keyof Mutations & string>(
    mutation: MutationName,
    ...payload: WrapOptParams<Parameters<Mutations[MutationName]>[1]>
  ) => unknown;
  useGetter: <const Selector extends keyof Getters & string>(
    getterName: Selector
  ) => ComputedRef<ReturnType<Getters[Selector]>>;
  useState: <const Selector extends keyof State & string>(
    getterName: Selector
  ) => ComputedRef<State[Selector]>;
} {
  return {
    mapGetters: arr => {
      return arg.namespaced === false
        ? (mapGetters(arr) as any)
        : (mapGetters(arg.moduleName, arr) as any);
    },
    mapState: (
      mapper:
        | Record<string, keyof State>
        | Record<string, (state: State) => any>
        | (keyof State)[]
    ) => {
      return mapState(arg.moduleName, mapper as any) as any;
    },
    dispatch: (action, ...payload) => {
      return arg.namespaced === false
        ? rootStore.dispatch(action, ...payload)
        : rootStore.dispatch(`${arg.moduleName}/${action}`, ...payload);
    },
    commit: (mutation, ...payload) => {
      return arg.namespaced === false
        ? rootStore.commit(mutation, ...payload)
        : rootStore.commit(`${arg.moduleName}/${mutation}`, ...payload);
    },
    useGetter: getterName => {
      return computed(() =>
        arg.namespaced === false
          ? rootStore.getters[getterName]
          : rootStore.getters[`${arg.moduleName}/${getterName}`]
      );
    },
    useState: stateSlice => {
      return computed(
        () => rootStore.state[arg.moduleName as keyof RootState][stateSlice]
      );
    },
    ...({
      [arg.moduleName]: {
        namespaced: arg.namespaced ?? true,
        state: arg.initState,
        mutations: arg.mutations,
        actions: arg.actions,
        getters: arg.getters,
        modules: arg.modules
      }
    } as {
      [key in ModuleName]: Module<State, RootState>;
    })
  };
}

type WrapOptParams<T> = [T] extends [undefined] ? [] : [T];

export interface StateMapper<State> {
  <const Mapper extends Record<string, keyof State>>(
    mapper: Mapper
  ): {
    [K in keyof Mapper]: () => State[Mapper[K]];
  };
  <const Mapper extends Record<string, (state: State) => any>>(
    mapper: Mapper
  ): {
    [K in keyof Mapper]: () => ReturnType<Mapper[K]>;
  };
  <const Selector extends keyof State & string>(
    arr: Selector[]
  ): { [K in Selector]: () => State[K] };
}

export interface GetterMapper<
  Getters extends Record<string, (...args: any) => any>
> {
  <const Selector extends keyof Getters & string>(
    arr: Selector[]
  ): { [K in Selector]: () => ReturnType<Getters[K]> };
}
