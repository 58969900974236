import { GalleryItem } from "./../gallery.type";
import { type GalleryState } from "./gallery.state";

export const getters = {
  getGalleryItems: (state: GalleryState) => {
    return Object.values(state.gallery).sort(
      (a: GalleryItem, b: GalleryItem) => {
        return a.weight - b.weight;
      }
    );
  },

  getAddingGalleryItem: (state: GalleryState) => {
    return state.editingGalleryItemId
      ? state.gallery[state.editingGalleryItemId]
      : new GalleryItem();
  }
};
