import { type Route } from "vue-router";

import { dispatchStoresInformations } from "./storesInformations.store";

export const StoresInformationsRouter = [
  {
    path: "/store",
    component: () => import("./StoresInformations.vue"),
    beforeEnter: async (to: Route, from: Route, next: any) => {
      await dispatchStoresInformations("loadAppStore");
      next();
    }
  }
];
