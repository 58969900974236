import { type ActionContext } from "vuex";

import { i18n } from "@/i18n/i18n";
import { type AdsState } from "@/pages/ads/store/ads.state";
import { ApplicationConfigService } from "@/shared/service/applicationConfig.service";
import { ErrorService } from "@/shared/service/errorService";
import { FilesService } from "@/shared/service/files.service";
import { SnackbarService } from "@/shared/snackbar/snackbar.service";
import { type RootState } from "@/store";

import { InternalType } from "../../../shared/types/files.type";
import { isNotNullish } from "../../../utils";

export const AdsActions = {
  async loadApplicationCfg({ commit }: ActionContext<AdsState, RootState>) {
    try {
      commit("LOAD");
      const applicationCfg = await ApplicationConfigService.get();
      commit("SET_APPLICATION_CFG", applicationCfg);
    } catch (e) {
      ErrorService.handleError(e);
    } finally {
      commit("UNLOAD");
    }
  },

  async updateApplicationCfg(
    { state, dispatch, commit }: ActionContext<AdsState, RootState>,
    payload: { splashDuration: number }
  ) {
    try {
      commit("LOAD");
      await dispatch("saveAllImg");
      if (state.applicationCfg) {
        state.applicationCfg.splashAds = state.splashAds;
        state.applicationCfg.drawerAds = state.drawerAds;
        state.applicationCfg.splashDuration = payload.splashDuration;

        await ApplicationConfigService.updateApplicationCfg(
          state.applicationCfg
        );
        SnackbarService.info(i18n.t("ADS.SAVE").toString());
        await dispatch("loadApplicationCfg");
      } else {
        SnackbarService.error(
          "updateApplicationCfg in ads page failed: applicationCfg was not loaded"
        );
      }
    } catch (e) {
      ErrorService.handleError(e);
    } finally {
      commit("UNLOAD");
    }
  },

  async saveAllImg({
    state,
    commit
  }: ActionContext<AdsState, RootState>): Promise<void> {
    await Promise.all(
      [
        ...state.drawerAds.flatMap((ad, index) => {
          return [
            ad.croppedImage
              ? ({
                  index,
                  commitKey: `SET_DRAWER_AD_IMAGE`,
                  croppedImage: ad.croppedImage
                } as const)
              : null,
            ad.videoFile
              ? ({
                  index,
                  commitKey: `SET_DRAWER_AD_VIDEO`,
                  videoFile: ad.videoFile
                } as const)
              : ad.videoFilename
                ? ({
                    index,
                    commitKey: `SET_DRAWER_AD_VIDEO`,
                    videoFilename: ad.videoFilename
                  } as const)
                : ({
                    index,
                    commitKey: `SET_DRAWER_AD_VIDEO`,
                    videoFilename: null
                  } as const)
          ];
        }),
        ...state.splashAds.flatMap((ad, index) => {
          return [
            ad.croppedImage
              ? ({
                  index,
                  commitKey: `SET_SPLASH_AD_IMAGE`,
                  croppedImage: ad.croppedImage
                } as const)
              : null,
            ad.videoFile
              ? ({
                  index,
                  commitKey: `SET_SPLASH_AD_VIDEO`,
                  videoFile: ad.videoFile
                } as const)
              : ad.videoFilename
                ? ({
                    index,
                    commitKey: `SET_SPLASH_AD_VIDEO`,
                    videoFilename: ad.videoFilename
                  } as const)
                : ({
                    index,
                    commitKey: `SET_SPLASH_AD_VIDEO`,
                    videoFilename: null
                  } as const)
          ];
        })
      ]
        .filter(isNotNullish)
        .map(async a => {
          const { filename } = a.croppedImage
            ? await FilesService.uploadImage({
                image: a.croppedImage,
                internalType: InternalType.PICTURE
              })
            : a.videoFile
              ? await FilesService.uploadVideo(
                  a.videoFile,
                  a.commitKey === "SET_DRAWER_AD_VIDEO" ? 4 : 9 / 16
                )
              : { filename: a.videoFilename };
          commit(a.commitKey, { filename, index: a.index });
        })
    );
  }
};
