import { type GuestType } from "@/pages/guestTypes/GuestType.model";
import { type GuestTypeState } from "@/pages/guestTypes/store/guestType.state";

export const getters = {
  getGuestTypes: (state: GuestTypeState): GuestType[] => {
    return Object.values(state.guestTypes);
  },

  getGuestTypeById: (state: GuestTypeState): ((id: string) => GuestType) => {
    return (id: string): GuestType => state.guestTypes[id];
  },

  getUsedFrGuestNameLowercase: (state: GuestTypeState): string[] => {
    return Object.values(state.guestTypes)
      .filter(
        g =>
          state.currentGuestType == null || g._id !== state.currentGuestType._id
      )
      .map(g => g.names["fr"].toLowerCase());
  }
};
