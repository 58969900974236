import { type Sponsor } from "@/shared/types/sponsor.type";

export class SponsorsState {
  sponsors: { [key: string]: Sponsor } = {};
  loading = false;
  deletingSponsorId: string | null = null;
  editingSponsor: Sponsor | null = null;
  image: string | null = null;
}

export const state = new SponsorsState();
