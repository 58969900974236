import axios from "axios";
import dayjs from "dayjs";

import { translatableText } from "@/shared/filters/translatableText.filter";
import { type ProgEvent } from "@/shared/types/progEvent.type";

import { type PageContent } from "../menuEntries/pageSetup/pageContent.type";
import { type ImportResult } from "./progEvent.store";

type DBProgEvent = Omit<
  ProgEvent,
  "fakeTitle" | "startTime" | "date" | "endTime" | "attendees"
>;

export function eventsToDbFormat(events?: ProgEvent[]): DBProgEvent[] | null {
  if (events == null) return null;
  return events.map(e => eventToDbFormat(e));
}

export function eventToDbFormat(event: ProgEvent): DBProgEvent {
  event.day = dayjs(event.date).toISOString();
  event.showStartDate = dayjs(event.day)
    .add(getDayOffset(event.startTime), "days")
    .hour(parseInt(event.startTime.split(":")[0]))
    .minute(parseInt(event.startTime.split(":")[1]))
    .toISOString();
  event.showEndDate = dayjs(event.day)
    .add(getDayOffset(event.endTime), "days")
    .hour(parseInt(event.endTime.split(":")[0]))
    .minute(parseInt(event.endTime.split(":")[1]))
    .toISOString();
  if (dayjs(event.showEndDate).isBefore(dayjs(event.showStartDate))) {
    event.showEndDate = dayjs(event.showEndDate).add(1, "days").toISOString();
  }

  const {
    date: _date,
    startTime: _startTime,
    endTime: _endTime,
    fakeTitle: _fakeTitle,
    attendees: _attendees,
    ...rest
  } = event;
  return rest;
}

// offset by one day if below 06:00
export function getDayOffset(time: string): 1 | 0 {
  return dayjs(time, "HH:mm").format("HH:mm") < "06:00" ? 1 : 0;
}

function eventToJsFormat(event: DBProgEvent): ProgEvent {
  return {
    ...event,
    fakeTitle: computeFakeTitle(event),
    date: dayjs(event.day).format("YYYY-MM-DD"),
    startTime: dayjs(event.showStartDate).format(
      "HH:mm"
    ) as `${number}:${number}`,
    endTime: dayjs(event.showEndDate).format("HH:mm") as `${number}:${number}`,
    attendees: event.eventAttendeesList.length
  };
}

export function computeFakeTitle(event: DBProgEvent): string {
  const title = translatableText(event.titles, "fr");
  if (title === "" && event.musicGroups?.length) {
    return event.musicGroups
      .map(guest => translatableText(guest.names))
      .join(" & ");
  }
  return title;
}

export type ProgramEventUsage = {
  pageContents: PageContent[];
};

export const ProgEventService = {
  async getAll(): Promise<ProgEvent[]> {
    const { data } = await axios.get<DBProgEvent[]>("/api/programEvent");
    return data.map((data: DBProgEvent) => eventToJsFormat(data));
  },

  async getProgramEventByProgram(id: string): Promise<ProgEvent[]> {
    const { data } = await axios.get<DBProgEvent[]>(
      `/api/programEvent/byProgram/${id}`
    );
    return data.map((data: DBProgEvent) => eventToJsFormat(data));
  },

  async getProgramEventById(id: string): Promise<ProgEvent> {
    const { data } = await axios.get<DBProgEvent>(`/api/programEvent/${id}`);
    return eventToJsFormat(data);
  },

  async deleteProgramEvent(id: string): Promise<void> {
    await axios.delete(`/api/programEvent/${id}`);
  },

  async addProgramEvent(programEvent: ProgEvent): Promise<ProgEvent> {
    const { data } = await axios.post<DBProgEvent>(
      "/api/programEvent",
      eventToDbFormat(programEvent)
    );
    return eventToJsFormat(data);
  },

  async updateProgramEvent(programEvent: ProgEvent): Promise<ProgEvent> {
    const { data } = await axios.put<DBProgEvent>(
      `/api/programEvent`,
      eventToDbFormat(programEvent)
    );
    return eventToJsFormat(data);
  },
  async hideShowBatch(ids: string[], show: boolean): Promise<void> {
    await axios.post("/api/programEvent/batch-hide-show", { ids, show });
  },

  async checkUsage(ids: string[]): Promise<ProgramEventUsage> {
    const { data } = await axios.get("/api/programEvent/check-usage", {
      params: { ids: ids.join(",") }
    });
    return data;
  },

  async editShowBatch(
    ids: string[],
    programId?: string,
    sceneId?: string
  ): Promise<void> {
    await axios.post("/api/programEvent/batch-edit-show", {
      ids,
      programId,
      sceneId
    });
  },
  async multipleDelete(ids: string[]): Promise<void> {
    await axios.post(`/api/programEvent/batch-delete`, ids);
  },
  async uploadFile(file: string | ArrayBuffer | null): Promise<string> {
    const { data } = await axios.post<string>(
      "/api/programEvent/batch/importXls",
      {
        file: file
      }
    );
    return data;
  },
  async getImportResult(batchId: string): Promise<ImportResult> {
    const { data } = await axios.get<ImportResult>(
      `/api/programEvent/batch/importResult/${batchId}`
    );
    return data;
  }
};
