import Vue from "vue";
import Vuex from "vuex";

import { ads } from "@/pages/ads/store/ads.store";
import { matomoDashboardStore } from "@/pages/analytics/store/dashboardAnalytics.store";
import { type SponsorsState } from "@/pages/sponsors/store/sponsors.state";

import { applicationCfg } from "./pages/applicationCfg/store/applicationCfg.store";
import { attendee } from "./pages/attendee/store/attendee.store";
import { attendeeWhiteList } from "./pages/attendee-white-list/store/attendeeWhiteList.store";
import { dashboard } from "./pages/dashboard/dashboard.store";
import { festival } from "./pages/festival/festival.store";
import { gallery } from "./pages/gallery/store/gallery.store";
import { guests } from "./pages/guests/store/guests.store";
import { guestType } from "./pages/guestTypes/store/guestType.store";
import { imports } from "./pages/import/store/import.store";
import { type InfoState } from "./pages/infos/store/infos.store";
import { infos } from "./pages/infos/store/infos.store";
import { login } from "./pages/login/login.store";
import { map } from "./pages/map/store/map.store";
import { maps } from "./pages/map/store/maps.store";
import { menuEntries } from "./pages/menuEntries/menuEntries.store";
import { pageSetup } from "./pages/menuEntries/pageSetup/pageSetup.store";
import { notifications } from "./pages/notifications/store/notifications.store";
import { notificationsTopics } from "./pages/notificationsTopics/store/notificationsTopics.store";
import { orphaned } from "./pages/orphaned/store/orphaned.store";
import { progEvent } from "./pages/progEvent/progEvent.store";
import { programs } from "./pages/programs/store/programs.store";
import { publications } from "./pages/publications/publications.store";
import { resetApp } from "./pages/resetApp/resetApp.store";
import { scenes } from "./pages/scenes/store/scenes.store";
import { sponsorsCategories } from "./pages/sponsors/sponsorsCategories/store/sponsorsCategories.store";
import { sponsors } from "./pages/sponsors/store/sponsors.store";
import { staticCfgs } from "./pages/staticCfg/store/staticCfgs.store";
import { staticConfig } from "./pages/staticCfg/store/staticConfig.store";
import { storesInformations } from "./pages/storesInformations/storesInformations.store";
import { users } from "./pages/users/store/users.store";
import { snackbar } from "./shared/snackbar/snackbar.store";
import { appBar } from "./shared/store/app-bar.store";
import { appConfig } from "./shared/store/app-config.store";
import { appData, type GrabDataState } from "./shared/store/app-data.store";
import { isMobile } from "./shared/store/isMobile.store";
import { scrollPosition } from "./shared/store/scrollPosition.store";
import { session } from "./shared/store/session.store";
import { warningBanner } from "./shared/store/warningBanner.store";

export interface RootState {
  sponsorsCategories: any; // TODO migrate sponsorsCategories to ts
  sponsors: SponsorsState;
  appData: GrabDataState;
  infos: InfoState;
}

Vue.use(Vuex);

const store = new Vuex.Store<RootState>({
  modules: {
    login,
    resetApp,
    orphaned,
    applicationCfg,
    ads,
    publications,
    users,
    notifications,
    notificationsTopics,
    appData,
    menuEntries,
    attendeeWhiteList,
    storesInformations,
    festival,
    infos,
    sponsors,
    sponsorsCategories,
    attendee,
    guests,
    imports,
    scenes,
    programs,
    progEvent,
    warningBanner,
    dashboard,
    map,
    maps,
    matomoDashboardStore,
    gallery,
    scrollPosition,
    pageSetup,
    guestType,
    staticConfig,
    staticCfgs,
    //Global modules
    isMobile,
    session,
    snackbar,
    appConfig,
    appBar
  }
});

if (import.meta.hot) {
  import.meta.hot.accept(["./pages/login/login.store"], async () => {
    store.hotUpdate({
      modules: {
        login: (await import("./pages/login/login.store")).login,
        resetApp: (await import("./pages/resetApp/resetApp.store")).resetApp,
        orphaned: (await import("./pages/orphaned/store/orphaned.store"))
          .orphaned,
        applicationCfg: (
          await import("./pages/applicationCfg/store/applicationCfg.store")
        ).applicationCfg,
        ads: (await import("./pages/ads/store/ads.store")).ads,
        appConfig: (await import("./shared/store/app-config.store")).appConfig,
        addData: (await import("./shared/store/app-data.store")).appData,
        publications: (await import("./pages/publications/publications.store"))
          .publications,
        users: (await import("./pages/users/store/users.store")).users,
        notifications: (
          await import("./pages/notifications/store/notifications.store")
        ).notifications,
        notificationsTopics: (
          await import(
            "./pages/notificationsTopics/store/notificationsTopics.store"
          )
        ).notificationsTopics,
        menuEntries: (await import("./pages/menuEntries/menuEntries.store"))
          .menuEntries,
        matomoDashboardStore: (
          await import("./pages/analytics/store/dashboardAnalytics.store")
        ).matomoDashboardStore,
        attendeeWhiteList: (
          await import(
            "./pages/attendee-white-list/store/attendeeWhiteList.store"
          )
        ).attendeeWhiteList,
        storesInformations: (
          await import("./pages/storesInformations/storesInformations.store")
        ).storesInformations,
        festival: (await import("./pages/festival/festival.store")).festival,
        infos: (await import("./pages/infos/store/infos.store")).infos,
        sponsors: (await import("./pages/sponsors/store/sponsors.store"))
          .sponsors,
        sponsorsCategories: (
          await import(
            "./pages/sponsors/sponsorsCategories/store/sponsorsCategories.store"
          )
        ).sponsorsCategories,
        attendee: (await import("./pages/attendee/store/attendee.store"))
          .attendee,
        guests: (await import("./pages/guests/store/guests.store")).guests,
        scenes: (await import("./pages/scenes/store/scenes.store")).scenes,
        programs: (await import("./pages/programs/store/programs.store"))
          .programs,
        progEvent: (await import("./pages/progEvent/progEvent.store"))
          .progEvent,
        appBar: (await import("./shared/store/app-bar.store")).appBar,
        warningBanner: (await import("./shared/store/warningBanner.store"))
          .warningBanner,
        dashboard: (await import("./pages/dashboard/dashboard.store"))
          .dashboard,
        map: (await import("./pages/map/store/map.store")).map,
        isMobile: (await import("./shared/store/isMobile.store")).isMobile,
        gallery: (await import("./pages/gallery/store/gallery.store")).gallery,
        scrollPosition: (await import("./shared/store/scrollPosition.store"))
          .scrollPosition,
        pageSetup: (
          await import("./pages/menuEntries/pageSetup/pageSetup.store")
        ).pageSetup,
        guestType: (await import("./pages/guestTypes/store/guestType.store"))
          .guestType,
        imports: (await import("./pages/import/store/import.store")).imports
      }
    });
  });
}

export default store;
