import cloneDeep from "lodash/cloneDeep";
import { type ActionContext } from "vuex";

import { i18n } from "@/i18n/i18n";
import { type SponsorsOrderChange } from "@/pages/sponsors/models/SponsorsOrderChange.type";
import { type SponsorsState } from "@/pages/sponsors/store/sponsors.state";
import { translatableText } from "@/shared/filters/translatableText.filter";
import { ErrorService } from "@/shared/service/errorService";
import { FilesService } from "@/shared/service/files.service";
import { SnackbarService } from "@/shared/snackbar/snackbar.service";
import {
  type Sponsor,
  type SponsorCategory
} from "@/shared/types/sponsor.type";
import { type RootState } from "@/store";

import router from "../../../router";
import { InternalType } from "../../../shared/types/files.type";
import { SponsorsService } from "../sponsors.service";

export const actions = {
  async list({ commit }: ActionContext<SponsorsState, RootState>) {
    commit("LOAD");
    try {
      const sponsors = await SponsorsService.getAll();
      commit("SET_SPONSORS", sponsors);
    } catch (e) {
      ErrorService.handleError(e);
    } finally {
      commit("UNLOAD");
    }
  },

  async delete({
    state,
    commit,
    dispatch
  }: ActionContext<SponsorsState, RootState>) {
    commit("LOAD");
    try {
      const id = state.deletingSponsorId ? state.deletingSponsorId : "";
      await SponsorsService.delete(id);
      SnackbarService.info(
        i18n
          .t("SPONSORS.SNACKBAR.DELETE", {
            string: translatableText(state.sponsors[id].names)
          })
          .toString()
      );
      await dispatch("list");
    } catch (e) {
      ErrorService.handleError(e);
    } finally {
      commit("SET_NOT_DELETING");
    }
  },

  async uploadImage(
    { commit }: ActionContext<SponsorsState, RootState>,
    file: File
  ) {
    commit("LOAD");
    try {
      const savedImage = await FilesService.uploadImageFile(
        file,
        InternalType.SPONSOR
      );
      commit("SET_IMAGE", savedImage.filename);
    } catch (e) {
      ErrorService.handleError(e);
      throw e;
    } finally {
      commit("UNLOAD");
    }
  },

  async addSponsor(
    {
      state,
      commit,
      dispatch,
      rootState
    }: ActionContext<SponsorsState, RootState>,
    sponsor: Sponsor
  ) {
    commit("LOAD");
    try {
      await dispatch("sponsorsCategories/updateSponsorsCategories", null, {
        root: true
      });
      const selectedCategory = cloneDeep(
        rootState.sponsorsCategories.selectedCategory
      );
      sponsor.image = state.image;
      sponsor.category = selectedCategory
        ? cloneDeep(
            Object.values(
              rootState.sponsorsCategories
                .sponsorsCategories as SponsorCategory[]
            ).filter(
              (n: SponsorCategory) =>
                n.nameNormalized === selectedCategory.nameNormalized
            )[0]
          )
        : selectedCategory;
      let message;
      if (state.editingSponsor) {
        await SponsorsService.updateSponsor(sponsor);
        message = i18n.t("SPONSORS.SNACKBAR.EDIT", {
          string: translatableText(sponsor.names)
        });
      } else {
        await SponsorsService.addSponsor(sponsor);
        message = i18n.t("SPONSORS.SNACKBAR.ADD", {
          string: translatableText(sponsor.names)
        });
      }
      SnackbarService.info(message.toString());
      commit("RESET");
      commit("UNLOAD");
      commit("sponsorsCategories/RESET_BOX", null, { root: true });
      router.push("/sponsors");
    } catch (e) {
      ErrorService.handleError(e);
      commit("UNLOAD");
    }
  },

  async loadSponsor(
    { commit }: ActionContext<SponsorsState, RootState>,
    id: string
  ) {
    commit("LOAD");
    try {
      const sponsor = await SponsorsService.getSponsorById(id);
      commit("SET_EDITING", sponsor);
    } catch (e) {
      ErrorService.handleError(e);
    } finally {
      commit("UNLOAD");
    }
  },

  async updateOrder(
    { commit, rootGetters }: ActionContext<SponsorsState, RootState>,
    payload: SponsorsOrderChange
  ) {
    commit("LOAD");
    try {
      const categories: SponsorCategory[] =
        rootGetters["sponsorsCategories/getSponsorsCategories"];
      const sorted = payload.categories.reduce<Record<string, string[]>>(
        (acc: { [key: string]: string[] }, cat: string) => {
          const category = categories.find(
            c => c.name.toLowerCase() === cat.toLowerCase()
          );
          const id = category ? category.nameNormalized : "noCategory";
          acc[id] = payload.sponsorsIdsByCategories[cat].map(s => s._id);
          return acc;
        },
        {}
      );
      await SponsorsService.updateOrder(sorted);
      SnackbarService.info(i18n.t("SPONSORS.SNACKBAR.ORDER_CHANGE").toString());
    } catch (e) {
      ErrorService.handleError(e);
    } finally {
      commit("UNLOAD");
    }
  }
};
