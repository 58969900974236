import axios from "axios";

export const OrphanedService = {
  async getAll() {
    const { data } = await axios.get("/api/files/orphaned");
    return data;
  },

  async delete(file) {
    await axios.delete(`/api/files/${file}`);
  },

  async deleteAll() {
    await axios.delete("/api/files/orphaned");
  },

  async compressAll() {
    await axios.put("/api/files/compress");
  }
};
