import {
  type AdImageLinkWithFiles,
  type AdModeType,
  type ApplicationCfgWithAdsFiles
} from "@/pages/ads/ads.types";
import { type AdsState } from "@/pages/ads/store/ads.state";
import { SnackbarService } from "@/shared/snackbar/snackbar.service";

function addOrUpdateAd(
  payload: { index: number; ad: AdImageLinkWithFiles },
  adsArray: AdImageLinkWithFiles[]
) {
  if (payload.index != null) {
    adsArray.splice(payload.index, 1);
    adsArray.splice(payload.index, 0, payload.ad);
  } else {
    adsArray.push(payload.ad);
  }
}

function isAdsPercentageValid(ads: AdImageLinkWithFiles[]) {
  const total = ads.reduce((acc: any, ad: AdImageLinkWithFiles) => {
    acc += ad.percentage;
    return acc;
  }, 0);
  return total === 100 || ads.length === 0;
}

export const AdsMutation = {
  LOAD(state: AdsState) {
    state.loading = true;
  },
  UNLOAD(state: AdsState) {
    state.loading = false;
  },
  SET_AD_TO_DELETE_INDEX(
    state: AdsState,
    payload: {
      index: number;
      mode: AdModeType;
    }
  ) {
    state.currentAdIndex = payload.index;
    state.adMode = payload.mode;
    state.showDeleteDialog = true;
  },

  SET_APPLICATION_CFG(
    state: AdsState,
    applicationCfg: ApplicationCfgWithAdsFiles
  ) {
    state.applicationCfg = applicationCfg;
    state.drawerAds = applicationCfg.drawerAds;
    state.splashAds = applicationCfg.splashAds;
    state.splashDuration = applicationCfg.splashDuration;
    state.isDrawerAdsPercentageValid =
      !applicationCfg.features["showDrawerBottomAdvertisement"] ||
      (applicationCfg.features["showDrawerBottomAdvertisement"] &&
        isAdsPercentageValid(state.drawerAds));
    state.isSplashAdsPercentageValid =
      !applicationCfg.features["displaySplashAdvertisement"] ||
      (applicationCfg.features["displaySplashAdvertisement"] &&
        isAdsPercentageValid(state.splashAds));
  },

  SET_DRAWER_AD_IMAGE(
    state: AdsState,
    payload: {
      index: number;
      filename: string;
    }
  ) {
    state.drawerAds[payload.index].croppedImage = undefined;
    state.drawerAds[payload.index].image = payload.filename;
    state.isDrawerAdsPercentageValid = isAdsPercentageValid(state.drawerAds);
  },

  SET_SPLASH_AD_IMAGE(
    state: AdsState,
    payload: {
      index: number;
      filename: string | null;
    }
  ) {
    state.splashAds[payload.index].croppedImage = undefined;
    state.splashAds[payload.index].image = payload.filename;
    state.isSplashAdsPercentageValid = isAdsPercentageValid(state.splashAds);
  },

  SET_DRAWER_AD_VIDEO(
    state: AdsState,
    payload: {
      index: number;
      filename: string | null;
    }
  ) {
    state.drawerAds[payload.index].videoFile = undefined;
    state.drawerAds[payload.index].videoFilename = payload.filename;
    state.isDrawerAdsPercentageValid = isAdsPercentageValid(state.drawerAds);
  },

  SET_SPLASH_AD_VIDEO(
    state: AdsState,
    payload: {
      index: number;
      filename: string | null;
    }
  ) {
    state.splashAds[payload.index].videoFile = undefined;
    state.splashAds[payload.index].videoFilename = payload.filename;
    state.isSplashAdsPercentageValid = isAdsPercentageValid(state.splashAds);
  },

  DELETE_AD(state: AdsState) {
    if (state.currentAdIndex == null) {
      SnackbarService.error(
        "Error : Deletion of Ad impossible because currentAdIndex is undefined"
      );
      return;
    }
    if (state.adMode === "DRAWER") {
      state.drawerAds.splice(state.currentAdIndex, 1);
      state.isDrawerAdsPercentageValid = isAdsPercentageValid(state.drawerAds);
    } else {
      state.splashAds.splice(state.currentAdIndex, 1);
      state.isSplashAdsPercentageValid = isAdsPercentageValid(state.splashAds);
    }
    state.currentAdIndex = undefined;
    state.adMode = undefined;
    state.showDeleteDialog = false;
  },

  CLOSE_DELETE_AD(state: AdsState) {
    state.currentAdIndex = undefined;
    state.adMode = undefined;
    state.showDeleteDialog = false;
  },

  ADD_AD(state: AdsState, mode: any) {
    state.adMode = mode;
    state.currentAd = undefined;
    state.currentAdIndex = undefined;
    state.showAdModal = true;
  },

  CLOSE_AD_MODAL(state: AdsState) {
    state.adMode = undefined;
    state.currentAd = undefined;
    state.currentAdIndex = undefined;
    state.showAdModal = false;
  },

  SAVE_OR_UPDATE_AD(
    state: AdsState,
    payload: { index: number; ad: AdImageLinkWithFiles }
  ) {
    if (state.adMode === "DRAWER") {
      addOrUpdateAd(payload, state.drawerAds);
      state.isDrawerAdsPercentageValid = isAdsPercentageValid(state.drawerAds);
    } else {
      addOrUpdateAd(payload, state.splashAds);
      state.isSplashAdsPercentageValid = isAdsPercentageValid(state.splashAds);
    }
    state.currentAdIndex = undefined;
    state.currentAd = undefined;
    state.adMode = undefined;
    state.showAdModal = false;
  },

  EDIT_AD(
    state: AdsState,
    payload: {
      index: number;
      ad: AdImageLinkWithFiles;
      mode: AdModeType;
    }
  ) {
    state.adMode = payload.mode;
    state.currentAdIndex = payload.index;
    state.currentAd = { ...payload.ad };
    state.showAdModal = true;
  }
};
