import { type Module } from "vuex";

import { type RootState } from "@/store";

import { actions } from "./programs.actions";
import { getters } from "./programs.getters";
import { mutations } from "./programs.mutations";
import { type ProgramsState, state } from "./programs.state";

export const programs: Module<ProgramsState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
