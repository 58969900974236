import { actions } from "./attendeeWhiteList.actions";
import { mutations } from "./attendeeWhiteList.mutations";
import { state } from "./attendeeWhiteList.state";

export const attendeeWhiteList = {
  namespaced: true,
  state,
  mutations,
  actions
};
