import { type Route } from "vue-router";

import { dispatchFestival } from "./festival.store";

export const FestivalRouter = [
  {
    path: "/festival",
    component: () => import("./Festival.vue"),
    beforeEnter: async (to: Route, from: Route, next: any) => {
      await dispatchFestival("loadFestival");
      next();
    }
  }
];
