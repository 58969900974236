import { IdsService } from "../../../../shared/service/utils.service";

export const mutations = {
  LOAD(state) {
    state.loading = true;
  },

  UNLOAD(state) {
    state.loading = false;
  },

  SET_NOT_DELETING(state) {
    state.deletingSponsorCategoryId = null;
  },

  SET_SPONSORS_CATEGORIES(state, sponsorsCategories) {
    state.sponsorsCategories = sponsorsCategories.reduce(
      (acc, sponsorCategory) => {
        acc[sponsorCategory._id] = sponsorCategory;
        return acc;
      },
      {}
    );
  },

  SET_DELETING(state, id) {
    state.deletingSponsorCategoryId = id;
  },

  OPEN_ADD_SPONSOR_CATEGORY(state) {
    state.showModal = true;
  },

  SET_EDITING(state, id) {
    state.editingSponsorCategoryId = id;
  },

  RESET_EDITING(state) {
    state.editingSponsorCategoryId = null;
  },

  //comboBox

  RESET_BOX(state) {
    state.selectedCategory = null;
    state.deletedCategoriesIds = [];
    state.addedCategories = {};
    state.categoriesSearch = "";
  },

  DELETE_CATEGORY(state, category) {
    if (category._id in state.addedCategories) {
      delete state.addedCategories[category._id];
      state.addedCategories = { ...state.addedCategories };
    } else {
      state.deletedCategoriesIds.push(category._id);
      delete state.sponsorsCategories[category._id];
      state.sponsorsCategories = { ...state.sponsorsCategories };
    }
  },

  CLEAR_SELECTED_CATEGORY(state) {
    state.selectedCategory = null;
  },

  SET_CATEGORIES_SELECTION(state, category) {
    if (category == null) return;

    if (typeof category === "string") {
      category = {
        name: category,
        nameNormalized: category.toLowerCase(),
        _id: null,
        weight: 100
      };
    }

    const tmpCategories = Object.values(state.sponsorsCategories).filter(
      n => n.nameNormalized === category.nameNormalized
    );
    if (tmpCategories.length > 0) {
      category = tmpCategories[0];
    } else {
      this.commit("sponsorsCategories/ADD_CATEGORY", category);
    }

    state.selectedCategory = category;
    state.categoriesSearch = "";
  },

  ADD_CATEGORY(state, category) {
    if (
      Object.values(state.sponsorsCategories).filter(
        n => n.nameNormalized === category.nameNormalized
      ).length === 0 &&
      Object.values(state.addedCategories).filter(
        n => n.nameNormalized === category.nameNormalized
      ).length === 0
    ) {
      category._id = IdsService.generateId();
      state.addedCategories[category._id] = category;
    }
  },

  SET_CATEGORIES_SEARCH(state, categoriesSearch) {
    state.categoriesSearch = categoriesSearch;
  }
};
