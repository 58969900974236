import { actions } from "./notificationsTopics.actions";
import { getters } from "./notificationsTopics.getters";
import { mutations } from "./notificationsTopics.mutations";
import { state } from "./notificationsTopics.state";

export const notificationsTopics = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
