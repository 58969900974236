import { type Route } from "vue-router";

import { infosDispatch } from "./store/infos.store";

export const InfosRouter = [
  {
    path: "/infosPratiques",
    component: () => import("./Infos.vue")
  },
  {
    path: "/infosPratiques/create",
    component: () => import("./AddingInfos.vue"),
    beforeEnter: async (to: Route, from: Route, next: () => void) => {
      await infosDispatch("loadInfo", undefined);
      next();
    }
  },
  {
    path: "/infosPratiques/:id",
    component: () => import("./AddingInfos.vue"),
    beforeEnter: async (to: Route, from: Route, next: () => void) => {
      await infosDispatch("loadInfo", to.params.id);
      next();
    }
  }
];
