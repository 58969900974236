import cloneDeep from "lodash/cloneDeep";

import { i18n } from "../../../i18n/i18n";

export const getters = {
  notifTopic: state => {
    if (state.mode !== "edit") {
      return {
        names: {
          fr: "",
          en: ""
        },
        color: "#000000",
        selectedByDefault: true
      };
    } else {
      const topic = { ...state.editNotifTopic };
      topic.names = { ...state.editNotifTopic.names };
      return topic;
    }
  },

  getNotifTopics: state => {
    return state.notifTopics.filter(n => !n.deleted);
  },

  getNotificationsTopicsNotDeletedSorted: state => {
    const sortedTopics = cloneDeep(state.notifTopics);
    sortedTopics.sort((a, b) => {
      if (a.names["fr"] < b.names["fr"]) return -1;
      if (a.names["fr"] > b.names["fr"]) return 1;
    });
    const topics = [
      {
        _id: null,
        names: { fr: i18n.t("NOTIFICATIONS.ADDING_MODAL.ALL") },
        color: "white"
      },
      ...sortedTopics
    ];
    return topics.filter(n => !n.deleted);
  },

  getNotificationsTopics: state => {
    return id => {
      return state.notifTopics.filter(n => n._id === id)[0];
    };
  }
};
