import axios from "axios";

export const NotificationsTopicsService = {
  async getAll() {
    const { data } = await axios.get("/api/notif/topic");
    return data;
  },

  async getTopicById(id) {
    const { data } = await axios.get(`/api/notif/topic/${id}`);
    return data;
  },

  async deleteNotifTopic(id) {
    return await axios.delete("/api/notif/topic/" + id);
  },

  async addNotifTopic(notifTopic) {
    return await axios.post("/api/notif/topic", notifTopic);
  },

  async editNotifTopic(id, notifTopic) {
    return await axios.put("/api/notif/topic/" + id, notifTopic);
  }
};
