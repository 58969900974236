import { type Route } from "vue-router";

import store from "../../store";

export const SponsorsRouter = [
  {
    path: "/sponsors",
    component: () => import("./Sponsors.vue")
  },
  {
    path: "/sponsors/create",
    component: () => import("./AddingSponsors.vue")
  },
  {
    path: "/sponsors/:id",
    component: () => import("./AddingSponsors.vue"),
    beforeEnter: async (to: Route, from: Route, next: any) => {
      await store.dispatch("sponsors/loadSponsor", to.params.id);
      next();
    }
  }
];
