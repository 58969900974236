import { type ActionContext } from "vuex";

import {
  type ExportUrl,
  type FestivalImportUrl,
  type ImportStatus
} from "@/pages/import/store/import.type";
import { ErrorService } from "@/shared/service/errorService";
import { FestivalService } from "@/shared/service/festival.service";
import { type RootState } from "@/store";

import { ImportService } from "../import.service";
import { type StateImport } from "./import.state";

async function uploadFile(
  file: any,
  commit: any,
  handler: (file: any) => Promise<void>
) {
  commit("LOAD");
  try {
    await handler(file);
  } catch (e) {
    ErrorService.handleError(e);
  }
  commit("UNLOAD");
}

export const actions = {
  async uploadGcArtist(
    { commit }: ActionContext<StateImport, RootState>,
    file: any
  ) {
    await uploadFile(file, commit, ImportService.uploadArtist);
  },
  async uploadGcScene(
    { commit }: ActionContext<StateImport, RootState>,
    file: any
  ) {
    await uploadFile(file, commit, ImportService.uploadScene);
  },
  async uploadGcEvent(
    { commit }: ActionContext<StateImport, RootState>,
    file: any
  ) {
    await uploadFile(file, commit, ImportService.uploadEvent);
  },
  async uploadHeeds(
    { commit }: ActionContext<StateImport, RootState>,
    file: any
  ) {
    await uploadFile(file, commit, ImportService.uploadHeeds);
  },
  async syncHeeds(
    { commit }: ActionContext<StateImport, RootState>,
    { clean }: { clean: boolean }
  ) {
    commit("LOAD");
    await ImportService.syncHeeds(clean);
    commit("UNLOAD");
  },
  async sync(
    { commit }: ActionContext<StateImport, RootState>,
    { urls, clean }: { urls: ExportUrl[]; clean: boolean }
  ) {
    commit("LOAD");
    await ImportService.sync(urls, clean);
    commit("UNLOAD");
  },

  async loadSyncUrls({ commit }: ActionContext<StateImport, RootState>) {
    const festivalImportUrl: FestivalImportUrl =
      await FestivalService.getFestival();
    commit("SET_SYNC_URL", festivalImportUrl);
  },

  async getLastStatus({ commit }: ActionContext<StateImport, RootState>) {
    const importStatus: ImportStatus =
      await ImportService.getLastImportStatus();
    commit("SET_IMPORT_STATUS", importStatus);
    if (importStatus.end) {
      commit("ENABLE");
    }
  }
};
