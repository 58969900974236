import { sortScenes } from "@/pages/scenes/store/scenes.getters";

export const mutations = {
  LOAD(state) {
    state.loading = true;
  },

  UNLOAD(state) {
    state.loading = false;
  },

  SET_SCENES(state, scenes) {
    state.scenes = scenes.reduce((acc, scene) => {
      acc[scene._id] = scene;
      return acc;
    }, {});
  },

  SET_DELETING(state, id) {
    state.deletingSceneId = id;
  },

  SET_NOT_DELETING(state) {
    state.deletingSceneId = null;
  },

  SET_ADDING(state) {
    state.showModal = true;
  },

  SET_EDITING(state, id) {
    state.editingSceneId = id;
    state.showModal = true;
  },

  RESET(state) {
    state.editingSceneId = null;
    state.showModal = false;
  },

  MOVE(state, payload) {
    const tab = Object.values(state.scenes).sort(sortScenes);
    const index = tab.indexOf(state.scenes[payload.sceneId]);
    const weight = tab[index].weight;
    tab[index].weight = tab[index + payload.up_down].weight;
    tab[index + payload.up_down].weight = weight;
    state.sceneToMoveId = tab[index + payload.up_down]._id;
    state.scenes = { ...state.scenes };
  },

  RESET_MOVE(state) {
    state.sceneToMoveId = null;
  },

  SET_SCENE_EVENTS(state, events) {
    state.deletingSceneEvents = [...events];
  },

  SET_SCENE_MARKERS(state, markers) {
    state.deletingSceneMarkers = [...markers];
  }
};
