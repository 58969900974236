import { type RouteConfig } from "vue-router";

import store from "../../store";
import { dispatchApplicationCfg } from "../applicationCfg/store/applicationCfg.store";
import { dispatchFestival } from "../festival/festival.store";
import { menuEntriesDispatch } from "./menuEntries.store";

export const MenuRouter: RouteConfig[] = [
  {
    path: "/menuEntries",
    component: () => import("./MenuEntries.vue"),
    beforeEnter: async (to, from, next) => {
      await Promise.all([
        dispatchApplicationCfg("loadApplicationCfg"),
        dispatchFestival("loadFestival"),
        store.dispatch("progEvent/list"),
        menuEntriesDispatch("getAllContent"),
        menuEntriesDispatch("getAllChoices")
      ]);
      next();
    }
  }
];
