import cloneDeep from "lodash/cloneDeep";

type Sortable = { _id: string; weight: number };
export const IdsService = {
  generateId(): string {
    return "_" + Math.random().toString(36).substring(2, 9);
  }
};

export const colorService = {
  rgba_to_argb(color = "") {
    let newColor = "";
    if (color.length === 9) {
      newColor = "#" + color.slice(7) + color.slice(1, 7);
    } else {
      newColor = color;
    }
    return newColor;
  },

  remove_alpha(color = "") {
    if (color.length === 9) {
      return "#" + color.slice(1, 7);
    }
    return color;
  },

  argb_to_rgba(color = "") {
    let newColor = "";
    if (color.length === 9) {
      const alpha = color.slice(1, 3);
      newColor = "#" + color.slice(3) + alpha;
    } else {
      newColor = color;
    }
    return newColor;
  }
};

export const weightService = {
  getMinWeight(objects: Record<string, Sortable>) {
    return Object.values(objects).length == 0
      ? 100
      : Object.values(objects).reduce(
          (acc, object) => (object.weight < acc ? object.weight : acc),
          Object.values(objects)[0].weight
        );
  },

  getIndex(
    objects: Record<string, Sortable>,
    id: string,
    sortfunction: (a: Sortable, b: Sortable) => number
  ) {
    const objectsSorted = Object.values(cloneDeep(objects));
    objectsSorted.sort((a, b) => sortfunction(a, b));
    return objectsSorted.indexOf(objectsSorted.filter(n => n._id == id)[0]);
  },

  isMaxWeight(
    objects: Record<string, Sortable>,
    object: Sortable,
    sortfunction: (a: Sortable, b: Sortable) => number
  ) {
    const objectsSorted = Object.values(cloneDeep(objects));
    objectsSorted.sort((a, b) => sortfunction(a, b));
    return object._id == objectsSorted[0]._id;
  },

  sortWeight(a: Sortable | null, b: Sortable | null) {
    return (b ? b.weight : 0) - (a ? a.weight : 0);
  },

  formatWeight<T extends Sortable>(objects: T[]): T[] {
    const results = cloneDeep(objects);
    results.sort((a, b) => this.sortWeight(a, b));
    let i = 99;
    return results.map(o => {
      o.weight = i--;
      return o;
    });
  }
};
